import { message } from "antd";

export function Alertas() {

    //alerta para sesión vencida

    const SessionExpired = () => {
        message.error("Su sesión ha expirado, por favor vuelve a introducir tus datos");
    }

    return {
        SessionExpired,
    }
}



