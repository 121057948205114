import { useState } from "react";
import { 
    getVerificacionDocsApi,
    addVerificacionDocApi,
    updateVerificacionDocApi,
    deleteVerificacionDocApi,
    getVerificacionDocsApiID,
} from "../../../Api/administrativa/formularios-sig/inspeccion-doc";

import { useAuth } from "../../useAuth";

export function useVerificacionDocs() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    VerificacionDoc: null,
  });

  const { loading, error, VerificacionDoc } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getVerificacionDocs = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getVerificacionDocsApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        VerificacionDoc: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getVerificacionDocsID = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getVerificacionDocsApiID(id, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        VerificacionDoc: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addVerificacionDocs = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addVerificacionDocApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateVerificacionDocs = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateVerificacionDocApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteVerificacionDocs = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteVerificacionDocApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  }

  return {
    loading,
    error,
    VerificacionDoc,
    getVerificacionDocs,
    addVerificacionDocs,
    updateVerificacionDocs,
    deleteVerificacionDocs,
    getVerificacionDocsID,
    cancelLoader,
  };
}