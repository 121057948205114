import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";


export async function getcomisionesApi(token){
    const url = `${BASE_API}/comisiones/api/comisiones`;
    return makeRequest(url, "GET", token);
}

export async function getdetallecomisionesApi(token){
    const url = `${BASE_API}/comisiones/api/Comisiones`;
    return makeRequest(url, "GET", token);
}

export async function addcomisionesapi(data, token){
    const mesAnio = `${data.anio}_${data.mes}`
    const fecha_inicial = data.fecha_inicial
    const fecha_final = data.fecha_final

    const url = `${BASE_API}/comisiones/Prueba/${mesAnio}/${fecha_inicial}/${fecha_final}`;
    return makeRequest(url, "POST", token)
}
