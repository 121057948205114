import { BASE_API } from "../../../utils/constants";
import { makeRequest, makeRequestFileData } from "../../Request";

  // Función para obtener los datos de un Comercial
  export async function getSolicitudPermisosApi(token, id_user) {
    const url = `${BASE_API}/solicitud_permisos/api/Permisos/${id_user}/`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos de un Comercial
  export async function getBossUserApi(token, id_user) {
    const url = `${BASE_API}/userdb/boss_name/${id_user}`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos de un Comercial
  export async function getPermisosBossApi(token, userId) {
    const url = `${BASE_API}/solicitud_permisos/api/Permisos/getPermisosJefe/${userId}/`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos de un Comercial
  export async function getPermisosGHApi(token) {
    const url = `${BASE_API}/solicitud_permisos/api/Permisos/getPermisosGH/`;
    return makeRequest(url, "GET", token);
  }

  // Función para agregar un nuevo Comercial
  export async function addSolicitudPermisosApi(data, token) {
    const url = `${BASE_API}/solicitud_permisos/api/Permisos/`;
    return makeRequestFileData(url, "POST", token, data);
  }

  export async function updateStatusAprovJefeApi(id_permiso, id_user, estado, token) {
    const url = `${BASE_API}/solicitud_permisos/api/Permisos/patchStatusBoss/${id_permiso}/${id_user}/${estado}/`;
    return makeRequest(url, "PATCH", token);
  }

  export async function updateStatusAprovGHApi(id_permiso, id_user, userMe, estado, token) {
    const url = `${BASE_API}/solicitud_permisos/api/Permisos/patchStatusGH/${id_permiso}/${id_user}/${userMe}/${estado}/`;
    return makeRequest(url, "PATCH", token);
  }
