import { BASE_API } from "../utils/constants";

export async function loginDjango(formValue) {

  try {
    const url = `${BASE_API}/login/`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValue),
    };
    const response = await fetch(url, params);
    const result = await response.json();
    if (response.status !== 200) {
      throw new Error(result.error);
    }

    return result;
  } catch (error) {
    throw error;
  }
}

//recibe el token
//toma el token para hacer una peticion
export async function getMeApi(token) {

  try {
    const url = `${BASE_API}/usuarios/auth/me/`;
    const params = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, params);
    const result = await response.json();

    return result;
  } catch (error) {
    throw error;
  }
}