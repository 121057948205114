import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from 'formik';
import { Button, Col, Form, Row, message, Input, InputNumber, Typography, Space, Image, Skeleton, Modal } from "antd";
import { useAuth } from "../../hooks/useAuth";
import { useSyzia } from "../../hooks/syzia/usesyzia";
import Bot from "../../assets/bot.png";

export function SyziaForm(props) {
    const { recoveryData } = props;
    const { addConsulta, updateConsulta, RespuestaP, RespuestaU, id_consulta } = useSyzia();
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
    const [refetch, setRefetch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contentSkeleton, setContentSkeleton] = useState(null);
    const [nextMessage, setNextMessage] = useState("");  // Estado para manejar el siguiente mensaje
    const { auth } = useAuth();
    const { TextArea } = Input;
    const { Text } = Typography;
    const userEmail = auth.me?.email;
    const [conversation, setConversation] = useState([]);
    const [ newidUpdate, setnewidUpdate ] = useState(id_consulta)

    const formatContent = (messages) => {
        let sequenceNumber = 1;

        return messages.map((msg, index) => {

            // Determina si el mensaje actual es el último en el array
            const isLastMessage = index === messages.length - 1;
            // Determina si se debe mostrar una línea horizontal después del mensaje actual
            const showHr = !isLastMessage && messages[index + 1]?.role === 'user';

            const content = (
                <div key={index}>
                    {msg.role === 'user' && (
                        <>
                            <strong>Prompt {sequenceNumber}:</strong>
                            <p>{msg.content}</p>
                        </>
                    )}
                    {msg.role === 'assistant' && (
                        <>
                            <strong>Respuesta {sequenceNumber}:</strong>
                            <p>{msg.content}</p>
                        </>
                    )}
                    {showHr && <hr />}
                </div>
            );

            if (msg.role === 'assistant') {
                sequenceNumber++;
            }
    
            return content;
        });
    };

    useEffect(() => {
        if (recoveryData !== null) {
            const messagesRecovery = Array.isArray(recoveryData.consulta) ? recoveryData.consulta : [];
            const formattedContent = formatContent(messagesRecovery);
            
            setnewidUpdate(recoveryData.id_consulta)

            setContentSkeleton(
                <div>
                    {formattedContent}
                    <TextArea
                        rows={3}
                        placeholder="Escribe tu siguiente pregunta..."
                        value={nextMessage}
                        onChange={(e) => setNextMessage(e.target.value)}
                    />
                    <Button 
                        type="primary" 
                        onClick={handleContinueConversation}
                        style={{ marginTop: '10px' }}
                        disabled={!nextMessage.trim()}  // Deshabilitar si no hay texto
                    >
                        Seguir Conversación
                    </Button>
                    <Button 
                        type="default"
                        danger
                        onClick={() => {
                            setNextMessage(""); 
                            window.location.reload(); // Recargar la página
                        }}
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                    >
                        Comenzar Otro Prompt
                    </Button>
                </div>
            );
            setLoading(false); // Oculta el loader cuando recoveryData cambia
        }
    }, [recoveryData, nextMessage]);
    
    useEffect(() => {
        if (RespuestaP !== null) {
            setnewidUpdate(id_consulta)
            const messages = Array.isArray(RespuestaP) ? RespuestaP : [];
            const formattedContent = formatContent(messages);
            
            setContentSkeleton(
                <div>
                    {formattedContent}
                    <TextArea
                        rows={3}
                        placeholder="Escribe tu siguiente pregunta..."
                        value={nextMessage}
                        onChange={(e) => setNextMessage(e.target.value)}
                    />
                    <Button 
                        type="primary" 
                        onClick={handleContinueConversation}
                        style={{ marginTop: '10px' }}
                        disabled={!nextMessage.trim()}  // Deshabilitar si no hay texto
                    >
                        Seguir Conversación
                    </Button>

                    <Button 
                        type="default"
                        danger
                        onClick={() => {
                            setNextMessage(""); 
                            window.location.reload(); // Recargar la página
                        }}
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                    >
                        Comenzar Otro Prompt
                    </Button>
                </div>
            );
            setLoading(false); // Oculta el loader cuando RespuestaP cambia
        }
    }, [RespuestaP, nextMessage]);
    
    useEffect(() => {
        if (RespuestaU !== null) {
            setnewidUpdate(id_consulta)
            const updatedMessages = Array.isArray(RespuestaU) ? RespuestaU : [];
            const formattedContent = formatContent(updatedMessages);
    
            setContentSkeleton(
                <div>
                    {formattedContent}
                    <TextArea
                        rows={3}
                        autoSize={{ minRows: 3 }}
                        placeholder="Escribe tu siguiente pregunta..."
                        value={nextMessage}
                        onChange={(e) => setNextMessage(e.target.value)}
                    />
                    <Button 
                        type="primary" 
                        onClick={handleContinueConversation}
                        style={{ marginTop: '10px' }}
                        disabled={!nextMessage.trim()}  // Deshabilitar si no hay texto
                    >
                        Seguir Conversación
                    </Button>
    
                    <Button 
                        type="default"
                        danger
                        onClick={() => {
                            setNextMessage(""); 
                            window.location.reload(); // Recargar la página
                        }}
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                    >
                        Comenzar Otro Prompt
                    </Button>
                </div>
            );
            setLoading(false); // Oculta el loader cuando RespuestaU cambia
        }
    }, [RespuestaU, nextMessage]);


    console.log("Newprompt: ", recoveryData)

    const formik = useFormik({
      initialValues: initialValues(),
      validationSchema: Yup.object(newSchema()),
      onSubmit: async (formValues) => {
        setLoading(true);

        const mensaje = [
            {
                role: "system",
                content: formValues.rolai,
            },
            {
                role: "user",
                content: formValues.consulta,
            }
        ];

        try {
            await addConsulta(mensaje, formValues.creatividad, userEmail);
            message.success("Operación exitosa");
        } catch (error) {
            if (error?.message) {
                message.error(error.message);
            }
        } finally {
            setLoading(false);
        }
      },
    });

    const handleContinueConversation = async () => {
        if (!nextMessage.trim()) {
            message.error("Por favor, escribe una pregunta para continuar.");
            return;
        }
    
        const mensaje = [
            {
                role: "user",
                content: nextMessage,
            }
        ];
    
        try {
            setLoading(true);
            const response = await updateConsulta(newidUpdate, mensaje);
    
            // Verifica si el backend ha devuelto todo el historial
            if (response?.data?.historial) {
                // Actualiza el estado con el historial completo
                setConversation(response.data.historial);
            }
    
            setNextMessage("");
            message.success("Pregunta agregada exitosamente");
        } catch (error) {
            message.error("Error al agregar otra consulta");
        } finally {
            setLoading(false);
        }
    };
    
    
    

    return (
        <>
            <Space>
                <Image width={80} height={80} src={Bot} />
                <Text style={{ fontWeight: 'bold', fontFamily: 'fantasy', fontSize: '30px', letterSpacing: '5px' }}>CHAT <strong style={{ color: '#CC302B' }}>SYZ</strong>AI</Text>
            </Space>
            <br/><br/>

            <Form layout="vertical" onFinish={formik.handleSubmit}>
                <Row gutter={12} >
                    <Col span={8}>
                        <Form.Item
                            label="Creatividad"
                            validateStatus={
                            formik.errors.creatividad && formik.touched.creatividad ? "error" : ""
                            }
                            help={
                            formik.touched.creatividad && formik.errors.creatividad
                                ? formik.errors.creatividad
                                : ""
                            }
                        >
                            <InputNumber
                                style={{
                                    width: '100%',
                                }}
                                name="creatividad"
                                min="0"
                                max="1"
                                step="0.1"
                                stringMode
                                value={formik.values.creatividad}
                                onChange={(value) => formik.setFieldValue('creatividad', value)}
                                onBlur={formik.handleBlur}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Rol AI"
                            validateStatus={
                            formik.errors.rolai && formik.touched.rolai ? "error" : ""
                            }
                            help={
                            formik.touched.rolai && formik.errors.rolai
                                ? formik.errors.rolai
                                : ""
                            }
                        >
                            <Input
                                name="rolai"
                                value={formik.values.rolai}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={16}>
                        <Form.Item
                            label="Consulta"
                            validateStatus={
                            formik.errors.consulta && formik.touched.consulta ? "error" : ""
                            }
                            help={
                            formik.touched.consulta && formik.errors.consulta
                                ? formik.errors.consulta
                                : ""
                            }
                        >
                            <TextArea
                                name="consulta"
                                rows={5}
                                autoSize 
                                value={formik.values.consulta}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12} >   
                    <Col span={9}>
                        <Form.Item>
                            <Button 
                                type="primary"
                                htmlType="submit" 
                                style={{ marginRight: '20px', top: '10px' }}
                            >
                                Consultar
                            </Button>

                            <Button 
                                type="primary" 
                                danger 
                                style={{ top: '10px' }} 
                                onClick={formik.resetForm} 
                            >
                                Limpiar campos
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Skeleton 
                loading={loading}
                active
            >
                {contentSkeleton}
            </Skeleton>
        </>
    );
}

// Función para inicializar los valores del formulario
function initialValues() {
    return {
      creatividad: "1", 
      rolai: "",
      consulta: "",
    };
}

// Función para definir el esquema de validación del formulario
function newSchema() {
  return {
      creatividad: Yup.string().required("El campo es requerido"),
      rolai: Yup.string().required("El campo es requerido"),
      consulta: Yup.string().required("El campo es requerido"),
  };
}


export function UpdateConsultaForm(props) {
    const { data, onClose, onRefetch } = props;
    const { updateConsulta, RespuestaUB } = useSyzia();
    const { TextArea } = Input;

    const formik = useFormik({
        initialValues: {
            consulta: ""
        },
        onSubmit: async (values) => {
            const mensaje = [
                {
                    role: "user",
                    content: values.consulta,
                }
            ];

            try {
                //await updateConsulta(data.id_consulta, mensaje);
                onRefetch();
                onClose();
            } catch (error) {
                message.error('Error al agregar otra consulta');
            }
        }
    });

    // Manejo del modal para mostrar la respuesta
    useEffect(() => {
        if (RespuestaUB !== null) {
            Modal.info({
                title: 'Respuesta de la SYZIA',
                content: (
                    <div>
                        <p>{RespuestaUB}</p>
                    </div>
                ),
            });
        }
    }, [RespuestaUB]);

    return (
        <Form onFinish={formik.handleSubmit}>
            <Form.Item
                label="Nueva pregunta"
                name="consulta"
                rules={[{ required: true, message: "Por favor, ingresa una consulta" }]}
            >
                <TextArea
                    name="consulta"
                    rows={5}
                    autoSize
                    value={formik.values.consulta}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            </Form.Item>
            <Space>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ background: "#60C000" }}
                    loading={formik.isSubmitting}
                >
                    Agregar
                </Button>
            </Space>
        </Form>
    );
}