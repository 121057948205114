import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getDispatchDatosId } from '../../Api/ProcesosApi';
import { TablaBaseSap } from '../../components/comons/TablaBaseSap';

export function GridSap() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [dataDispatch, setDataDispatch] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar el loader
  const [año, setAño] = useState(new Date().getFullYear()); // Estado para controlar el año seleccionado

  const fetchData = async (year) => {
    setLoading(true); // Mostrar el loader antes de la petición
    try {
      await getDispatchDatosId(year).then((r) => {
        setDataDispatch(r.data.value);
        setLoading(false); // Ocultar el loader después de la petición
      });
    } catch (error) {
      console.log(error);
      setLoading(false); // Ocultar el loader en caso de error
    }
  };

  useEffect(() => {
    fetchData(año);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [año]);

  const handleYearChanges = (event) => {
    setAño(event.target.value);
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          
          <TablaBaseSap
            Data={dataDispatch}
            handleYearChange={handleYearChanges}
            año={año}
          />
        </>
      )}
    </>
  );
}
