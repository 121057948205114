import { useLineaEtica } from '../../hooks/useLineaEtica'
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from '../../hooks/useAuth';
import { TablaGeneral } from "../../components/comons/TablaGeneral";
import { Modal } from 'antd';
import {
  Button
} from "@mui/material";
import {buttonStyle} from "../../components/comons/styles";

const headers = [
  { label: "Fecha de registro", field: "fecha_registro" },
  { label: "Anónima", field: "Anonima" },
  { label: "Fecha de hechos", field: "fecha_echos" },
  { label: "Lugar de hechos", field: "Lugar_echos" },
  { label: "Descripción", field: "Descripcion" },
];

  const popoverColumns = [
    { label: "Anónima", field: "Anonima" },
    { label: "Nombre", field: "Nombre" },
    { label: "Teléfono", field: "Telefono" },
    { label: "Email", field: "Email" },
    { label: "Fecha de hechos", field: "fecha_echos" },
    { label: "Lugar de hechos", field: "Lugar_echos" },
    { label: "Descripción", field: "Descripcion" },
    {
      label: "Anexos",
      field: "Anexos",
      renderHeader: () => (
        <strong>
          {'Birthday '}
          <span role="img" aria-label="enjoy">
            🎂
          </span>
        </strong>
      ),
    },
    { label: "Fecha de registro", field: "fecha_registro" },
    
  // ... otros encabezados
   
  ];



export  function LineaEticaPage() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [contentModal, setContentModal] = useState(null);
  const { auth } = useAuth();
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const { loading, LineaEtica, getLineaEtica} = useLineaEtica();

  useEffect(() => {
    getLineaEtica();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  
  return (
   <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (

        <>
        {(auth.me?.administrativo_full_modify) && (
        <TablaGeneral
          Data={LineaEtica}
          columns={headers}
          popoverColumns={popoverColumns}
        />
        )}

        {(auth.me?.administrativo_only_view && !auth.me?.administrativo_full_modify) && (
        <TablaGeneral
          Data={LineaEtica}
          columns={headers}
          popoverColumns={popoverColumns}
        />
        )}
        </>

      )}
      <Modal
        open={showModal}
        onCancel={openCloseModal}
        footer={null}
        width="70%"
        title="Competencia"
        destroyOnClose={true} // Esto destruirá el contenido del modal cuando se cierre
      >
        {contentModal}
      </Modal>
      
    </>
  )
}
