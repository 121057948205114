import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";


  
// Función para obtener los datos de un Leads
export async function getLeadsApi(token) {
    const url = `${BASE_API}/leads/api`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo Leads
  export async function addLeadsApi(data, token) {
    const url = `${BASE_API}/leads/api/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos de un Leads existente
  export async function updateLeadsApi(id, data, token) {
    const url = `${BASE_API}/leads/api/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar un Leads
  export async function deleteLeadsApi(id, token) {
    const url = `${BASE_API}/leads/api/${id}/`;
    return makeRequest(url, "DELETE", token);
  }
  