import { useState } from "react";
import { 
    getControlriesgosApi,
    addControlriesgosApi,
    updateControlriesgosApi,
    deleteControlriesgosApi,
    getControlriesgosApiID,
} from "../../../Api/administrativa/formularios-sig/control-riesgos";

import { useAuth } from "../../useAuth";

export function useControlRiesgos() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    ControlRiesgos: null,
  });

  const { loading, error, ControlRiesgos } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getControlRiesgos = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getControlriesgosApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        ControlRiesgos: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getControlRiesgosID = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getControlriesgosApiID(id, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        ControlRiesgos: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addControlRiesgos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addControlriesgosApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateControlRiesgos = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateControlriesgosApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteControlRiesgos = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteControlriesgosApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  }

  return {
    loading,
    error,
    ControlRiesgos,
    getControlRiesgos,
    addControlRiesgos,
    updateControlRiesgos,
    deleteControlRiesgos,
    getControlRiesgosID,
    cancelLoader,
  };
}