import { useAuth } from '../hooks/useAuth';
import Login from '../pages/login/LoginPage';


export const LayoutNull = ({ children }) => {
  const { auth } = useAuth();

  if (!auth) return <Login/>;
  
  return (
    <>
      {children}
    </>
  );
};

export default LayoutNull;


