import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Col, Form, Row, message, Select, Input, DatePicker, Table, Spin } from "antd";
import { useFormik } from "formik";
import { formStyle } from "../../../comons/styles";
import { useQuienElabora } from "../../../../hooks/administrativa/formularios-sig/useQuienElabora";
import { initialValuesQuienElabora } from "../components/initialValues";
import { CircularProgress } from "@mui/material";
import moment from 'moment';

export function QuienElaboraForm(props) {

  const { updateQuienElabora, cancelLoader, loading } = useQuienElabora();
  const { dataTipoFormulario, data, handleNext, handlePrev, id, setnewdataQuienElabora } = props;

  useEffect(() => {
    cancelLoader()
  }, [data])
  

  const formik = useFormik({
    initialValues: initialValuesQuienElabora(id.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,

    onSubmit: async (formValues) => {
      try {
        await updateQuienElabora(id.Id_quienelabora, formValues);
        message.success("Actualización exitosa (Quien elabora)");
        setnewdataQuienElabora(formValues);
        handleNext();
      } catch (error) {
        message.error("Error en la operación (Quien elabora)");
      }
    },
  });

  return (
    <>
    {loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </div>
    ) : (
    <Form layout="vertical" onFinish={formik.handleSubmit} style={formStyle}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Nombres y apellidos"
              validateStatus={formik.errors.nombreelabora && formik.touched.nombreelabora ? "error" : ""}
              help={formik.touched.nombreelabora && formik.errors.nombreelabora ? formik.errors.nombreelabora : ""}
          >
            <Input
                name="nombreelabora"
                value={formik.values.nombreelabora}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Cédula"
              validateStatus={formik.errors.cedulaelabora && formik.touched.cedulaelabora ? "error" : ""}
              help={formik.touched.cedulaelabora && formik.errors.cedulaelabora ? formik.errors.cedulaelabora : ""}
          >
            <Input
                name="cedulaelabora"
                value={formik.values.cedulaelabora}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Cargo"
              validateStatus={formik.errors.cargoelabora && formik.touched.cargoelabora ? "error" : ""}
              help={formik.touched.cargoelabora && formik.errors.cargoelabora ? formik.errors.cargoelabora : ""}
          >
            <Input
                name="cargoelabora"
                value={formik.values.cargoelabora}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Email"
              validateStatus={formik.errors.emailelabora && formik.touched.emailelabora ? "error" : ""}
              help={formik.touched.emailelabora && formik.errors.emailelabora ? formik.errors.emailelabora : ""}
          >
            <Input
                name="emailelabora"
                value={formik.values.emailelabora}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
            <Form.Item
                label="Fecha Elaboración"
                validateStatus={formik.errors.fecha_firmaelabora && formik.touched.fecha_firmaelabora
                ? "error"
                : ""}
                help={formik.touched.fecha_firmaelabora && formik.errors.fecha_firmaelabora
                ? formik.errors.fecha_firmaelabora
                : ""}
            >
                <DatePicker
                    showTime
                    name="fecha_firmaelabora"
                    style={{ width: '100%' }}
                    value={formik.values.fecha_firmaelabora ? moment(formik.values.fecha_firmaelabora) : null}
                    onChange={(date, dateString) => formik.setFieldValue('fecha_firmaelabora', dateString)}
                    onBlur={() => formik.setFieldTouched('fecha_firmaelabora')} 
                />
            </Form.Item>
        </Col>
      </Row>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="primary" htmlType="button" onClick={handlePrev}>
            Anterior
          </Button>
          <Button type="primary" htmlType="submit">
            Actualizar
          </Button>
      </div>
    </Form>
    )}
    </>
  )
}

export function QuienElaboraFullView(props) {
  const { cancelLoader, loading } = useQuienElabora();
  const { data } = props;

  useEffect(() => {
    cancelLoader();
  }, [data]);

  const formik = useFormik({
    initialValues: initialValuesQuienElabora(data.Id_formulario, data),
  });

  const columns = [
    {
      title: 'Información del Elaborador',
      children: [
        {
          title: 'Nombres y Apellidos',
          dataIndex: 'nombreelabora',
          key: 'nombreelabora',
        },
        {
          title: 'Cédula',
          dataIndex: 'cedulaelabora',
          key: 'cedulaelabora',
        },
        {
          title: 'Cargo',
          dataIndex: 'cargoelabora',
          key: 'cargoelabora',
        },
        {
          title: 'Email',
          dataIndex: 'emailelabora',
          key: 'emailelabora',
        },
        {
          title: 'Fecha Elaboración',
          dataIndex: 'fecha_firmaelabora',
          key: 'fecha_firmaelabora',
          render: (text) => text ? moment(text).format('DD/MM/YYYY HH:mm:ss') : null,
        },
      ],
    },
  ];

  const dataSource = [formik.values]; // Assuming there is only one set of values in formik.values

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row style={{ fontSize: '25px' }}>Quien Elabora</Row>
      <hr />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
      />
    </>
  );
}

export default QuienElaboraFullView;


// export function QuienElaboraFullView(props) {

//   const { cancelLoader, loading } = useQuienElabora();
//   const { data } = props;

//   useEffect(() => {
//     cancelLoader()
//   }, [data])
  

//   const formik = useFormik({
//     initialValues: initialValuesQuienElabora(data.Id_formulario, data),
//   });

//   return (
//     <>
//     {loading ? (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     ) : (
//     <Form layout="vertical" style={formStyle}>
//       <Row style={{ fontSize: '25px' }} >Quien elabora</Row>
//       <hr/>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Nombres y apellidos" >
//             <Input
//                 name="nombreelabora"
//                 value={formik.values.nombreelabora}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Cédula" >
//             <Input
//                 name="cedulaelabora"
//                 value={formik.values.cedulaelabora}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Cargo" >
//             <Input
//                 name="cargoelabora"
//                 value={formik.values.cargoelabora}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Email" >
//             <Input
//                 name="emailelabora"
//                 value={formik.values.emailelabora}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//             <Form.Item label="Fecha Elaboración" >
//                 <Input
//                     name="fecha_firmaelabora"
//                     value={formik.values.fecha_firmaelabora ? moment(formik.values.fecha_firmaelabora).format('DD/MM/YYYY HH:mm:ss') : null}
//                     readOnly
//                 />
//             </Form.Item>
//         </Col>
//       </Row>
//     </Form>
//     )}
//     </>
//   )
// }