import { Button, Col, Form, Input, Row, message, Select, DatePicker } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useLeads } from "../../hooks/useLeads";
import { formStyle } from "../comons/styles";
import moment from 'moment';

export function LeadsForm(props) {
  const { onClose, onRefetch, Leads } = props;
  const { addLeads, updateLeads } = useLeads();
  const { Option } = Select;

  const formik = useFormik({
    initialValues: initialValues(Leads), // Utiliza la función getInitialValues
    validationSchema: Yup.object(Leads ? updateSchema() : newSchema()),
    onSubmit: async (formValues) => {
      try {
        if (Leads) await updateLeads(Leads.id_leads, formValues);
        else await addLeads(formValues);

        onRefetch();
        onClose();
        message.success("Operación exitosa");
      } catch (error) {
        if (error?.message) {
          message.error(error.message);
        } else {
          onClose();
        }
      }
    },
  });
  

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit} style={formStyle}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Año"
            validateStatus={
              formik.errors.anio && formik.touched.anio ? "error" : ""
            }
            help={
              formik.touched.anio && formik.errors.anio
                ? formik.errors.anio
                : ""
            }
          >
            <DatePicker
              style={{width: '100%'}}
              name="anio"
              picker="year"
              value={formik.values.anio ? moment(formik.values.anio) : ''}
              onChange={(date, dateString) => formik.setFieldValue('anio', dateString)}
              onBlur={() => formik.handleBlur('anio')}
            />
          </Form.Item>
        </Col>
        
        <Col span={12}>
          <Form.Item
            label="Mes"
            validateStatus={
              formik.errors.mes && formik.touched.mes ? "error" : ""
            }
            help={
              formik.touched.mes && formik.errors.mes
                ? formik.errors.mes
                : ""
            }
          >
            <DatePicker
              style={{ width: '100%' }}
              picker="month"
              name="mes"
              format="M"
              value={formik.values.mes ? moment(formik.values.mes, 'M') : ''}
              onChange={(date, dateString) => formik.setFieldValue('mes', dateString)}
              onBlur={() => formik.handleBlur('mes')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Empleado de ventas que recibió el lead"
            validateStatus={
              formik.errors.empleado_ventas_lead && formik.touched.empleado_ventas_lead ? "error" : ""
            }
            help={formik.touched.empleado_ventas_lead && formik.errors.empleado_ventas_lead ? formik.errors.empleado_ventas_lead : ""}
          >
            <Input
              name="empleado_ventas_lead"
              value={formik.values.empleado_ventas_lead}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Origen de contacto"
            validateStatus={
              formik.errors.origen_contacto && formik.touched.origen_contacto ? "error" : ""
            }
            help={formik.touched.origen_contacto && formik.errors.origen_contacto ? formik.errors.origen_contacto : ""}
          >
            <Select
              name="origen_contacto"
              value={formik.values.origen_contacto}
              onChange={(value) => formik.setFieldValue('origen_contacto', value)} // Actualiza el valor en formik
              onBlur={formik.handleBlur}
              placeholder="Selecciona una opción"
            >
              <Option value="Buscador de internet">Buscador de internet</Option>
              <Option value="Evento">Evento</Option>
              <Option value="Gestión vendedor">Gestión vendedor</Option>
              <Option value="Mailing de mercadeo">Mailing de mercadeo</Option>
              <Option value="Material publicitario POP">Material publicitario POP</Option>
              <Option value="Pauta Digital">Pauta Digital</Option>
              <Option value="Redes Sociales">Redes Sociales</Option>
              <Option value="Referido Cliente">Referido Cliente</Option>
              <Option value="Referido Fábrica">Referido Fábrica</Option>
              <Option value="Sitio Web SYZ">Sitio Web SYZ</Option>
              <Option value="Sitio Web Fábrica">Sitio Web Fábrica</Option>
              <Option value="Webinar interno">Webinar interno</Option>
              <Option value="Webinar Fábrica">Webinar Fábrica</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>

        <Col span={12}>
          <Form.Item
            label="Fecha contacto"
            validateStatus={
              formik.errors.fecha_contacto && formik.touched.fecha_contacto
                ? "error"
                : ""
            }
            help={
              formik.touched.fecha_contacto && formik.errors.fecha_contacto
                ? formik.errors.fecha_contacto
                : ""
            }
          >
            <DatePicker
              style={{width: '100%'}}
              name="fecha_contacto"
              value={formik.values.fecha_contacto ? moment(formik.values.fecha_contacto) : null}
              onChange={(date, dateString) => formik.setFieldValue('fecha_contacto', dateString)}
              onBlur={() => formik.setFieldTouched('fecha_contacto')}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Pais"
            validateStatus={
              formik.errors.pais && formik.touched.pais ? "error" : ""
            }
            help={formik.touched.pais && formik.errors.pais ? formik.errors.pais : ""}
          >
            <Select
              name="pais"
              value={formik.values.pais}
              onChange={(value) => formik.setFieldValue('pais', value)} // Actualiza el valor en formik
              onBlur={formik.handleBlur}
              placeholder="Selecciona una opción"
            >
              <Option value="Arabia">Arabia</Option>
              <Option value="Argentina">Argentina</Option>
              <Option value="Australia">Australia</Option>
              <Option value="Bolivia">Bolivia</Option>
              <Option value="Brasil">Brasil</Option>
              <Option value="Chile">Chile</Option>
              <Option value="China">China</Option>
              <Option value="Colombia">Colombia</Option>
              <Option value="Ecuador">Ecuador</Option>
              <Option value="El salvador">El salvador</Option>
              <Option value="España">España</Option>
              <Option value="Estados Unidos">Estados Unidos</Option>
              <Option value="Guatemala">Guatemala</Option>
              <Option value="Mexico">Mexico</Option>
              <Option value="Nicaragua">Nicaragua</Option>
              <Option value="Panama">Panama</Option>
              <Option value="Paraguay">Paraguay</Option>
              <Option value="Perú">Perú</Option>
              <Option value="Republica dominicana">Republica dominicana</Option>
              <Option value="Uruguay">Uruguay</Option>
              <Option value="Venezuela">Venezuela</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Nit"
            validateStatus={
              formik.errors.nit && formik.touched.nit ? "error" : ""
            }
            help={
              formik.touched.nit && formik.errors.nit
                ? formik.errors.nit
                : ""
            }
          >
            <Input
              name="nit"
              value={formik.values.nit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Razon social"
            validateStatus={
              formik.errors.razon_social && formik.touched.razon_social
                ? "error"
                : ""
            }
            help={
              formik.touched.razon_social && formik.errors.razon_social
                ? formik.errors.razon_social
                : ""
            }
          >
            <Input
              name="razon_social"
              value={formik.values.razon_social}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="contacto"
            validateStatus={
              formik.errors.contacto && formik.touched.contacto ? "error" : ""
            }
            help={
              formik.touched.contacto && formik.errors.contacto
                ? formik.errors.contacto
                : ""
            }
          >
            <Input
              name="contacto"
              value={formik.values.contacto}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Cargo"
            validateStatus={
              formik.errors.cargo && formik.touched.cargo ? "error" : ""
            }
            help={
              formik.touched.cargo && formik.errors.cargo
                ? formik.errors.cargo
                : ""
            }
          >
            <Input
              name="cargo"
              value={formik.values.cargo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Email"
            validateStatus={
              formik.errors.email && formik.touched.email ? "error" : ""
            }
            help={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          >
            <Input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Numero telefonico"
            validateStatus={
              formik.errors.numero_telefonico && formik.touched.numero_telefonico ? "error" : ""
            }
            help={
              formik.touched.numero_telefonico && formik.errors.numero_telefonico
                ? formik.errors.numero_telefonico
                : ""
            }
          >
            <Input
              name="numero_telefonico"
              value={formik.values.numero_telefonico}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Dirección"
            validateStatus={
              formik.errors.direccion && formik.touched.direccion ? "error" : ""
            }
            help={
              formik.touched.direccion && formik.errors.direccion
                ? formik.errors.direccion
                : ""
            }
          >
            <Input
              name="direccion"
              value={formik.values.direccion}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Descripción"
            validateStatus={
              formik.errors.descripcion && formik.touched.descripcion ? "error" : ""
            }
            help={
              formik.touched.descripcion && formik.errors.descripcion
                ? formik.errors.descripcion
                : ""
            }
          >
            <Input
              name="descripcion"
              value={formik.values.descripcion}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Sector Industrial"
            validateStatus={
              formik.errors.sector_industrial && formik.touched.sector_industrial ? "error" : ""
            }
            help={formik.touched.sector_industrial && formik.errors.sector_industrial ? formik.errors.sector_industrial : ""}
          >
            <Select
              name="sector_industrial"
              value={formik.values.sector_industrial}
              onChange={(value) => formik.setFieldValue('sector_industrial', value)} // Actualiza el valor en formik
              onBlur={formik.handleBlur}
              placeholder="Selecciona una opción"
            >
              <Option value="Agricola">Agricola</Option>
              <Option value="Alimentos y bebidas">Alimentos y bebidas</Option>
              <Option value="Biocombustibles">Biocombustibles</Option>
              <Option value="Comercio">Comercio</Option>
              <Option value="Edificaciones">Edificaciones</Option>
              <Option value="Hidrocarburos">Hidrocarburos</Option>
              <Option value="Industria pesada">Industria pesada</Option>
              <Option value="Industria quimica">Industria quimica</Option>
              <Option value="Institucional">Institucional</Option>
              <Option value="Otros">Otros</Option>
              <Option value="Servicios">Servicios</Option>
              <Option value="Servicios Publicos">Servicios Publicos</Option>
              <Option value="Transporte y maquinaria">Transporte y maquinaria</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Grupo"
            validateStatus={
              formik.errors.grupo && formik.touched.grupo ? "error" : ""
            }
            help={formik.touched.grupo && formik.errors.grupo ? formik.errors.grupo : ""}
          >
            <Select
              name="grupo"
              value={formik.values.grupo}
              onChange={(value) => formik.setFieldValue('grupo', value)} // Actualiza el valor en formik
              onBlur={formik.handleBlur}
              placeholder="Selecciona una opción"
            >
              <Option value="Automatización y control">Automatización y control</Option>
              <Option value="Electrico">Electrico</Option>
              <Option value="Instrumentación">Instrumentación</Option>
              <Option value="Mecanica">Mecanica</Option>
              <Option value="Servicios">Servicios</Option>
              <Option value="SYZTEMAS">SYZTEMAS</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Línea"
            validateStatus={
              formik.errors.linea && formik.touched.linea ? "error" : ""
            }
            help={formik.touched.linea && formik.errors.linea ? formik.errors.linea : ""}
          >
            <Select
              name="linea"
              value={formik.values.linea}
              onChange={(value) => formik.setFieldValue('linea', value)} // Actualiza el valor en formik
              onBlur={formik.handleBlur}
              placeholder="Selecciona una opción"
            >
              <Option value="Accesorios carrotanques">Accesorios carrotanques</Option>
              <Option value="Accesorios tanques">Accesorios tanques</Option>
              <Option value="Analitica">Analitica</Option>
              <Option value="Bombeo">Bombeo</Option>
              <Option value="Calibracion">Calibracion</Option>
              <Option value="Combustibles">Combustibles</Option>
              <Option value="Combustion">Combustion</Option>
              <Option value="Comprension">Comprension</Option>
              <Option value="Comunicaciones">Comunicaciones</Option>
              <Option value="Conduccion">Conduccion</Option>
              <Option value="Conectividad">Conectividad</Option>
              <Option value="Conexion y despacho">Conexion y despacho</Option>
              <Option value="Configuraciones">Configuraciones</Option>
              <Option value="Controladores">Controladores</Option>
              <Option value="DataLogger">DataLogger</Option>
              <Option value="Distribucion">Distribucion</Option>
              <Option value="Filtración">Filtración</Option>
              <Option value="Fiscalización">Fiscalización</Option>
              <Option value="Instalación">Instalación</Option>
              <Option value="Laboratorio">Laboratorio</Option>
              <Option value="Lavado Automatico">Lavado Automatico</Option>
              <Option value="Limpieza ductos">Limpieza ductos</Option>
              <Option value="Lubricación">Lubricación</Option>
              <Option value="Mantenimiento">Mantenimiento</Option>
              <Option value="Medición">Medición</Option>
              <Option value="Motores">Motores</Option>
              <Option value="Prevencion de caidas">Prevencion de caidas</Option>
              <Option value="Proceso">Proceso</Option>
              <Option value="Pruebas">Pruebas</Option>
              <Option value="Seguridad">Seguridad</Option>
              <Option value="Servicios">Servicios</Option>
              <Option value="Software">Software</Option>
              <Option value="Transferencia de custodia">Transferencia de custodia</Option>
              <Option value="Valvulas">Valvulas</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="¿Es lead o cliente existente?"
            validateStatus={
              formik.errors.lead_ocliente_existente && formik.touched.lead_ocliente_existente ? "error" : ""
            }
            help={formik.touched.lead_ocliente_existente && formik.errors.lead_ocliente_existente ? formik.errors.lead_ocliente_existente : ""}
          >
            <Select
              name="lead_ocliente_existente"
              value={formik.values.lead_ocliente_existente}
              onChange={(value) => formik.setFieldValue('lead_ocliente_existente', value)} // Actualiza el valor en formik
              onBlur={formik.handleBlur}
              placeholder="Selecciona una opción"
            >
              <Option value="Cliente existente">Cliente existente</Option>
              <Option value="Es un lead">Es un lead</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Tipo Lead"
            validateStatus={
              formik.errors.tipo_lead && formik.touched.tipo_lead ? "error" : ""
            }
            help={formik.touched.tipo_lead && formik.errors.tipo_lead ? formik.errors.tipo_lead : ""}
          >
            <Select
              name="tipo_lead"
              value={formik.values.tipo_lead}
              onChange={(value) => formik.setFieldValue('tipo_lead', value)} // Actualiza el valor en formik
              onBlur={formik.handleBlur}
              placeholder="Selecciona una opción"
            >
              <Option value="Calificado">Calificado</Option>
              <Option value="Descalificado">Descalificado</Option>
              <Option value="Lead sin tramitar">Lead sin tramitar</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Motivo Descalificación"
            validateStatus={
              formik.errors.motivo_descalificacion && formik.touched.motivo_descalificacion ? "error" : ""
            }
            help={formik.touched.motivo_descalificacion && formik.errors.motivo_descalificacion ? formik.errors.motivo_descalificacion : ""}
          >
            <Select
              name="motivo_descalificacion"
              value={formik.values.motivo_descalificacion}
              onChange={(value) => formik.setFieldValue('motivo_descalificacion', value)} // Actualiza el valor en formik
              onBlur={formik.handleBlur}
              placeholder="Selecciona una opción"
            >
              <Option value="El cliente no responde comunicaciones">El cliente no responde comunicaciones</Option>
              <Option value="Muy poca probabilidad de venta">Muy poca probabilidad de venta</Option>
              <Option value="No aplica">No aplica</Option>
              <Option value="No está dentro del portafolio de SYZ">No está dentro del portafolio de SYZ</Option>
              <Option value="Pais no autorizado">Pais no autorizado</Option>
              <Option value="Se envia a fabrica para su manejo directo">Se envia a fabrica para su manejo directo</Option>
              <Option value="Se esta trabajando con cliente final">Se esta trabajando con cliente final</Option>
              <Option value="Se tramitó por otro origen">Se tramitó por otro origen</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/*
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Asignado A"
            validateStatus={
              formik.errors.asignado_a && formik.touched.asignado_a ? "error" : ""
            }
            help={
              formik.touched.asignado_a && formik.errors.asignado_a
                ? formik.errors.asignado_a
                : ""
            }
          >
            <Input
              name="asignado_a"
              value={formik.values.asignado_a}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Fecha Asignación Gerente Cuenta"
            validateStatus={
              formik.errors.fecha_asignacion_gerente_cuenta &&
              formik.touched.fecha_asignacion_gerente_cuenta
                ? "error"
                : ""
            }
            help={
              formik.touched.fecha_asignacion_gerente_cuenta &&
              formik.errors.fecha_asignacion_gerente_cuenta
                ? formik.errors.fecha_asignacion_gerente_cuenta
                : ""
            }
          >
            <DatePicker
              style={{width: '100%'}}
              name="fecha_asignacion_gerente_cuenta"
              value={formik.values.fecha_asignacion_gerente_cuenta ? moment(formik.values.fecha_asignacion_gerente_cuenta) : null}
              onChange={(date, dateString) => formik.setFieldValue('fecha_asignacion_gerente_cuenta', dateString)}
              onBlur={() => formik.setFieldTouched('fecha_asignacion_gerente_cuenta')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="OPV Creado"
            validateStatus={formik.errors.opv_creado && formik.touched.opv_creado ? "error" : ""}
            help={formik.touched.opv_creado && formik.errors.opv_creado ? formik.errors.opv_creado : ""}
          >
            <Input
              name="opv_creado"
              value={formik.values.opv_creado}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Motivo Pérdida"
            validateStatus={formik.errors.motivo_perdida && formik.touched.motivo_perdida ? "error" : ""}
            help={formik.touched.motivo_perdida && formik.errors.motivo_perdida ? formik.errors.motivo_perdida : ""}
          >
            <Input
              name="motivo_perdida"
              value={formik.values.motivo_perdida}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row> */}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {Leads ? "Actualizar" : "Crear"}
        </Button>
      </Form.Item>
    </Form>
  );
}

// Función para inicializar los valores del formulario
function initialValues(data) {
  return {
    anio: data?.anio || '',
    mes: data?.mes || '',
    empleado_ventas_lead: data?.empleado_ventas_lead || "",
    origen_contacto: data?.origen_contacto || "",
    fecha_contacto: data?.fecha_contacto || null,
    pais: data?.pais || "",
    nit: data?.nit || "",
    razon_social: data?.razon_social || "",
    contacto: data?.contacto || "",
    cargo: data?.cargo || "",
    email: data?.email || "",
    numero_telefonico: data?.numero_telefonico || "",
    direccion: data?.direccion || "",
    descripcion: data?.descripcion || "",
    sector_industrial: data?.sector_industrial || "",
    grupo: data?.grupo || "",
    linea: data?.linea || "",
    lead_ocliente_existente: data?.lead_ocliente_existente || "",
    tipo_lead: data?.tipo_lead || "",
    motivo_descalificacion: data?.motivo_descalificacion || "",
    asignado_a: data?.asignado_a || "",
    fecha_asignacion_gerente_cuenta: data?.fecha_asignacion_gerente_cuenta || null,
    opv_creado: data?.opv_creado || "",
    motivo_perdida: data?.motivo_perdida || ""
  };
}
  
// Función para definir el esquema de validación del formulario
function newSchema() {
  return {
      anio: Yup.string().required("El campo de año es requerido"),
      mes: Yup.string().required("El campo de mes es requerido"),
      empleado_ventas_lead: Yup.string().required("El campo de empleado de ventas lead es requerido"),
      origen_contacto: Yup.string().required("El campo de origen de contacto es requerido"),
      fecha_contacto: Yup.date().required("El campo de fecha de contacto es requerido"),
      pais: Yup.string().required("El campo de país es requerido"),
      nit: Yup.string().required("El campo de nit es requerido"),
      razon_social: Yup.string().required("El campo de razón social es requerido"),
      contacto: Yup.string().required("El campo de contacto es requerido"),
      cargo: Yup.string().required("El campo de cargo es requerido"),
      email: Yup.string().email("Formato de correo electrónico inválido").required("El campo de email es requerido"),
      numero_telefonico: Yup.string().required("El campo de número telefónico es requerido"),
      direccion: Yup.string().required("El campo de dirección es requerido"),
      descripcion: Yup.string().required("El campo de descripción es requerido"),
      sector_industrial: Yup.string().required("El campo de sector industrial es requerido"),
      grupo: Yup.string().required("El campo de grupo es requerido"),
      linea: Yup.string().required("El campo de línea es requerido"),
      lead_ocliente_existente: Yup.string().required("El campo de lead o cliente existente es requerido"),
      tipo_lead: Yup.string().required("El campo de tipo de lead es requerido"),
      motivo_descalificacion: Yup.string().required("El campo de motivo de descalificación es requerido"),
      //asignado_a: Yup.string().required("El campo de asignado a es requerido"),
      //fecha_asignacion_gerente_cuenta: Yup.date().required("El campo de fecha de asignación de gerente de cuenta es requerido"),
      //opv_creado: Yup.string().required("El campo de OPV creado es requerido"),
      //motivo_perdida: Yup.string().required("El campo de motivo de pérdida es requerido"),
    };
}

function updateSchema() {
  return {
    anio: Yup.string().required("El campo de año es requerido"),
    mes: Yup.string().required("El campo de mes es requerido"),
    empleado_ventas_lead: Yup.string().required("El campo de empleado de ventas lead es requerido"),
    origen_contacto: Yup.string().required("El campo de origen de contacto es requerido"),
    fecha_contacto: Yup.date().required("El campo de fecha de contacto es requerido"),
    pais: Yup.string().required("El campo de país es requerido"),
    nit: Yup.string().required("El campo de nit es requerido"),
    razon_social: Yup.string().required("El campo de razón social es requerido"),
    contacto: Yup.string().required("El campo de contacto es requerido"),
    cargo: Yup.string().required("El campo de cargo es requerido"),
    email: Yup.string().email("Formato de correo electrónico inválido").required("El campo de email es requerido"),
    numero_telefonico: Yup.string().required("El campo de número telefónico es requerido"),
    direccion: Yup.string().required("El campo de dirección es requerido"),
    descripcion: Yup.string().required("El campo de descripción es requerido"),
    sector_industrial: Yup.string().required("El campo de sector industrial es requerido"),
    grupo: Yup.string().required("El campo de grupo es requerido"),
    linea: Yup.string().required("El campo de línea es requerido"),
    lead_ocliente_existente: Yup.string().required("El campo de lead o cliente existente es requerido"),
    tipo_lead: Yup.string().required("El campo de tipo de lead es requerido"),
    motivo_descalificacion: Yup.string().required("El campo de motivo de descalificación es requerido"),
    //asignado_a: Yup.string().required("El campo de asignado a es requerido"),
    //fecha_asignacion_gerente_cuenta: Yup.date().required("El campo de fecha de asignación de gerente de cuenta es requerido"),
    //opv_creado: Yup.string().required("El campo de OPV creado es requerido"),
    //motivo_perdida: Yup.string().required("El campo de motivo de pérdida es requerido"),
  };
}
