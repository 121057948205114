import { Divider, Spin } from "antd";
import {
  CircleAbierto,
  CircleCerrado,
  CirclePendiente,
  ContainerContent,
  ContainerFlex,
  ContainerPopover,
  SpanNormal,
  TextBold,
  TextCrudo
} from "./styles";


export const PopoverContent = ({ data }) => {
  // Verificar que data exista y sea un objeto válido
  if (!data || typeof data !== "object") {
    // Si data no existe o no es un objeto válido, puedes mostrar un mensaje o componente de carga
    return <div>Cargando...</div>;
  }

  // Validar propiedades requeridas
  const ServiceCallID = data.ServiceCallID ?? "Num LDS Desconocido";
  const CustomerName = data.CustomerName ?? "Cliente Desconocido";
  const TechnicianName = data.TechnicianName ?? "Código del técnico Desconocido";
  const Subject = data.Subject ?? "Objetivo Desconocido";
  const ItemDescription = data.ItemDescription ?? "Descripción Desconocida";
  const ItemCode = data.ItemCode ?? "Código item Desconocido";
  const Calltype = data.CallType ?? "Tipo de llamada Desconocido";
  const Priority = data.Priority ?? "Prioridad Desconocida";
  const BPShipToAddress = data.BPShipToAddress ?? "Dirección Desconocida";
  const CreationDate = data.CreationDate ?? "Fecha de creación Desconocida";
  const ResolutionOnDate = data.ResolutionOnDate ?? "Fecha de resolución Desconocida";
  const ResponseOnTime = data.ResponseOnTime ?? "Tiempo de resolución Desconocido";
  const ClosingDate = data.ClosingDate ?? "Fecha de cierre Desconocida";
  const Resolution = data.Resolution ?? "Solución Desconocida";
  const Status = data.Status ?? "Estado Desconocido";

  // Continúa validando otras propiedades según lo necesario...

  const hour = new Date(data?.tiempo_auth)?.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  }) ?? "Hora Desconocida";

  // Renderizar el contenido una vez que las propiedades requeridas estén validadas
  return (
    <>
   
    <ContainerPopover>
     <ContainerContent>
  {/* <Avatar src={data.conductor_foto} /> */}
  <div>
    <ContainerFlex >
      
      <TextCrudo>{TechnicianName}</TextCrudo>
      <ContainerFlex style={{ justifyContent: "flex-end" }}>
        {Status === -1 && (
          <>
            <TextBold>Cerrado</TextBold>
            <CircleCerrado />
          </>
        )}
        {Status === -2 && (
          <>
            <TextBold>Pendiente</TextBold>
            <CirclePendiente />
          </>
        )}
        {Status === -3 && (
          <>
            <TextBold>Abierto</TextBold>
            <CircleAbierto />
          </>
        )}
      </ContainerFlex>
    </ContainerFlex>
    <TextBold>{CustomerName}</TextBold>
    <ContainerFlex style={{ justifyContent: "space-between", marginTop: "18px" }}>
      <TextBold>
        ID : <span>{ServiceCallID}</span>
      </TextBold>
      <TextBold>
        Tipo de llamada: <SpanNormal>{Calltype}</SpanNormal>
      </TextBold>
    </ContainerFlex>
    <TextBold>
      Prioridad: <SpanNormal>{Priority}</SpanNormal>
    </TextBold>
    <TextBold>
      Item: <SpanNormal>{ItemCode}</SpanNormal>
    </TextBold>
  </div>
</ContainerContent>


      <Divider style={{ margin: "8px 0" }} />

      {/* Aquí mostramos los datos uno por línea después del Divider */}


      <TextBold>{BPShipToAddress}</TextBold>
      
      <TextBold>
        Fecha de resolución: <SpanNormal>{ResolutionOnDate}</SpanNormal>
      </TextBold>
      <TextBold>
        Tiempo de respuesta: <SpanNormal>{ResponseOnTime}</SpanNormal>
      </TextBold>
      <TextBold>
        fecha de cierre: <SpanNormal>{ClosingDate}</SpanNormal>
      </TextBold>
      
      <TextBold>
        Objetivo: <SpanNormal>{Subject}</SpanNormal>
      </TextBold>
      <TextBold>
        Descripción: <SpanNormal>{ItemDescription}</SpanNormal>
      </TextBold>
      <TextBold>
        Solución: <SpanNormal>{Resolution}</SpanNormal>
      </TextBold>

      {/* Continuar mostrando los demás datos según sea necesario */}
    </ContainerPopover>
   
    </>
  );
};
