import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";

// Función para obtener los datos de un SurveysOrdersInvoices
export async function getUsersADApi(token) {
    const url = `${BASE_API}/userdb/users/`;
    return makeRequest(url, "GET", token);
}
  
// Función para actualizar los datos de un SurveysOrdersInvoices existente
export async function updateuserPermisosApi(id_user, dataCheck, token) {
  const url = `${BASE_API}/userdb/users-permisos/${id_user}`;
  return makeRequest(url, "PATCH", token, dataCheck);
}

export async function CambioPasswordApi(id_user, data, token) {
  const url = `${BASE_API}/userdb/cambio_contrasena/${id_user}`;
  return makeRequest(url, "PATCH", token, data);
}