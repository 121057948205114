import { Form, Input, Button, Space, Row } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/useAuth'; // Asegúrate de importar el hook useAuth adecuadamente
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { loginDjango } from '../../Api/user';
import { message } from 'antd';


export function LoginForm() {
  const { login } = useAuth();
  const [redirect, setRedirect] = useState(false);
  const [repass, setRepass] = useState(false);
  const user_temp_pass = process.env.REACT_APP_user_temp_password;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Correo electrónico no válido').required('Campo requerido'),
      password: Yup.string().required('Campo requerido'),
    }),

    onSubmit: async (values) => {
      try {
        const response = await loginDjango(values); // Aquí deberías llamar a la función de inicio de sesión con los valores del formulario
        const { token, mensaje } = response;
        login(token);
        message.success(mensaje);
        setRedirect(true);

        if(values.password == user_temp_pass){
          setRepass(true)
        }
      } catch (error) {
        message.error(error.message);
      }
    },
  });

  if(redirect && repass){
    return <Navigate to="/reset-password" />;
  }else{
    if(redirect){
      return <Navigate to="/home" />;
    }
  }
  
  return (
    <>
    <Form onFinish={formik.handleSubmit}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Por favor ingresa tu correo electrónico' },
          { type: 'email', message: 'Correo electrónico no válido' },
        ]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="Correo Electrónico"
          size='large'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Por favor ingresa tu contraseña' }
      ]}
        
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Contraseña"
          size='large'
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Item>

      <Form.Item>
        <Row justify="center">
          <Space>
            <Button type="primary" htmlType="submit" shape="round" size='large'>
              Ingresar
            </Button>
          </Space>
        </Row>
      </Form.Item>
    </Form>
    </>
  );
}


