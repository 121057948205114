import { useState } from "react";
import { getMeApi } from "../Api/user";


export function useUser() {
  const [ me, setMe] = useState(null);
  //traemos los datos de usuario almacenados en sesion
  //alertas para peticiones

  //peticion get de usuarios

  /**La función "getMe" es una función asíncrona que obtiene los datos del usuario actual a través de una llamada a la API "getMeApi".
   * El token de autenticación se pasa como parámetro. Si se produce un error, se lanza el error.
   * En caso contrario, se devuelve la respuesta del servidor. */
  const getMe = async (token) => {
    try {
      const response = await getMeApi(token);
      setMe(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  return {
    me,
    getMe,
  };
}