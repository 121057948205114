import { Tabs } from "antd";
import { SyziaForm } from "../../components/syzia/form";
import { SyziaTable } from "../../components/syzia/table";
import React, { useState } from "react";

export function SyziaPage() {
  const [recoveryData, setRecoveryData] = useState(null);
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key) => {
    setActiveTab(key)
  };

  const items = [
      {
          key: '1',
          label: '¡ Haz una pregunta a SYZAI !',
          children: <SyziaForm recoveryData={recoveryData} />
      },
      {
          key: '2',
          label: 'Histórico',
          children: <SyziaTable setRecoveryData={setRecoveryData} setActiveTab={handleTabChange} />
      }
  ];

  return (
      <>
          <Tabs 
              activeKey={activeTab}
              items={items}
              destroyInactiveTabPane
              onChange={handleTabChange}
          />
      </>
  );
}