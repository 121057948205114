import React, { useEffect, useCallback, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useSolicitudPer } from "../../../../hooks/administrativa/solicitud-permisos/useSolicitudPer";
import { ChangeStatusForBoss, ChangeStatusForGH } from "./form";
import { TablaGeneral } from "../../../comons/Tables/TablaPermisos";
import { useAuth } from "../../../../hooks/useAuth";
import { Tabs, message, Modal } from "antd";

const headersComisiones = [
    { label: "ID permiso", field: "Id_permiso" },
    { label: "Usuario creador", field: "user_name" },
    { label: "Cédula", field: "cedula" },
    { label: "Fecha creación", field: "fecha_creacion" },
    { label: "Fecha permiso", field: "fecha_permiso" },
    { label: "Hora inicial", field: "hora_ini" },
    { label: "Hora fin", field: "hora_fin" },
    { label: "Motivo", field: "motivo" },
    { label: "Jefe inmediato", field: "jefeinmediato" },
    { label: "Aprobación Jefe", field: "aprobadojefe" },
    { label: "Gestión humana", field: "gh" },
    { label: "Aprobación GH", field: "aprobadogh" },
    { label: "Archivo", field: "archivo" },
];

const popoverColumns = [
    { label: "Reposición", field: "reposicion" },
]

const downloadColumns = headersComisiones.concat(popoverColumns)


export function AprobacionJefe() {
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
    const [refetch, setRefetch] = useState(false);
    const [dataTable, setdataTable] = useState([]);
    const [showStatusModal, setStatusModal] = useState(false);
    const [contentStatusModal,  setContentStatusModal] = useState(null);
    const openCloseStatusModal = useCallback(() => setStatusModal((prev) => !prev), []);
    const { loading, SolicitudPer, getSolicitudesBoss, Respuesta } = useSolicitudPer();
    const { auth } = useAuth();

    let userId = auth.me?.id_user;    

    useEffect(() => {

        if (SolicitudPer !== null) {
            const processedData = SolicitudPer.map((item) => ({
                ...item,
                id_user: item.id_user.id_user,
                user_name: item.id_user.name_user
            }));

            setdataTable(processedData)
        }
    }, [SolicitudPer]);

    const handleStatusForm = useCallback(
        (data) => {
          setContentStatusModal(
            <ChangeStatusForBoss
              onClose={openCloseStatusModal}
              onRefetch={onRefetch}
              data={data}
            />
          );
          openCloseStatusModal();
        },
        [openCloseStatusModal, onRefetch]
      );

    // Maneja el estado del spin
    useEffect(() => {
        if (Respuesta !== null) {
            message.success(Respuesta);
        }
    }, [Respuesta]);

    useEffect(() => {
        getSolicitudesBoss(userId);
    }, [refetch])


    return (
        <>
        {loading ? (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </div>
        ) : (
            <>
                <br />
                <TablaGeneral
                    style={{ top: '100px' }}
                    Data={dataTable}
                    columns={headersComisiones}
                    popoverColumns={popoverColumns}
                    handleSendAprobJefe={handleStatusForm}
                    aprovJefe={1}
                    historial={0}
                />
            </>
        )}

        <Modal
            style={{
            top: 20,
            }}
            open={showStatusModal}
            onCancel={() => {openCloseStatusModal();}}
            footer={null}
            width=""
            title="Estado aprobación"
            destroyOnClose
            centered
            cancelButtonProps={false}
        >
        {contentStatusModal}
      </Modal>

        </>
    )
}

export function AprobacionGH() {
    const { loading, SolicitudPer, getSolicitudesGH, Respuesta } = useSolicitudPer();
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
    const [refetch, setRefetch] = useState(false);
    const [dataTable, setdataTable] = useState([]);
    const [showStatusModal, setStatusModal] = useState(false);
    const [contentStatusModal,  setContentStatusModal] = useState(null);
    const openCloseStatusModal = useCallback(() => setStatusModal((prev) => !prev), []);


    useEffect(() => {

        if (SolicitudPer !== null) {
            const processedData = SolicitudPer.map((item) => ({
                ...item,
                id_user: item.id_user.id_user,
                user_name: item.id_user.name_user
            }));

            setdataTable(processedData)
        }
    }, [SolicitudPer]);


    const handleStatusForm = useCallback(
        (data) => {
            setContentStatusModal(
            <ChangeStatusForGH
                onClose={openCloseStatusModal}
                onRefetch={onRefetch}
                data={data}
            />
            );
            openCloseStatusModal();
        },
        [openCloseStatusModal, onRefetch]
        );

    // Maneja el estado del spin
    useEffect(() => {
        if (Respuesta !== null) {
            message.success(Respuesta);
        }
    }, [Respuesta]);

    useEffect(() => {
        getSolicitudesGH();
    }, [refetch])


    return (
        <>
        {loading ? (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </div>
        ) : (
            <>
                <br />
                <TablaGeneral
                    style={{ top: '100px' }}
                    Data={dataTable}
                    columns={headersComisiones}
                    popoverColumns={popoverColumns}
                    handleSendAprobGH={handleStatusForm}
                    ExcelColumns={downloadColumns}
                    descarga={1}
                    historial={0}
                    aprovJefe={0}
                />
            </>
        )}

        <Modal
            style={{
            top: 20,
            }}
            open={showStatusModal}
            onCancel={() => {openCloseStatusModal();}}
            footer={null}
            width=""
            title="Estado aprobación"
            destroyOnClose
            centered
            cancelButtonProps={false}
        >
        {contentStatusModal}
      </Modal>  
        </>
    )
}

export function SolicitudPermisosTable() {
    const { loading, SolicitudPer, getSolicitudPer } = useSolicitudPer();
    const { auth } = useAuth();
    const [dataTable, setdataTable] = useState([]);
    const userID = auth.me?.id_user;

    useEffect(() => {

        if (SolicitudPer !== null) {
            const processedData = SolicitudPer.map((item) => ({
                ...item,
                id_user: item.id_user.id_user,
                user_name: item.id_user.name_user
            }));

            setdataTable(processedData)
        }
    }, [SolicitudPer]);

    useEffect(() => {
        getSolicitudPer(userID);
    }, []);

    return (
        <>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <br/>
                    <TablaGeneral
                        style={{ top: '100px' }}
                        Data={dataTable}
                        columns={headersComisiones}
                        popoverColumns={popoverColumns}
                        aprovJefe={0}
                        historial={1}
                    />
                </>
            )}
        </>
    );
}

export function SolicitudPermisosTableAprobaciones(props) {

    const { isGh } = props;
    
    const items = [
        {
            key: '1',
            label: 'Aprobador',
            children: <AprobacionJefe />,
        }
    ]

    const items2 = [
        {
            key: '1',
            label: 'Aprobador',
            children: <AprobacionJefe />,
        },
        {
            key: '2',
            label: 'Gestion Humana',
            children: <AprobacionGH />,
        }
    ]


    return (
        <>
            { isGh == false && (
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                    destroyInactiveTabPane
                />
            )}

            { isGh == true && (
                <Tabs
                    defaultActiveKey="1"
                    items={items2}
                    destroyInactiveTabPane
                />
            )}
        </>
    )
}