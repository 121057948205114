import React from 'react';
import { Tabs } from 'antd';
import { FormulariosSigTabsTables, FormulariosSigTabsTablesAdmin, FormulariosSigTabsTablesAprobMe } from '../../components/administrativa/formularios-sig/components/tabs';
import { useAuth } from '../../hooks/useAuth';


const items = [
    {
      key: '1',
      label: 'Consultas formularios',
      children: <FormulariosSigTabsTables />,
    },
    {
      key: '2',
      label: 'Mis aprobaciones',
      children: <FormulariosSigTabsTablesAprobMe />,
    },
];

const itemsAdmin = [
  {
      key: '1',
      label: 'Consultas formularios',
      children: <FormulariosSigTabsTables />,
  },
  {
      key: '2',
      label: 'Mis aprobaciones',
      children: <FormulariosSigTabsTablesAprobMe />,
  },
  {
      key: '3',
      label: 'Aprobaciones SIG',
      children: <FormulariosSigTabsTablesAdmin />,
  },
];

export  function FormulariosSigPage() {
  const { auth } = useAuth();
  const isSigAdmin = auth.me?.administrativo_sig_aprobador;


  return (
  <>
      { isSigAdmin === false && (
        <Tabs 
          defaultActiveKey='1'
          items={items}
          destroyInactiveTabPane
        />
      )}

      { isSigAdmin === true && (
        <Tabs 
          defaultActiveKey='1'
          items={itemsAdmin}
          destroyInactiveTabPane
        />
      )}
  </> 
)
};