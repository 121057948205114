import { useState } from "react";
import { getcomisionesApi, getdetallecomisionesApi, addcomisionesapi } from "../Api/comisiones";

import { useAuth } from "./useAuth";


export function useComisiones() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    message: null,
    Comisiones: null,
    DetalleComisiones: null,
  });

  const { loading, error, Comisiones, DetalleComisiones, message } = state;
  const { auth } = useAuth();

  /* const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getComisiones = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getcomisionesApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        Comisiones: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getDetalleComisiones = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getdetallecomisionesApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        DetalleComisiones: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addComisiones = async ( data ) => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  addcomisionesapi(data, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        message: response,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };



  return {
    loading,
    error,
    message,
    Comisiones,
    DetalleComisiones,
    getComisiones,
    getDetalleComisiones,
    addComisiones,
  };
}