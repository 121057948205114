// token.js
import { TOKEN_KEY } from "../utils/constants";

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  const token = localStorage.getItem(TOKEN_KEY);
  return token;
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

