import { useCallback, useEffect, useState } from 'react';
import { useArchivos } from '../hooks/useArchivos';
import { TablaBase } from '../components/comons/TablaBase';
import CircularProgress from '@mui/material/CircularProgress';
import { AddEdit } from '../components/AddEdit';
import { Box, Button } from '@mui/material';
import { Modal } from 'antd';
export default function UserPage() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const {
    loading,
    Archivos,
    getArchivos,
    deleteArchivos,
  } = useArchivos();

  useEffect(() => {
    getArchivos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  
  const handleAdd = useCallback(() => {
    setContentModal(
      <AddEdit onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback((data) => {
    setContentModal(
      <AddEdit
        onClose={openCloseModal}
        onRefetch={onRefetch}
        archivos={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleDelete = useCallback((archivo) => {
    deleteArchivos(archivo.id);
    onRefetch();
  }, [deleteArchivos, onRefetch]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
      <TablaBase
        Archivos={Archivos}
        handleAgregarArchivo={handleAdd}
        handleEditarArchivo={handleUpdate}
        handleEliminarArchivo={handleDelete}
      />
      )}
<Modal
        open={showModal}
        onCancel={openCloseModal}
        footer={null}
        width="70%"
        title="Videos"
        destroyOnClose={true} // Esto destruirá el contenido del modal cuando se cierre
      >
        {contentModal}
      </Modal>
    </>
  );
}

