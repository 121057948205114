import { useState } from "react";
import {
getUsersADApi,
updateuserPermisosApi,
CambioPasswordApi,
} from "../Api/usersAD";  
import { useAuth } from "./useAuth";

export function useUsersAD() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    UsuariosDA: null,
  });

  const { loading, error, UsuariosDA } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getUsuariosAD = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getUsersADApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        UsuariosDA: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const updateUsersPermission = async (id_user, field, checked) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const dataCheck = { [field]: checked };
      const [result, response] = await updateuserPermisosApi(id_user, dataCheck, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updatePassword = async (id, password, repassword) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const data = {"password": password, "repassword": repassword }
      const [result, response] = await CambioPasswordApi(id, data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
      return { success: true, mensaje: result.message };

    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  return {
    loading,
    error,
    UsuariosDA,
    getUsuariosAD,
    updateUsersPermission,
    updatePassword,
  };
}