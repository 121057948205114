import { Tabs } from 'antd';
import { RiesgoPotencialTable } from '../tables/riego-potencial';
import { ControlRiesgosTable } from '../tables/control-riesgos';
import { AnalisisTrabajoTable } from '../tables/analisis-trabajo';
import { CondicionesSaludTable } from '../tables/condiciones-salud';
import { AccesoPrevencionTable } from '../tables/acceso-prevencion';
import { InspeccionAlturasTable } from '../tables/inspeccion-altura';
import { VerificacionDocsTable } from '../tables/verificacion-doc';
import { QuienElaboraTable } from '../tables/quien-elabora';
import { QuienDifundeTable } from '../tables/quien-difunde';
import { SupervisorCoordinadorFormTable } from '../tables/super-coor';
import { FormularioSigTable, FormularioSigTableAdmin, FormularioSigTableAprobMe } from '../tables/formulario-sig';


/* Tabs dependiendo a la vista */
const itemsTables = [
  {
    key: '1',
    label: 'Datos de la actividad',
    children: <FormularioSigTable />,
  },
];

const itemsTablesAprobMe = [
  {
    key: '1',
    label: 'Solicitud de aprobación recibidas',
    children: <FormularioSigTableAprobMe />,
  },
];

const itemsTablesAdmin = [
  {
    key: '1',
    label: 'Datos de la actividad',
    children: <FormularioSigTableAdmin />,
  },
];

//Tabs para visualizar toda la información de los formularios que se suben para cada usuario
export  function FormulariosSigTabsTables() {

  return (
    <Tabs 
        defaultActiveKey='1'
        items={itemsTables}
        destroyInactiveTabPane
    />
  )
}

//Tabs para revisar los formularios a aprobar como difundido
export  function FormulariosSigTabsTablesAprobMe() {

  return (
    <Tabs 
        defaultActiveKey='1'
        items={itemsTablesAprobMe}
        destroyInactiveTabPane
    />
  )
}

//Tabs para administradores, permite cambiar el estado de aprobación de los formularios
export function FormulariosSigTabsTablesAdmin(){

  return (
    <Tabs 
        defaultActiveKey='1'
        items={itemsTablesAdmin}
        destroyInactiveTabPane
    />
  )
}