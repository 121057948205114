import { useState } from "react";
import {
  getExcelApi,
  addExcelApi,
  updateExcelApi,
  deleteExcelApi,
} from "../Api/excel";  
import { useAuth } from "./useAuth";
export function useExcel() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    Excel: null,
  });

  const { loading, error, Excel } = state;
  const { auth } = useAuth();
  const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  };

  const getExcel = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getExcelApi(auth.token);
      handleResponse(response);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        Excel: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addExcel = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addExcelApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateExcel = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateExcelApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteExcel = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteExcelApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    Excel,
    getExcel,
    addExcel,
    updateExcel,
    deleteExcel,
  };
}