import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../hooks/useAuth";
import { TablaGeneral } from "../../components/comons/TablaGeneral";
import { Modal } from 'antd';
import { useSurveysOrdersInvoices } from '../../hooks/useSurveysOrdersInvoices'
import { SurveysOrdersInvoicesForm } from "../../components/Marketing/SurveysOrdersInvoicesForm";


const headers = [
  { label: "ID Encuesta", field: "id_encuestas_pf" },
  { label: "Empresa", field: "empresa" },
  { label: "Nombres y Apellidos", field: "nombres_apellidos" },
  { label: "Cargo", field: "cargo" },
  { label: "Correo", field: "correo" },
  { label: "Celular", field: "celular" },
  { label: "Documento", field: "documento" },
  { label: "Fecha de la encuesta", field: "fecha_documento" },
];

const popoverColumns = [
  { label: "ID Encuesta", field: "id_encuestas_pf" },
  { label: "¿Qué tan difícil o fácil fue resolver su solicitud con SYZ?", field: "p9" },
  { label: "¿Qué tanto recomendaria nuestra marca?", field: "p10" },
  { label: "¿Tiene usted algún comentario, felicitación, queja o reclamo respecto al servicio?", field: "p11" },
];


export  function SurveysOrdersInvoicesPage() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [contentModal, setContentModal] = useState(null);
  const { auth } = useAuth();
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const { loading, SurveysOrdersInvoices, getSurveysOrdersInvoices, deleteSurveysOrdersInvoices } = useSurveysOrdersInvoices();

  useEffect(() => {
    getSurveysOrdersInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  const handleAdd = useCallback(() => {
    setContentModal(
      <SurveysOrdersInvoicesForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setContentModal(
        <SurveysOrdersInvoicesForm
          onClose={openCloseModal}
          onRefetch={onRefetch}
          SurveysOrdersInvoices={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    (data) => {
      deleteSurveysOrdersInvoices(data.id_encuestas_pf);
      onRefetch();
    },
    [deleteSurveysOrdersInvoices, onRefetch]
  );
  
  return (
    <>
    {loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    ) : (

      <>
        {(auth.me?.marketing_full_modify) && (
        <TablaGeneral
          Data={SurveysOrdersInvoices}
          columns={headers}
          popoverColumns={popoverColumns}
          handleAgregarData={handleAdd}
          handleEditarData={handleUpdate}
          handleEliminarData={handleDelete}
        />
        )}

        {(auth.me?.marketing_only_view && !auth.me?.marketing_full_modify) && (
        <TablaGeneral
          Data={SurveysOrdersInvoices}
          columns={headers}
          popoverColumns={popoverColumns}
        />
        )}
        </>

    )}
     <Modal
        open={showModal}
        onCancel={openCloseModal}
        footer={null}
        width="70%"
        title="NPS Y CES"
        destroyOnClose={true} // Esto destruirá el contenido del modal cuando se cierre
      >
        {contentModal}
      </Modal>
    
  </>
  )
}
