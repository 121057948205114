import React, { useState, useEffect, createContext } from "react";
import { setToken, getToken, removeToken } from "../Api/token";
import { useUser } from "../hooks/useUser";
import { Alertas } from "../components/comons/Alertas/alertas";

 /*contexto para login y logout el cual  almacena datos como el token y el user data en local host*/

export const AuthContext = createContext({
  auth: undefined,
  login: () => null,
  logout: () => null,
});

export function AuthProvider(props) {
  const { children } = props;
  const [ auth, setAuth] = useState(undefined);
  const { getMe } = useUser();
  const { SessionExpired } = Alertas()

  //refresh del token 
  useEffect(() => {
    (async () => {
      const token = getToken();
      if (token) {
        const me = await getMe(token);
        if(me.code == "token_not_valid"){
          removeToken();
          setAuth(null);
          SessionExpired();
        }
        setAuth({ token, me });
      } else {
        setAuth(null);
      }
    })();
  }, []);

  const login = async (token) => {
    //lo setea por medio de la funcion setToken que se encuentra en api
    setToken(token);
    const me = await getMe(token);
    setAuth({ token, me });
  };

  const logout = () => {
    if (auth) {
      removeToken();
      setAuth(null);
    }
  };

  const valueContext = {
    //llamamos a las funciones creadas anteriormente para que sean implementadas en el ValueContext
    auth,
    login,
    logout,
  };

  //elimina flash
  if (auth === undefined) return null;

  return (
    <AuthContext.Provider value={valueContext}>{children}</AuthContext.Provider>
  );
}