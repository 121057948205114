import { Popover, Tag } from "antd";
import { PopoverContent } from "../../../components/PopoverContent";
import {  Spin } from "antd";
export const TagCalendar = ({
  onMouseEnter,
  nOrden,
  hour,
  type,
  color,
  dataPopover,
  fontSize,
  loading
}) => {
  return (
    <Popover
      placement="bottomRight"
      content={
        <>
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Spin size="large" />
          </div>
        ) : (
      
      <PopoverContent data={dataPopover} loading={loading} />
        )}
        </>
    }
      trigger="hover"
    >

      <Tag
        color={color}
        onMouseEnter={onMouseEnter}
        style={{
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column", // Ajustar el contenido para que se muestre en columnas
          alignItems: "center", // Centrar el contenido horizontalmente
      
          wordWrap: "break-word", // Permitir que el texto se envuelva dentro del ancho máximo
          textOverflow: "ellipsis", // Agregar una elipsis al final del texto que se desborda
          overflow: "hidden", // Ocultar el texto que se desborda
        }}
      >
        <p
          style={{
            fontSize: `${fontSize ?? "8px"}`,
            fontWeight: 700,
            margin: 0,
          }}
        >
          {type} - {nOrden}
        </p>
        <p
          style={{
            fontSize: `${fontSize ?? "8px"}`,
            fontWeight: 700,
            margin: 0,
          }}
        >
          {hour}
        </p>
      </Tag>
    </Popover>
  );
};
