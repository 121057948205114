import { useState } from "react";
import { 
    getRiesgoPotencialApi,
    addRiesgoPotencialApi,
    updateRiesgoPotencialApi,
    deleteRiesgoPotencialApi,
    getRiesgoPotencialApiID,
} from "../../../Api/administrativa/formularios-sig/riesgo-potencial";

import { useAuth } from "../../useAuth";

export function useRiesgoPotencial() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    RiesgoPotencial: null,
  });

  const { loading, error, RiesgoPotencial } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getRiesgoPotencial = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getRiesgoPotencialApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        RiesgoPotencial: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getRiesgoPotencialID = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getRiesgoPotencialApiID(id, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        RiesgoPotencial: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addRiesgoPotencial = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addRiesgoPotencialApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateRiesgoPotencial = async (id, data, tipoForm) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateRiesgoPotencialApi(id, data, auth.token);

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteRiesgoPotencial = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteRiesgoPotencialApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  }

  return {
    loading,
    error,
    RiesgoPotencial,
    getRiesgoPotencial,
    addRiesgoPotencial,
    updateRiesgoPotencial,
    deleteRiesgoPotencial,
    getRiesgoPotencialID,
    cancelLoader,
  };
}