import { BASE_API } from "../../../utils/constants";
import { makeRequest } from "../../Request";

  
  // Función para obtener los datos de un Competence
  export async function getFormularioSigaApi(token) {
    const url = `${BASE_API}/formularios-sig/api/Formulario/`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos de un Competence
  export async function getFormularioSigaApibyuser(Id_creador, token) {
    const url = `${BASE_API}/formularios-sig/api/FormularioByUser?id_creador=${Id_creador}`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos de un Competence
  export async function getFormularioSigaApibyEmail(email_user, token) {
    const url = `${BASE_API}/formularios-sig/api/DifundidoByEmail?email_user=${email_user}`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo Competence
  export async function addFormularioSigApi(data, token) {
    const url = `${BASE_API}/formularios-sig/api/Formulario/`;
    return makeRequest(url, "POST", token, data);
  }

  export async function SendEmailDifundidosApi(id, token) {
    const url = `${BASE_API}/formularios-sig/api/EmailAprobadores/${id}/Adifundidos/`;
    return makeRequest(url, "POST", token)
  }

  export async function SendEmailSupervisorApi(id, token) {
    const url = `${BASE_API}/formularios-sig/api/EmailAprobadores/${id}/Aprobador/`;
    return makeRequest(url, "POST", token)
  }
  
  // Función para actualizar los datos de un Competence existente
  export async function updateFormularioSigApi(id, data, token) {
    const url = `${BASE_API}/formularios-sig/api/Formulario/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }

  export async function updateStatusFormularioSigApi(id, data, token) {
    const url = `${BASE_API}/formularios-sig/api/FormularioStatus/${id}/`;
    return makeRequest(url, "PATCH", token, data);
  }

  export async function updateStatusFormularioSigMeApi(id, status, difundidoFormEmail, token) {
    const url = `${BASE_API}/formularios-sig/api/DifundidoStatusByEmail/?id_form_difundido=${id}&status=${status}&email_user=${difundidoFormEmail}`;
    return makeRequest(url, "PATCH", token);
  }
  
  export async function deleteFormularioSigApi(id, token) {
    const url = `${BASE_API}/formularios-sig/api/Formulario/${id}/`;
    return makeRequest(url, "DELETE", token);
  }