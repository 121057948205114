import { BASE_API } from "../../../utils/constants";
import { makeRequest } from "../../Request";

  
  // Función para obtener los datos de un Competence
  export async function getControlriesgosApi(token) {
    const url = `${BASE_API}/formularios-sig/api/ControlriesgosGetTable/`;
    return makeRequest(url, "GET", token);
  }

  export async function getControlriesgosApiID(id, token) {
    const url = `${BASE_API}/formularios-sig/api/Controlriesgos/${id}/`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo Competence
  export async function addControlriesgosApi(data, token) {
    const url = `${BASE_API}/formularios-sig/api/Controlriesgos/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos de un Competence existente
  export async function updateControlriesgosApi(id, data, token) {
    const url = `${BASE_API}/formularios-sig/api/Controlriesgos/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar un Competence
  export async function deleteControlriesgosApi(id, token) {
    const url = `${BASE_API}/formularios-sig/api/Controlriesgos/${id}/`;
    return makeRequest(url, "DELETE", token);
  }