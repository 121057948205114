import { Button, Col, Form, Input, Row, message, Typography,Select } from "antd";

import { Formik, useFormik } from "formik";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { useArchivos } from "../hooks/useArchivos";
import { formStyle } from "./comons/styles";
const { Option } = Select;

export function AddEdit(props) {
  const { onClose, onRefetch, archivos } = props;
  const { addArchivos, updateArchivos } = useArchivos();
  const [previewVideo, setPreviewVideo] = useState(archivos?.foto || null);
  const [uploading, setUploading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(archivos),
    validationSchema: Yup.object(archivos ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        setUploading(true);

        if (archivos) await updateArchivos(archivos.id, formValue);
        else await addArchivos(formValue);

        onRefetch();
        onClose();
        
        message.success("Operación exitosa");
      } catch (error) {
        if (error?.message) {
          message.error(error.message);
         
        } else {
          onClose();
        }
      } finally {
        setUploading(false);
      }
    },
  });

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("video", file);
    setPreviewVideo(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:
      "image/*, video/*, .pdf, .xls, .xlsx, .csv, .doc, .docx, .ppt, .pptx",

    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  
  return (
    <Form layout="vertical" onFinish={formik.handleSubmit} style={formStyle}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="ID Curso"
            validateStatus={
              formik.errors.id_curso && formik.touched.id_curso ? "error" : ""
            }
            help={
              formik.touched.id_curso && formik.errors.id_curso
                ? formik.errors.id_curso
                : ""
            }
          >
            <Input
              name="id_curso"
              value={formik.values.id_curso}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
        <Form.Item
            label="Nombre del video"
            validateStatus={
              formik.errors.nombre_video && formik.touched.nombre_video
                ? "error"
                : ""
            }
            help={
              formik.touched.nombre_video && formik.errors.nombre_video
                ? formik.errors.nombre_video
                : ""
            }
          >
            <Input
              name="nombre_video"
              value={formik.values.nombre_video}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item
            label="Ruta Asociada"
            validateStatus={
              formik.errors.ruta_asociada && formik.touched.ruta_asociada ? "error" : ""
            }
            help={formik.touched.ruta_asociada && formik.errors.ruta_asociada ? formik.errors.ruta_asociada : ""}
          >
            <Input
              name="ruta_asociada"
              value={formik.values.ruta_asociada}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>


      </Row>
      <Row gutter={16}>
      <Col span={8}>
      <Form.Item
        label="Grupo"
        validateStatus={formik.errors.grupo && formik.touched.grupo ? 'error' : ''}
        help={formik.touched.grupo && formik.errors.grupo ? formik.errors.grupo : ''}
      >
        <Select
          name="grupo"
          value={formik.values.grupo}
          onChange={(value) => formik.setFieldValue('grupo', value)} // Actualiza el valor en formik
          onBlur={formik.handleBlur}
          placeholder="Selecciona una opción"
        >
          <Option value="AUTOMATIZACION">AUTOMATIZACION</Option>
          <Option value="ELECTRICA">ELECTRICA</Option>
          <Option value="INSTRUMENTACION">INSTRUMENTACION</Option>
          <Option value="MECANICA">MECANICA</Option>
          <Option value="SERVICIOS">SERVICIOS</Option>
          <Option value="SYZTEMAS">SYZTEMAS</Option>
          <Option value="ADMINISTRATIVO">ADMINISTRATIVO</Option>
          <Option value="COMERCIAL">COMERCIAL</Option>
          <Option value="ESTRATEGICA">ESTRATEGICA</Option>
          <Option value="FINANCIERA">FINANCIERA</Option>
          <Option value="INNOVACION">INNOVACION</Option>
          <Option value="LOGISTICA">LOGISTICA</Option>
          <Option value="SOPORTE">SOPORTE</Option>
          <Option value="TECNICA">TECNICA</Option>
          <Option value="TIC">TIC</Option>
          <Option value="WEBINAR">WEBINAR</Option>
        </Select>
      </Form.Item>
    </Col>
    <Col span={8}>
          <Form.Item
            label="Linea"
            validateStatus={
              formik.errors.linea && formik.touched.linea ? "error" : ""
            }
            help={
              formik.touched.linea && formik.errors.linea
                ? formik.errors.linea
                : ""
            }
          >
            <Input
              name="linea"
              value={formik.values.linea}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Sublinea"
            validateStatus={
              formik.errors.sublinea && formik.touched.sublinea ? "error" : ""
            }
            help={
              formik.touched.sublinea && formik.errors.sublinea
                ? formik.errors.sublinea
                : ""
            }
          >
            <Input
              name="sublinea"
              value={formik.values.sublinea}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
      <Col span={8}>
          <Form.Item
            label="IT"
            validateStatus={
              formik.errors.it && formik.touched.it ? "error" : ""
            }
            help={formik.touched.it && formik.errors.it ? formik.errors.it : ""}
          >
            <Input
              name="it"
              value={formik.values.it}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
      </Col>
      <Col span={8}>
          <Form.Item
            label="Marca"
            validateStatus={
              formik.errors.marca && formik.touched.marca ? "error" : ""
            }
            help={formik.touched.marca && formik.errors.marca ? formik.errors.marca : ""}
          >
            <Input
              name="marca"
              value={formik.values.marca}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
      </Col>
      <Col span={8}>
          <Form.Item
            label="Referencia"
            validateStatus={
              formik.errors.referencia && formik.touched.referencia ? "error" : ""
            }
            help={formik.touched.referencia && formik.errors.referencia ? formik.errors.referencia : ""}
          >
            <Input
              name="referencia"
              value={formik.values.referencia}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
    <Col span={12}>
          <Form.Item
            label="Modulos"
            validateStatus={
              formik.errors.modulos && formik.touched.modulos ? "error" : ""
            }
            help={formik.touched.modulos && formik.errors.modulos ? formik.errors.modulos : ""}
          >
            <Select
          name="modulos"
          value={formik.values.modulos}
          onChange={(value) => formik.setFieldValue('modulos', value)} // Actualiza el valor en formik
          onBlur={formik.handleBlur}
          placeholder="Selecciona una opción"
        >
          <Option value="APLICACIONES">APLICACIONES</Option>
          <Option value="CALIBRACIÓN/PRUEBAS">CALIBRACIÓN/PRUEBAS</Option>
          <Option value="CONFIGURACIÓN/PROGRAMACIÓN">CONFIGURACIÓN/PROGRAMACIÓN</Option>
          <Option value="CONCEPTOS BÁSICOS (GLOSARIO)">CONCEPTOS BÁSICOS (GLOSARIO)</Option>
          <Option value="CONCLUSIONES">CONCLUSIONES</Option>
          <Option value="CRITERIOS DE DISEÑO Y SELECCIÓN">CRITERIOS DE DISEÑO Y SELECCIÓN</Option>
          <Option value="EVALUACIÓN">EVALUACIÓN</Option>
          <Option value="ESPECIFICACIONES TÉCNICAS/GENERALES">ESPECIFICACIONES TÉCNICAS/GENERALES</Option>
          <Option value="GARANTÍAS">GARANTÍAS</Option>
          <Option value="HISTORIA O ANTECEDENTES DEL PRODUCTO">HISTORIA O ANTECEDENTES DEL PRODUCTO</Option>
          <Option value="HOJA DE DATOS">HOJA DE DATOS</Option>
          <Option value="INSTALACIÓN">INSTALACIÓN</Option>
          <Option value="INTRODUCCIÓN">INTRODUCCIÓN</Option>
          <Option value="LISTADO DE HERRAMIENTAS">LISTADO DE HERRAMIENTAS</Option>
          <Option value="MANTENIMIENTO">MANTENIMIENTO</Option>
          <Option value="N/A">N/A</Option>
          <Option value="NORMATIVIDAD">NORMATIVIDAD</Option>
          <Option value="OBJETIVOS">OBJETIVOS</Option>
          <Option value="OPERACIÓN">OPERACIÓN</Option>
          <Option value="PREGUNTAS FRECUENTES">PREGUNTAS FRECUENTES</Option>
          <Option value="PRINCIPIOS DE OPERACIÓN">PRINCIPIOS DE OPERACIÓN</Option>
          <Option value="PROBLEMAS Y SOLUCIONES">PROBLEMAS Y SOLUCIONES</Option>
          <Option value="RECOMENDACIONES DE SEGURIDAD">RECOMENDACIONES DE SEGURIDAD</Option>
          <Option value="TIPOS/TECNOLOGÍAS/CLASIFICACIÓN">TIPOS/TECNOLOGÍAS/CLASIFICACIÓN</Option>
          <Option value="UNBOXING">UNBOXING</Option>
          <Option value="VENTAJAS Y DESVENTAJAS">VENTAJAS Y DESVENTAJAS</Option>
        </Select>
          </Form.Item>
      </Col>
      <Col span={12}>
          <Form.Item
            label="Tipo de archivo"
            validateStatus={
              formik.errors.tipo_archivo && formik.touched.tipo_archivo ? "error" : ""
            }
            help={formik.touched.tipo_archivo && formik.errors.tipo_archivo ? formik.errors.tipo_archivo : ""}
          >
            <Select
          name="tipo_archivo"
          value={formik.values.tipo_archivo}
          onChange={(value) => formik.setFieldValue('tipo_archivo', value)} // Actualiza el valor en formik
          onBlur={formik.handleBlur}
          placeholder="Selecciona una opción"
        >
          <Option value="VIDEO">VIDEO</Option>
          <Option value="LITERATURA">LITERATURA</Option>
        </Select>
          </Form.Item>
      </Col>
    </Row>
      
      <Row gutter={16}>
      <Col span={24}>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed",
          padding: "2rem",
          textAlign: "center",
          cursor: "pointer",
          borderColor: isDragActive ? "green" : "gray",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="body2">Suelta el archivo aquí...</Typography>
        ) : (
          <Typography variant="body2">
            Arrastra y suelta el archivo aquí, o haz clic para seleccionar el
            video
          </Typography>
        )}
      </div>
      {previewVideo && (
        <video width="320" height="240" controls>
          <source src={previewVideo} type="video/mp4" />
        </video>
      )}
      {formik.touched.video && formik.errors.video && (
        <Typography color="error" variant="subtitle2">
          {formik.errors.video}
        </Typography>
      )}
      </Col>
</Row>

  
      <Form.Item>
        <Button type="primary" disabled={uploading} htmlType="submit">
        {uploading ? "Cargando..." : "Guardar"}
        </Button>
      </Form.Item>
    </Form>
  );
}

function initialValues(data) {
  return {
    id_curso: data?.id_curso || "",
    nombre_video: data?.nombre_video || "",
    ruta_asociada: data?.ruta_asociada || "",
    grupo: data?.grupo || "",
    linea: data?.linea || "",
    sublinea: data?.sublinea || "",
    it: data?.it || "",
    marca: data?.marca || "",
    referencia: data?.referencia || "",
    modulos: data?.modulos || "",
    tipo_archivo: data?.tipo_archivo || "",
    video: "",
  };
}

function newSchema() {
  return {
    id_curso: Yup.string().required("El campo curso es requerido"),
    nombre_video: Yup.string().required("El campo nombre de video es requerido"),
    ruta_asociada: Yup.string().required("El campo ruta asociada es requerido"),
    grupo: Yup.string().required("El campo de grupo es requerido"),
    linea: Yup.string().required("El campo línea es requerido"),
    sublinea: Yup.string().required("El campo sublínea es requerido"),
    it: Yup.string(),
    marca: Yup.string().required("El campo de marca es requerido"),
    referencia: Yup.string().required("El campo de referencia es requerido"),
    modulos: Yup.string().required("El campo de modulos es requerido"),
    tipo_archivo: Yup.string().required("El campo de tipo archivo es requerido"),
    video: Yup.mixed().required("El campo video es requerido"),
  };
}

function updateSchema() {
  return {
    id_curso: Yup.string().required("El campo curso es requerido"),
    nombre_video: Yup.string().required("El campo nombre de video es requerido"),
    ruta_asociada: Yup.string().required("El campo ruta asociada es requerido"),
    grupo: Yup.string().required("El campo de grupo es requerido"),
    linea: Yup.string().required("El campo línea es requerido"),
    sublinea: Yup.string().required("El campo sublínea es requerido"),
    it: Yup.string(),
    marca: Yup.string().required("El campo de la marca es requerido"),
    referencia: Yup.string().required("El campo de referencia es requerido"),
    modulos: Yup.string().required("El campo de modulos es requerido"),
    tipo_archivo: Yup.string().required("El campo de tipo archivo es requerido"),
    video: Yup.mixed(), // Eliminar la validación de campo requerido
  };
}
