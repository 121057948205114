import { useState } from "react";
import { 
    getAnalisistrabajosApi,
    addAnalisistrabajosApi,
    updateAnalisistrabajosApi,
    deleteAnalisistrabajosApi,
    getAnalisistrabajosApiID,
} from "../../../Api/administrativa/formularios-sig/analisis-trabajo";

import { useAuth } from "../../useAuth";

export function useAnalisisTrabajo() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    AnalisisTrabajo: null,
  });

  const { loading, error, AnalisisTrabajo } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getAnalisisRiesgos = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getAnalisistrabajosApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        AnalisisTrabajo: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getAnalisisRiesgosID = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      // Llamada a la API con el filtro por ID de formulario
      const [result, response] = await getAnalisistrabajosApiID(id, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        AnalisisTrabajo: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addAnalisisRiesgos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addAnalisistrabajosApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateAnalisisRiesgos = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateAnalisistrabajosApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteAnalisisRiesgos = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteAnalisistrabajosApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    AnalisisTrabajo,
    getAnalisisRiesgos,
    addAnalisisRiesgos,
    updateAnalisisRiesgos,
    deleteAnalisisRiesgos,
    getAnalisisRiesgosID,
    cancelLoader
  };
}