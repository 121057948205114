import { useState } from "react";
import { 
    getInspeccionAlturasApi,
    addInspeccionAlturaApi,
    updateInspeccionAlturaApi,
    deleteInspeccionAlturaApi,
    getInspeccionAlturasApiID
} from "../../../Api/administrativa/formularios-sig/inspeccion-alturas";

import { useAuth } from "../../useAuth";

export function useInspeccionAltura() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    InspeccionAltura: null,
  });

  const { loading, error, InspeccionAltura } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getInspeccionAlturas = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getInspeccionAlturasApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        InspeccionAltura: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getInspeccionAlturasID = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getInspeccionAlturasApiID(id, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        InspeccionAltura: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addInspeccionAlturas = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addInspeccionAlturaApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateInspeccionAlturas = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateInspeccionAlturaApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteInspeccionAlturas = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteInspeccionAlturaApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    InspeccionAltura,
    getInspeccionAlturas,
    addInspeccionAlturas,
    updateInspeccionAlturas,
    deleteInspeccionAlturas,
    getInspeccionAlturasID,
    cancelLoader,
  };
}