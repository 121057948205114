import { useState } from "react";
import {
  getComercialApi,
  addComercialApi,
  updateComercialApi,
  deleteComercialApi,
} from "../Api/Comercial";
import { useAuth } from "./useAuth";


export function useComercial() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    Comercial: null,
  });

  const { loading, error, Comercial } = state;
  const { auth } = useAuth();

  /* const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getComercial = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getComercialApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        Comercial: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addComercial = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addComercialApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateComercial = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateComercialApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteComercial = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteComercialApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    Comercial,
    getComercial,
    addComercial,
    updateComercial,
    deleteComercial,
  };
}