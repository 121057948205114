import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message, Card } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { CircularProgress } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import Background from '../assets/cardnameground.png';
import { useUsersAD } from '../hooks/useUserAD';
import { useUser } from '../hooks/useUser';

export default function HomePage() {
  const { updatePassword } = useUsersAD();
  const [visible, setVisible] = useState(false);
  const { auth } = useAuth();
  const { loading, getMe } = useUser();
  const [form] = Form.useForm();
  const { Meta } = Card;
  const id_user = auth.me?.id_user;

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    message.loading("Actualizando la contraseña.....")
    try {
      const values = await form.validateFields();
      const { success, mensaje } = await updatePassword(id_user, values.password, values.confirmPassword);

      if (success) {
        message.success(mensaje);
        setVisible(false);
        form.resetFields();
      }
    } catch (error) {
      message.error('Hubo un problema al actualizar la contraseña. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Card
          hoverable
          style={{
            width: 500,
            margin: 'auto',
            textAlign: 'center',
            border: '4px solid rgb(68, 68, 68)',
            padding: 1,
          }}
          cover={<img alt="example" src={Background} style={{ height: 250, borderRadius: 2 }} />}
          actions={[
            <Button danger style={{ width: 300, height: 45 }} onClick={handleOpenModal}>
              <EditOutlined key="edit" /> Editar contraseña
            </Button>,
          ]}
        >
          {/* Contenido de la tarjeta */}
          <Meta
            description={auth.me?.full_name}
            style={{ fontSize: '25px', fontFamily: 'cursive', fontWeight: 'bold' }}
          />
          <Meta
            description={auth.me?.area}
            style={{ fontSize: '15px', fontFamily: 'cursive', fontWeight: 'bold' }}
          />
          <Meta
            description={auth.me?.cargo}
            style={{ fontSize: '15px', fontFamily: 'cursive', fontWeight: 'bold' }}
          />
        </Card>
      )}
      
      {/* Modal para editar contraseña */}
      <Modal
        title={`Editar Contraseña de ${auth.me?.full_name}`}
        open={visible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="cancel" onClick={handleCloseModal}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="password"
            label="Nueva Contraseña"
            rules={[
              { required: true, message: 'Por favor ingrese la nueva contraseña' },
              { min: 8, message: 'La contraseña debe tener al menos 8 caracteres' },
              { pattern: /[a-z]/, message: 'La contraseña debe tener al menos una letra minúscula' },
              { pattern: /[A-Z]/, message: 'La contraseña debe tener al menos una letra mayúscula' },
              { pattern: /\d/, message: 'La contraseña debe tener al menos un número' },
              { pattern: /[^a-zA-Z\d]/, message: 'La contraseña debe tener al menos un carácter especial' },
            ]}
          >
            <Input.Password placeholder="Nueva contraseña" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirmar Contraseña"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Por favor confirme la nueva contraseña' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Las contraseñas no coinciden');
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirmar contraseña" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
