import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Card,
  Container,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Dropdown, Menu } from "antd";
import filter from "@inovua/reactdatagrid-community/filter";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";


import React, { useState, useCallback } from "react";
import CustomPopover from "./CustomPopover";
import { cellStyleComisiones, buttonStyle } from "./styles";
import { useAuth } from "../../hooks/useAuth";
import * as XLSX from "xlsx";


export function TablaGeneral(props) {
  const {
    Data,
    handleAgregarData,
    handleEditarData,
    handleEliminarData,
    columns,
    popoverColumns,
    ExcelColumns,
    descarga,
  } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { auth } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredData =
  Array.isArray(Data) &&
  Data.filter((data) =>
    columns.some((header) =>
      data[header.field]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );
  
  const rowsToShow =
    filteredData &&
    filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const rowsToDownload =
    filteredData &&
    filteredData.slice(filterData);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [popoverAnchorMas, setPopoverAnchorMas] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

 

  const open = Boolean(popoverAnchorEl);
  const popoverId = open ? "Data-popover" : undefined;

  const handlePopoverMasOpen = (event, Data) => {
    setPopoverAnchorMas(event.currentTarget);
    setSelectedData(Data);
  };

  const handlePopoverMasClose = () => {
    setPopoverAnchorMas(null);
    setSelectedData(null);
  };

  const openMas = Boolean(popoverAnchorMas);
  const popoverMasId = open ? "Data-popover" : undefined;

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  
  const onFilterValueChange = useCallback((filterValue) => {
    const filteredData = filter(Data, filterValue);
    setFilterData(filteredData);

  }, []);

  const exportToExcel = () => {
    const columnsToExport = ExcelColumns ? ExcelColumns.map(column => column.field) : columns.map(column => column.field);
    const dataToExport = filterData.length > 0 ? filterData : rowsToDownload;

    const filteredData = dataToExport.map(item => {
      const newItem = {};
      columnsToExport.forEach(columnName => {
        newItem[columnName] = item[columnName];
      });
      return newItem;
    });

    if (filteredData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
    } else {
      console.log('No hay datos para exportar');
    }
  };

  return (
    <Container>
      <Card
        sx={{
          borderRadius: "12px",
          padding: "16px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label="Buscar dato"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
        />

        {descarga == 1 && (
          <Tooltip title="Descargar excel" arrow>
            <IconButton onClick={exportToExcel} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
        {handleAgregarData && (
        <Button
          variant="contained"
          style={buttonStyle}
          startIcon={<AddIcon />}
          onClick={() => handleAgregarData(selectedData)}
        >
          Agregar
        </Button>
        )}
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow style={{ background: '#DEDEDE' }}>
                {columns.map((header) => (
                  <TableCell key={header.field}>
                    <Typography style={{ fontSize: '11px', width: '100px', textAlign: 'center' }}>{header.label}</Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography style={cellStyleComisiones}>Acciones</Typography>
                </TableCell>
              </TableRow>
              {rowsToShow &&
                rowsToShow.map((data) => (
                  <TableRow key={data.id}>
                    {columns.map((header) => (
                      <TableCell style={cellStyleComisiones} key={header.field}>
                        {data[header.field]}
                      </TableCell>
                    ))}
                    <TableCell>
                      <IconButton
                        onClick={(event) => handlePopoverMasOpen(event, data)}
                      >
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                    {handleEditarData && (
                    <TableCell>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                               onClick={() => handleEditarData(data)}
                            >
                              
                              Editar
                            </Menu.Item>
                            <Menu.Item
                               onClick={() => handleEliminarData(data)}
                            >
                              
                              Eliminar
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <IconButton>
                          <MoreVertIcon />
                        </IconButton>
                      </Dropdown>
                    </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData ? filteredData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <CustomPopover
        id={popoverMasId}
        open={openMas}
        anchorEl={popoverAnchorMas}
        onClose={handlePopoverMasClose}
        selectedData={selectedData}
        Columns={popoverColumns}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message="URL copiada con éxito"
      />
    </Container>
  );
}