import { useState } from "react";
import {
  getSatisfactionSurveysApi,
  addSatisfactionSurveysApi,
  updateSatisfactionSurveysApi,
  deleteSatisfactionSurveysApi,
} from "../Api/SatisfactionSurveys";  
import { useAuth } from "./useAuth";

export function useSatisfactionSurveys() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    SatisfactionSurveys: null,
  });

  const { loading, error, SatisfactionSurveys } = state;
  const { auth } = useAuth();
  const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  };

  const getSatisfactionSurveys = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getSatisfactionSurveysApi(auth.token);
      handleResponse(response);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        SatisfactionSurveys: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addSatisfactionSurveys = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addSatisfactionSurveysApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateSatisfactionSurveys = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateSatisfactionSurveysApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteSatisfactionSurveys = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteSatisfactionSurveysApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    SatisfactionSurveys,
    getSatisfactionSurveys,
    addSatisfactionSurveys,
    updateSatisfactionSurveys,
    deleteSatisfactionSurveys,
  };
}