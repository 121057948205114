import { Calendar, Popover, Space, Spin,  Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getDispatchDatosId } from "../../../../Api/ProcesosApi";
import { PopoverOrder } from "../../components/PopoverOrder";
import { TagCalendar } from "../../components/TagCalendar";
import { Oval } from "./styles";
import { Select } from "antd";

export const Main = () => {
  const [dataDispatch, setDataDispatch] = useState([]);
  const [dataDispatchHover, setDataDispatchHover] = useState({});
  const [selectedYear, setSelectedYear] = useState(moment().year()); // Establece el año actual como valor inicial
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedTechnician, setSelectedTechnician] = useState(""); // Estado para almacenar el técnico seleccionado
  const [selectedCustomer, setSelectedCustomer] = useState(""); // Estado para almacenar el cliente seleccionado
  const [loading, setLoading] = useState(true); // Estado para controlar el loader

  const fetchData = async (year) => {
    setLoading(true); // Mostrar el loader antes de la petición
    try {
      await getDispatchDatosId(year).then((r) => {
        setDataDispatch(r.data.value);
        setLoading(false); // Ocultar el loader después de la petición
      });
    } catch (error) {
      console.log(error);
      setLoading(false); // Ocultar el loader en caso de error
    }
  };

  useEffect(() => {
    fetchData(selectedYear);
  }, [selectedYear]); // La función fetchData se llamará cada vez que selectedYear cambie

  const handlePanelChange = (value) => {
    setSelectedYear(value.year());
  };

  const fetchPopoverData = async (year, id) => {
    setLoading(true); // Mostrar el loader antes de la petición
    try {
      const response = await getDispatchDatosId(year, id);
      setLoading(false); // Ocultar el loader después de la petición
      setDataDispatchHover(response.data.value[0]);
    } catch (error) {
      setLoading(false); // Ocultar el loader después de la petición
      console.log(error);
    }
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value === "all" ? null : value); // Si se selecciona "all", establece el estado como null, de lo contrario, establece el estado según el valor seleccionado
  };

  const handleTechnicianChange = (value) => {
    setSelectedTechnician(value); // Actualiza el estado con el técnico seleccionado
  };

  const handleCustomerChange = (value) => {
    setSelectedCustomer(value); // Actualiza el estado con el cliente seleccionado
  };

  const filterServicesByStatusAndTechnicianAndCustomer = (
    services,
    status,
    technician,
    customer
  ) => {
    return services.filter(
      (service) =>
        (status === null || service.Status === status) && // Si status es null, no se filtra por estado
        (technician === "" || service.TechnicianName === technician) &&
        (customer === "" || service.CustomerName === customer)
    );
  };

  const dateCellRender = (value) => {
    const formattedDate = value.format("YYYY-MM-DD");
    const servicesForDateDispatch = dataDispatch?.filter((service) =>
      moment(service.CreationDate).isSame(formattedDate, "day")
    );

    if (servicesForDateDispatch.length > 0) {
      // Filtra los servicios según las opciones seleccionadas en los Select
      let filteredServices = servicesForDateDispatch;

      if (selectedStatus !== null) {
        filteredServices = filteredServices.filter(
          (service) => service.Status === selectedStatus
        );
      }

      if (selectedTechnician !== "") {
        filteredServices = filteredServices.filter(
          (service) => service.TechnicianName === selectedTechnician
        );
      }

      if (selectedCustomer !== "") {
        filteredServices = filteredServices.filter(
          (service) => service.CustomerName === selectedCustomer
        );
      }

      // Si no se ha seleccionado un estado, técnico o cliente específico,
      // mostramos todos los servicios para la fecha seleccionada
      if (
        selectedStatus === null &&
        selectedTechnician === "" &&
        selectedCustomer === ""
      ) {
        filteredServices = servicesForDateDispatch;
      }

      const firstServiceDispatch = filteredServices[0];

      const CustomerName = firstServiceDispatch?.CustomerName;
      const TechnicianName = firstServiceDispatch?.TechnicianName;
      const Status = firstServiceDispatch?.Status;
      const getStatusColor = (status) => {
        switch (status) {
          case -1:
            return "black";
          case -2:
            return "gray";
          case -3:
            return "red-inverse";
          default:
            return "red-inverse";
        }
      };

      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {firstServiceDispatch && (
              <TagCalendar
                onMouseEnter={() =>
                  fetchPopoverData(
                    selectedYear,
                    firstServiceDispatch?.ServiceCallID
                  )
                }
                color={getStatusColor(Status)}
                nOrden={firstServiceDispatch?.ServiceCallID ?? ""}
                dataPopover={dataDispatchHover}
                type={CustomerName}
                hour={TechnicianName}
                loading={loading} // Pasa el estado loading como prop al componente TagCalendar
              />
            )}

            {servicesForDateDispatch.length > 1 && (
              <Popover
                placement="topRight"
                content={
                  <PopoverOrder
                    dataDispatch={servicesForDateDispatch}
                    selectedYear={selectedYear}
                  />
                }
                trigger="click"
                style={{ zIndex: 1000 }} // Puedes ajustar el valor del zIndex según sea necesario
              >
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Oval>
                    <p>
                      {servicesForDateDispatch.length > 1
                        ? servicesForDateDispatch.length - 1
                        : servicesForDateDispatch.length}
                      +
                    </p>
                  </Oval>
                </div>
              </Popover>
            )}
          </div>
        </>
      );
    }

    return null;
  };

  // Obtener valores únicos de los nombres de técnicos y clientes
  const uniqueTechnicians = [
    ...new Set(dataDispatch.map((service) => service.TechnicianName)),
  ];
  const uniqueCustomers = [
    ...new Set(dataDispatch.map((service) => service.CustomerName)),
  ];

  return (
    <>

<Space wrap align="start">
  {/* Filtro por Status */}
  <div>
    <Typography.Title level={5}>Filtro por Status</Typography.Title>
    <Select
      value={selectedStatus}
      onChange={handleStatusChange}
      style={{
        width: 200,
      }}
    >
      {/* Opción "Mostrar Todos" con el valor "all" */}
      <Select.Option value="all">Mostrar Todos</Select.Option>
      {/* Opciones de Status */}
      <Select.Option value={-1}>Cerrado</Select.Option>
      <Select.Option value={-2}>Pendiente</Select.Option>
      <Select.Option value={-3}>Abierto</Select.Option>
    </Select>
  </div>

  {/* Filtro por Técnico */}
  <div>
    <Typography.Title level={5}>Filtro por Técnico</Typography.Title>
    <Select
      style={{
        width: 200,
      }}
      value={selectedTechnician}
      onChange={handleTechnicianChange}
      placeholder="Selecciona un técnico"
      // allowClear
    >
      {/* Opción vacía para Técnicos */}
      <Select.Option key="" value="">
        {/* Agrega un espacio para mostrar la opción vacía correctamente */}
      </Select.Option>
      {/* Opciones de Técnicos */}
      {uniqueTechnicians.map((technician) => (
        <Select.Option key={technician} value={technician}>
          {technician}
        </Select.Option>
      ))}
    </Select>
  </div>

  {/* Filtro por Cliente */}
  <div>
    <Typography.Title level={5}>Filtro por Cliente</Typography.Title>
    <Select
      style={{
        width: 200,
      }}
      value={selectedCustomer}
      onChange={handleCustomerChange}
      placeholder="Selecciona un cliente"
      // allowClear
    >
      {/* Opción vacía para Clientes */}
      <Select.Option key="" value="">
        {/* Agrega un espacio para mostrar la opción vacía correctamente */}
      </Select.Option>
      {/* Opciones de Clientes */}
      {uniqueCustomers.map((customer) => (
        <Select.Option key={customer} value={customer}>
          {customer}
        </Select.Option>
      ))}
    </Select>
  </div>
</Space>


      <Calendar
        dateCellRender={dateCellRender}
        locale={{
          lang: {
            locale: "es",
            month: "Mes",
            year: "Año",
            shortWeekDays: ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"],
            shortMonths: [
              "ENERO",
              "FEBRERO",
              "MARZO",
              "ABRIL",
              "MAYO",
              "JUNIO",
              "JULIO",
              "AGOSTO",
              "SEPTIEMBRE",
              "OCTUBRE",
              "NOVIEMBRE",
              "DICIEMBRE",
            ],
          },
        }}
        onPanelChange={handlePanelChange}
      />
    </>
  );
};
