import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";


  
// Función para obtener los datos de un Excel
export async function getExcelApi(token) {
    const url = `${BASE_API}/cargue/detalle`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo Excel
  export async function addExcelApi(data, token) {
    const url = `${BASE_API}/cargue/detalle/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos de un Excel existente
  export async function updateExcelApi(id, data, token) {
    const url = `${BASE_API}/cargue/base/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar un Excel
  export async function deleteExcelApi(id, token) {
    const url = `${BASE_API}/cargue/archivo/${id}/`;
    return makeRequest(url, "DELETE", token);
  }
  