import { useState } from "react";
import { 
    getFormularioSigaApi,
    getFormularioSigaApibyuser,
    getFormularioSigaApibyEmail,
    addFormularioSigApi,
    SendEmailDifundidosApi,
    SendEmailSupervisorApi,
    updateFormularioSigApi,
    updateStatusFormularioSigApi,
    updateStatusFormularioSigMeApi,
    deleteFormularioSigApi,
} from "../../../Api/administrativa/formularios-sig/formulario-sig";

import { useAuth } from "../../useAuth";

export function useFormularioSig() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    FormularioSig: null,
  });

  const { loading, error, FormularioSig } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getFormularioSig = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getFormularioSigaApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        FormularioSig: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getFormularioSigbyuser = async (Id_creador) => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getFormularioSigaApibyuser(Id_creador, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        FormularioSig: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getFormularioSigbyEmail= async (email_user) => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getFormularioSigaApibyEmail(email_user, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        FormularioSig: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addFormularioSig = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addFormularioSigApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const SendEmailDifundidos = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await SendEmailDifundidosApi(id, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  }

  const SendEmailSupervisor = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await SendEmailSupervisorApi(id, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  }

  const updateFormularioSig = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateFormularioSigApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateStatusSig = async (id, field, status, myId) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      
      const data = { 
        [field]: status, 
        ['Id_aprobador']: myId
      };
      const [result, response] = await updateStatusFormularioSigApi(id, data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateStatusSigMe = async (id, status, difundidoFormEmail) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateStatusFormularioSigMeApi(id, status, difundidoFormEmail, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteFormularioSig = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteFormularioSigApi(id, auth.token);

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  }

  return {
    loading,
    error,
    FormularioSig,
    getFormularioSig,
    getFormularioSigbyuser,
    getFormularioSigbyEmail,
    addFormularioSig,
    SendEmailDifundidos,
    SendEmailSupervisor,
    updateFormularioSig,
    updateStatusSig,
    updateStatusSigMe,
    deleteFormularioSig,
    cancelLoader,
  };
}