import { useState } from "react";
import { 
    getDifundidoaApi,
    addDifundidoaApi,
    updateDifundidoaApi,
    deleteDifundidoaApi,
    getDifundidoaApiID,
} from "../../../Api/administrativa/formularios-sig/quien-difunde";

import { useAuth } from "../../useAuth";

export function useQuienDifunde() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    QuienDifunde: null,
  });

  const { loading, error, QuienDifunde } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getQuienDifunde = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getDifundidoaApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        QuienDifunde: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getQuienDifundeID = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getDifundidoaApiID(id, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        QuienDifunde: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addQuienDifunde = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addDifundidoaApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateQuienDifunde = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateDifundidoaApi(id, data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteQuienDifunde = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteDifundidoaApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    QuienDifunde,
    getQuienDifunde,
    addQuienDifunde,
    updateQuienDifunde,
    deleteQuienDifunde,
    getQuienDifundeID,
    cancelLoader,
  };
}