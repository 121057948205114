import React, { useState, useCallback } from "react";

import {
    Card,
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Dropdown, Menu, message } from "antd";

import * as XLSX from "xlsx";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from "@mui/icons-material/MoreVert";


import CustomPopover from "../CustomPopover";
import FilePreviewModal from "../PreviewFiles/ModalPreview";
import { cellStyle } from "../styles";
import { useAuth } from "../../../hooks/useAuth";


export function TablaGeneral(props) {
    const {
        Data,
        columns,
        popoverColumns,
        ExcelColumns,
        descarga,
        aprovJefe,
        historial,
        handleSendAprobGH,
        handleSendAprobJefe,
    } = props;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterData, setFilterData] = useState([]);
    const { auth } = useAuth();

    const isGH = auth.me?.is_gh;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    
    const filteredData =
    Array.isArray(Data) &&
        Data.filter((data) =>
            columns.some((header) =>
                data[header.field]
                    ?.toString()
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
            );

    const sortedData = [...filteredData].sort((a, b) => {
        const stateOrder = { 0: 1, 1: 2, 2: 3 };
        return stateOrder[a.aprobadojefe] - stateOrder[b.aprobadojefe];
        });

    const sortedData2 = [...filteredData].sort((a, b) => {
        const stateOrder = { 0: 1, 1: 2, 2: 3 };
        return stateOrder[a.aprobadogh] - stateOrder[b.aprobadogh];
        });

    const dataToUse = 
    ( aprovJefe === 0 && historial === 1 ) ?  filteredData  : 
    (isGH === true && aprovJefe === 0 && historial === 0) ? sortedData2 : 
    (aprovJefe === 1 && historial === 0) ? sortedData : 
    ([])
            
    const rowsToShow =
    dataToUse &&
    dataToUse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const rowsToDownload =
        filteredData &&
        filteredData.slice(filterData);


    const getColorAndTextStyle = (estado) => {
        let style = {
            color: "white",
            width: "120px", // Ancho por defecto para todos los estados
        };
        
        switch (estado) {
            case 0:
            style.backgroundColor = "rgb(68, 68, 68)";
            style.text = "Pendiente";
            break;
            case 1:
            style.backgroundColor = "#60C000";
            style.text = "Aprobado";
            break;
            case 2:
            style.backgroundColor = "rgb(207, 22, 22)";
            style.text = "Rechazado";
            break;
        }
        
        
        return style;
        };

    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [popoverAnchorMas, setPopoverAnchorMas] = useState(null);
    const [selectedData, setSelectedData] = useState(null);

    const open = Boolean(popoverAnchorEl);

    const handlePopoverMasOpen = (event, Data) => {
        setPopoverAnchorMas(event.currentTarget);
        setSelectedData(Data);
    };

    const handlePopoverMasClose = () => {
        setPopoverAnchorMas(null);
        setSelectedData(null);
    };

    const openMas = Boolean(popoverAnchorMas);
    const popoverMasId = open ? "Data-popover" : undefined;

    const exportToExcel = () => {
        const columnsToExport = ExcelColumns ? ExcelColumns.map(column => column.field) : columns.map(column => column.field);
        const dataToExport = filterData.length > 0 ? filterData : rowsToDownload;

        const filteredData = dataToExport.map(item => {
            const newItem = {};
            columnsToExport.forEach(columnName => {
                newItem[columnName] = item[columnName];
            });
            return newItem;
        });

        if (filteredData.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "data.xlsx");
        } else {
            console.log('No hay datos para exportar');
        }
    };

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');

    const handlePreviewOpen = (url) => {
        setPreviewUrl(url);
        setPreviewOpen(true);
    };

    const handlePreviewClose = () => {
        setPreviewOpen(false);
        setPreviewUrl('');
    };

    return (
        <Container>
            <Card
                sx={{
                    borderRadius: "12px",
                    padding: "16px",
                    marginBottom: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <TextField
                    label="Buscar dato"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                {descarga == 1 && (
                    <Tooltip title="Descargar excel" arrow>
                        <IconButton onClick={exportToExcel} style={{ marginRight: '8px', fontSize: '1.2rem' }}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                )}

            </Card>
            <Card>
            <TableContainer component={Paper}>
                <Table>
                <TableBody>
                    <TableRow style={{ background: '#DEDEDE' }}>
                    {columns.map((header) => (
                        <TableCell key={header.field} style={{ textAlign: 'center' }}>
                        <Typography style={{ fontSize: '11px' }}>{header.label}</Typography>
                        </TableCell>
                    ))}
                    <TableCell>
                        <Typography style={cellStyle} >Acciones</Typography>
                    </TableCell>
                    {isGH === true ? historial === 0 &&(
                        <TableCell>
                            <Typography style={cellStyle}>Aprobaciones</Typography>
                        </TableCell>
                    ) : aprovJefe === 1 ? historial === 0 && (
                        <TableCell>
                            <Typography style={cellStyle}>Aprobaciones</Typography>
                        </TableCell>
                    ) : ([])}
                    </TableRow>
                    {rowsToShow && rowsToShow.map((data) => (
                    <TableRow key={data.id}>
                        {columns.map((header) => (
                            <>
                                {/* Primer TableCell para otros campos */}
                                <TableCell style={ cellStyle } key={header.field}>
                                    {header.field === 'archivo' ? (
                                        <Tooltip title="Previsualizar">
                                            <IconButton onClick={() => handlePreviewOpen(data[header.field])}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : header.field === 'aprobadojefe' ? (
                                        <span
                                            style={{
                                                display: "block",
                                                textAlign: "center",
                                                fontSize: "11px",
                                                height: "100%",
                                                padding: 20,
                                                cellStyle,
                                                borderRadius: "10px",
                                                ...{
                                                    backgroundColor: getColorAndTextStyle(data[header.field]).backgroundColor,
                                                    color: getColorAndTextStyle(data[header.field]).color,
                                                    width: "100%"
                                                },
                                            }}
                                            >
                                            {getColorAndTextStyle(data[header.field]).text}
                                        </span>
                                    ) : header.field === 'aprobadogh' ? (
                                    <span
                                        style={{
                                            display: "block",
                                            textAlign: "center",
                                            fontSize: "11px",
                                            height: "100%",
                                            padding: 20,
                                            cellStyle,
                                            borderRadius: "10px",
                                            ...{
                                                backgroundColor: getColorAndTextStyle(data[header.field]).backgroundColor,
                                                color: getColorAndTextStyle(data[header.field]).color,
                                                width: "100%"
                                            },
                                        }}
                                        >
                                        {getColorAndTextStyle(data[header.field]).text}
                                    </span>
                                    ) : (
                                        data[header.field]
                                    )}
                                </TableCell>
                            </>
                        ))}
                        <TableCell style={cellStyle}>
                            <IconButton
                                onClick={(event) => handlePopoverMasOpen(event, data)}
                            >
                                <AddIcon />
                            </IconButton>
                        </TableCell>
                        {aprovJefe === 1 && historial === 0 ? (
                        <TableCell style={cellStyle}>
                            <Dropdown
                            
                            overlay={
                                <Menu key="">
                                    <Menu.Item onClick={() => handleSendAprobJefe(data)}>Cambiar estado Jefe</Menu.Item>
                                </Menu>
                            }
                            trigger={["click"]}
                            >
                            <IconButton>
                                <MoreVertIcon />
                            </IconButton>
                            </Dropdown>
                        </TableCell>  
                        ) : isGH === true  && historial === 0 ? (
                        <TableCell style={cellStyle}>
                            <Dropdown
                            overlay={
                                <Menu key="">
                                    <Menu.Item onClick={() => handleSendAprobGH(data)}>Cambiar estado GH</Menu.Item>
                                </Menu>
                            }
                            trigger={["click"]}
                            >
                            <IconButton>
                                <MoreVertIcon />
                            </IconButton>
                            </Dropdown>
                        </TableCell> 
                        ) : ([])}
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>

                <FilePreviewModal
                    open={previewOpen}
                    handleClose={handlePreviewClose}
                    url={previewUrl}
                />

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData ? filteredData.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>

            <CustomPopover
                id={popoverMasId}
                open={openMas}
                anchorEl={popoverAnchorMas}
                onClose={handlePopoverMasClose}
                selectedData={selectedData}
                Columns={popoverColumns}
            />
        </Container>
    );
}