import styled from "styled-components";

export const Oval = styled.div`
  height: 20px;
  width: 28px;
  border-radius: 10px;
  background: #1e063e;
  text-align: end;
  margin: 5px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #fff;
    font-weight: 700;
    font-size: 9px;
  }
`;
