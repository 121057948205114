import { baseApi } from "./basesApi";



export const getDispatchDatosId = (year, id) => {
  // console.log("Año recibido:", year);
  // console.log("ID recibido:", id);

  return baseApi
    .get(
      `/sap/sap/service_calls/${year}/${id !== undefined ? `?ServiceCallID=${id}` : ""}`
    )
    .then((r) => r);
};
