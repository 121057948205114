// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import Logo from '../../assets/lineasdenegocio.png';
import AuthWrapper1 from '../../components/authentication/AuthWrapper1';
import AuthCardWrapper from '../../components/authentication/AuthCardWrapper';
import { LoginForm } from '../../components/login/form';
import { Layout } from 'antd';
const { Header, Content, Footer } = Layout;

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AuthWrapper1>
            <Footer
              style={{
              textAlign: 'center',
             }}
            >
            <span style={{ color: 'rgb(204, 48, 43)'}}>SYZ </span>COLOMBIA SAS
            </Footer>
            <Grid container direction="column" justifyContent="flex-end">
                <Grid item xs={10}>
                    <Grid container justifyContent="center" alignItems="top" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 1 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <img src={Logo} alt="syz" width={150} height={150}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color='rgb(204, 48, 43)'
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h4'}
                                                        textAlign={matchDownSM ? 'center' : 'center'}
                                                        sx={{
                                                            fontFamily: 'Roboto',
                                                            paddingTop: '0',
                                                            marginTop: '0',
                                                        }}
                                                    >
                                                        Aplicaciones internas
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <LoginForm />
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default Login;
