import { useLeads } from '../../hooks/useLeads'
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from '../../hooks/useAuth';
import { TablaGeneral } from "../../components/comons/TablaGeneral";
import { Modal } from 'antd';
import { LeadsForm } from '../../components/Marketing/LeadsForm';

const headers = [
  { label: "ID", field: "id_leads" },
  { label: "Año", field: "anio" },
  { label: "Mes", field: "mes" },
  { label: "Empleado de Ventas", field: "empleado_ventas_lead" },
  { label: "Origen de Contacto", field: "origen_contacto" },
  { label: "Fecha de Contacto", field: "fecha_contacto" },
  { label: "País", field: "pais" },
  
];


const popoverColumns = [
  { label: "NIT", field: "nit" },
  { label: "Razón Social", field: "razon_social" },
  { label: "Contacto", field: "contacto" },
  { label: "Cargo", field: "cargo" },
  { label: "Email", field: "email" },
  { label: "Número Telefónico", field: "numero_telefonico" },
  { label: "Dirección", field: "direccion" },
  { label: "Descripción de la solicitud", field: "descripcion" },
  { label: "Sector Industrial", field: "sector_industrial" },
  { label: "Grupo", field: "grupo" },
  { label: "Línea", field: "linea" },
  { label: "¿Es Lead o Cliente Existente?", field: "lead_ocliente_existente" },
  { label: "Tipo de Lead", field: "tipo_lead" },
  { label: "Motivo de Descalificación", field: "motivo_descalificacion" },
/*{ label: "Asignado a", field: "asignado_a" },
  { label: "Fecha de Asignación Gerente de Cuenta", field: "fecha_asignacion_gerente_cuenta" },
  { label: "OPV Creado", field: "opv_creado" },
  { label: "Motivo de Pérdida", field: "motivo_perdida" }, */
];

export  function LeadsPage() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [contentModal, setContentModal] = useState(null);
  const { auth } = useAuth();
  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const { loading, Leads, getLeads, deleteLeads } = useLeads();

  useEffect(() => {
    getLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  const handleAdd = useCallback(() => {

    setContentModal(
      <LeadsForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
     
      setContentModal(
        <LeadsForm
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Leads={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    (data) => {
      deleteLeads(data.id_leads);
      onRefetch();
    },
    [deleteLeads, onRefetch]
  );

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
        {(auth.me?.marketing_full_modify) && (
        <TablaGeneral
          Data={Leads}
          columns={headers}
          popoverColumns={popoverColumns}
          handleAgregarData={handleAdd}
          handleEditarData={handleUpdate}
          handleEliminarData={handleDelete}
          filterFields={["nombre", "apellido"]}
        />
        )}

        {(auth.me?.marketing_only_view && !auth.me?.marketing_full_modify) && (
        <TablaGeneral
          Data={Leads}
          columns={headers}
          popoverColumns={popoverColumns}
          filterFields={["nombre", "apellido"]}
        />
        )}
        </>
        )}
        <Modal
        open={showModal}
        onCancel={openCloseModal}
        footer={null}
        width="70%"
        title="Leads"
        destroyOnClose={true} // Esto destruirá el contenido del modal cuando se cierre
      >
        {contentModal}
      </Modal>
      
    </>
  )
}