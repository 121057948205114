import * as Yup from "yup";

export const datosActividadSchema = Yup.object().shape({
    tipoformulario: Yup.string().required("tipo formulario es requerido"),
    fecha_elaboracion: Yup.string().required("fecha elaboración es requerido"),
    fecha_inicio: Yup.string().required("fecha inicio es requerido"),
    fecha_final: Yup.string().required("fecha final es requerido"),
    num_orden: Yup.string().required("Número de orden es requerido"),
    empresa: Yup.string().required("Empresa es requerido"),
    lugartrabajo: Yup.string().required("Lugar de trabajo es requerido"),
    direccion: Yup.string().required("Dirección es requerido"),
    num_trabajadores: Yup.string().required("Numero de trabajadores es requerido"),
    herramienta: Yup.string().required("Herramienta es requerido"),
    trabajo: Yup.string().required("Trabajo es requerido"),
    altura: Yup.string().required("Altura es requerido"),
    Fechaterminacion: Yup.string().required("Fecha terminación es requerido"),
});

export const riesgoPotencialSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    expsustanciasquimicas: Yup.string().required("El campo es requerido"),
    caidasuperior15: Yup.string().required("El campo es requerido"),
    riesgobiologico: Yup.string().required("El campo es requerido"),
    maquinapesada: Yup.string().required("El campo es requerido"),
    espaciosconfinados: Yup.string().required("El campo es requerido"),
    superficiecaliente: Yup.string().required("El campo es requerido"),
    riesgoelectrico: Yup.string().required("El campo es requerido"),
    ruido: Yup.string().required("El campo es requerido"),
    manipulacioncarga: Yup.string().required("El campo es requerido"),
    afmosferapeligrosa: Yup.string().required("El campo es requerido"),
    equipomovimiento: Yup.string().required("El campo es requerido"),
    caidaobjetos: Yup.string().required("El campo es requerido"),
    herramientamanual: Yup.string().required("El campo es requerido"),
    izajecarga: Yup.string().required("El campo es requerido"),
    derrames: Yup.string().required("El campo es requerido"),
    precionesanormales: Yup.string().required("El campo es requerido"),
    radiaciones: Yup.string().required("El campo es requerido"),
    esquirlas: Yup.string().required("El campo es requerido"),
    incendio: Yup.string().required("El campo es requerido"),
    otrosriesgos: Yup.string().required("El campo es requerido"),
});

export const controlRiesgosSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    proteccioncabeza: Yup.string().required("El campo es requerido"),
    proteccionfacial: Yup.string().required("El campo es requerido"),
    proteccionvisual: Yup.string().required("El campo es requerido"),
    proteccionrespiratoria: Yup.string().required("El campo es requerido"),
    proteccionauditiva: Yup.string().required("El campo es requerido"),
    proteccionmanual: Yup.string().required("El campo es requerido"),
    proteccionpies: Yup.string().required("El campo es requerido"),
    ropaproteccion: Yup.string().required("El campo es requerido"),
    sistemabloeti: Yup.string().required("El campo es requerido"),
    otraproteccion: Yup.string().required("El campo es requerido"),
    //EPP contra caidas
    arnescuerpo: Yup.string().required("El campo es requerido"),
    esligaimpacto: Yup.string().required("El campo es requerido"),
    eslingaposicionamiento: Yup.string().required("El campo es requerido"),
    eslingay: Yup.string().required("El campo es requerido"),
    esliga60: Yup.string().required("El campo es requerido"),
    tieoff: Yup.string().required("El campo es requerido"),
    lvvertical: Yup.string().required("El campo es requerido"),
    lvhorizontal: Yup.string().required("El campo es requerido"),
    puntoanclaje: Yup.string().required("El campo es requerido"),
    //Otros controles
    proctrabajo: Yup.string().required("El campo es requerido"),
    duchas: Yup.string().required("El campo es requerido"),
    trabajoalturas: Yup.string().required("El campo es requerido"),
    bloqueoetiquetado: Yup.string().required("El campo es requerido"),
    trabajofrio: Yup.string().required("El campo es requerido"),
    controlincedios: Yup.string().required("El campo es requerido"),
    senalizacion: Yup.string().required("El campo es requerido"),
    medevac: Yup.string().required("El campo es requerido"),
    proteccioncaidas: Yup.string().required("El campo es requerido"),
    explosividad: Yup.string().required("El campo es requerido"),
    trabajocaliente: Yup.string().required("El campo es requerido"),
    controlderrames: Yup.string().required("El campo es requerido"),
    hojaseguridad: Yup.string().required("El campo es requerido"),
    trabajoaltura: Yup.string().required("El campo es requerido"),
    primerosauxilios: Yup.string().required("El campo es requerido"),
    barrerafisica: Yup.string().required("El campo es requerido"),
    espacioconfinado: Yup.string().required("El campo es requerido"),
    vigiahse: Yup.string().required("El campo es requerido"),
    bypass: Yup.string().required("El campo es requerido"),
    trabajoelectrico: Yup.string().required("El campo es requerido"),
    otroscontroles: Yup.string().required("El campo es requerido"),
    //Equipo de seguridad
    casconodielectrico: Yup.string().required("El campo es requerido"),
    cascodielectrico: Yup.string().required("El campo es requerido"),
    botasnodielectricas: Yup.string().required("El campo es requerido"),
    botasdielectricas: Yup.string().required("El campo es requerido"),
    gafaseguridad: Yup.string().required("El campo es requerido"),
    caretasoldador: Yup.string().required("El campo es requerido"),
    trajeimpermeable: Yup.string().required("El campo es requerido"),
    chalecoreflectivo: Yup.string().required("El campo es requerido"),
    petomangas: Yup.string().required("El campo es requerido"),
    protauditivainsercion: Yup.string().required("El campo es requerido"),
    protauditivacopa: Yup.string().required("El campo es requerido"),
    overol: Yup.string().required("El campo es requerido"),
    guantescuero: Yup.string().required("El campo es requerido"),
    guanteshilaza: Yup.string().required("El campo es requerido"),
    guantesnitrilo: Yup.string().required("El campo es requerido"),
    guanteslatex: Yup.string().required("El campo es requerido"),
    guantespoliuretano: Yup.string().required("El campo es requerido"),
    tapabocas: Yup.string().required("El campo es requerido"),
    detectorlel: Yup.string().required("El campo es requerido"),
    lineavida: Yup.string().required("El campo es requerido"),
    eslingas: Yup.string().required("El campo es requerido"),
    arnescuerpo: Yup.string().required("El campo es requerido"),
    respiradorgases: Yup.string().required("El campo es requerido"),
    respiradorhumos: Yup.string().required("El campo es requerido"),
    respiradorpolvos: Yup.string().required("El campo es requerido"),
    respiradorlibre: Yup.string().required("El campo es requerido"),
    otrosseguridad: Yup.string().required("El campo es requerido"),
});

export const analisisTrabajoSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    tarea: Yup.string().required("El campo es requerido"),
    peligros: Yup.string().required("El campo es requerido"),
    riesgos: Yup.string().required("El campo es requerido"),
    controlesrequeridos: Yup.string().required("El campo es requerido"),
    responsables: Yup.string().required("El campo es requerido"),
});

export const condicionesSaludSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    //Condiciones salud
    condicionesoptimas: Yup.string().required("El campo es requerido"),
    medicamentossueno: Yup.string().required("El campo es requerido"),
    drogasalucinogenas: Yup.string().required("El campo es requerido"),
    epilepsia: Yup.string().required("El campo es requerido"),
    fobiaalturas: Yup.string().required("El campo es requerido"),
    capacitacion: Yup.string().required("El campo es requerido"),
    cetificadoaltura: Yup.string().required("El campo es requerido"),
    ssvigente: Yup.string().required("El campo es requerido"),
    //Peligros
    deficienciaoxigeno: Yup.string().required("El campo es requerido"),
    gasesinflamables: Yup.string().required("El campo es requerido"),
    excesooxigeno: Yup.string().required("El campo es requerido"),
    particulapolvo: Yup.string().required("El campo es requerido"),
    vaporestoxicos: Yup.string().required("El campo es requerido"),
    peligrosmecanicos: Yup.string().required("El campo es requerido"),
    choqueelectrico: Yup.string().required("El campo es requerido"),
    peligropiel: Yup.string().required("El campo es requerido"),
    atrapamiento: Yup.string().required("El campo es requerido"),
    otrospeligros: Yup.string().required("El campo es requerido"),
});

export const sistemaAccesoPrevencionSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    //sistema de acceso y prevencion
    escaleratijera: Yup.string().required("El campo es requerido"),
    escaleraextension: Yup.string().required("El campo es requerido"),
    escaleraplataforma: Yup.string().required("El campo es requerido"),
    andamiocarga: Yup.string().required("El campo es requerido"),
    andamiopozo: Yup.string().required("El campo es requerido"),
    elevadores: Yup.string().required("El campo es requerido"),
    barandas: Yup.string().required("El campo es requerido"),
    conos: Yup.string().required("El campo es requerido"),
    cadenas: Yup.string().required("El campo es requerido"),
    cerramientos: Yup.string().required("El campo es requerido"),
    cinta: Yup.string().required("El campo es requerido"),
    avisos: Yup.string().required("El campo es requerido"),
    //sistemas de prevencion
    rotacionpersonal: Yup.string().required("El campo es requerido"),
    senalizacion: Yup.string().required("El campo es requerido"),
    delimitacion: Yup.string().required("El campo es requerido"),
    procedimientoespacios: Yup.string().required("El campo es requerido"),
    controlacceso: Yup.string().required("El campo es requerido"),
    bloqueo: Yup.string().required("El campo es requerido"),
    analisispeligros: Yup.string().required("El campo es requerido"),
    //Proteccion afmosfera
    deficienciaoxigeno: Yup.string().required("El campo es requerido"),
    enriquesidaoxigeno: Yup.string().required("El campo es requerido"),
    gasescombustibles: Yup.string().required("El campo es requerido"),
    gasestoxicos: Yup.string().required("El campo es requerido"),
    inflamabilidad: Yup.string().required("El campo es requerido"),
    h2s: Yup.string().required("El campo es requerido"),
    calor: Yup.string().required("El campo es requerido"),
    mediocomuinicacion: Yup.string().required("El campo es requerido"),
    procedimientoevacua: Yup.string().required("El campo es requerido"),
});

export const inspeccionAlturaSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    //Tejidos y correas
    fibrasexternas: Yup.string().required("El campo es requerido"),
    cortes: Yup.string().required("El campo es requerido"),
    grietas: Yup.string().required("El campo es requerido"),
    estiramiento: Yup.string().required("El campo es requerido"),
    deteriorotejidoco: Yup.string().required("El campo es requerido"),
    corrosion: Yup.string().required("El campo es requerido"),
    quemaduras: Yup.string().required("El campo es requerido"),
    areasduras: Yup.string().required("El campo es requerido"),
    decoloracion: Yup.string().required("El campo es requerido"),
    consultaproveedor: Yup.string().required("El campo es requerido"),
    moho: Yup.string().required("El campo es requerido"),
    otrostejidos: Yup.string().required("El campo es requerido"),
    //Costuras
    cortaduras: Yup.string().required("El campo es requerido"),
    deshilachado: Yup.string().required("El campo es requerido"),
    hilos: Yup.string().required("El campo es requerido"),
    quemadurascosturas: Yup.string().required("El campo es requerido"),
    exposicionquimicos: Yup.string().required("El campo es requerido"),
    otroscosturas: Yup.string().required("El campo es requerido"),
    //Argollas
    deformaciones: Yup.string().required("El campo es requerido"),
    picaduras: Yup.string().required("El campo es requerido"),
    deterioroargollas: Yup.string().required("El campo es requerido"),
    defecto: Yup.string().required("El campo es requerido"),
    corrosionargollas: Yup.string().required("El campo es requerido"),
});

export const verificacionDocumentoSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    equipotienecompetencias: Yup.string().required("El campo es requerido"),
    tieneclaroriesgos: Yup.string().required("El campo es requerido"),
    medidasdecontrol: Yup.string().required("El campo es requerido"),
    funcionesyresponsabilidades: Yup.string().required("El campo es requerido"),
    eppadecuados: Yup.string().required("El campo es requerido"),
    equpoyherramienta: Yup.string().required("El campo es requerido"),
    todosequiposseguridad: Yup.string().required("El campo es requerido"),
});

export const quienElaboraSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    nombreelabora: Yup.string().required("El campo es requerido"),
    cedulaelabora: Yup.string().required("El campo es requerido"),
    cargoelabora: Yup.string().required("El campo es requerido"),
    emailelabora: Yup.string().email("El Email debe ser válido").required("El campo es requerido"),
    fecha_firmaelabora: Yup.date().required("El campo es requerido"),
});

export const quienDifundeSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    nombredifundido: Yup.string().required("El campo es requerido"),
    ceduladifundido: Yup.string().required("El campo es requerido"),
    cargodifundido: Yup.string().required("El campo es requerido"),
    emaildifundido: Yup.string().email("El Email debe ser válido").required("El campo es requerido"),
    fecha_difundido: Yup.date().required("El campo es requerido"),
});

export const SupervisorCoordinadorSchema = Yup.object().shape({
    Id_formulario: Yup.number().required("El campo es requerido"),
    nombresupervisor: Yup.string().required("El campo es requerido"),
    cedulasupervisor: Yup.string().required("El campo es requerido"),
    cargosupervisor: Yup.string().required("El campo es requerido"),
    emailsupervisor: Yup.string().email("El Email debe ser válido").required("El campo es requerido"),
    fecha_supervisor: Yup.date().required("El campo es requerido"),
});