// Función auxiliar para realizar solicitudes genéricas
export async function makeRequest(url, method, token, data) {
  try {
    const params = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    return [result, response];
  } catch (error) {
    throw error;
  }
}
export async function makeRequestFormData(url, method, token, data) {
  
  try {
    const params = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    };

    const response = await fetch(url, params);
    const result = await response.json();

    return [result, response];
  } catch (error) {
    throw error;
  }
}

export async function makeRequestFileData(url, method, token, data) {
  try {
    const formData = new FormData();

    // Agregar todos los campos de datos al formData, incluyendo el archivo
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const params = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();

    return [result, response];
  } catch (error) {
    throw error;
  }
}