// material-ui
import { styled } from '@mui/material/styles';
import Background from '../../assets/background.png';

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper1 = styled('div')(({ theme }) => ({
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    minHeight: '30vh'
}));

export default AuthWrapper1;
