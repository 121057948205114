import { Button, Col, Form, Input, Row, message, Select, DatePicker } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useCompetence } from "../../hooks/useCompetence";
import { formStyle } from "../comons/styles";
import moment from "moment";

export function CompetenceForm(props) {
  const { onClose, onRefetch, Competence } = props;
  const { addCompetence, updateCompetence } = useCompetence();
  const { Option } = Select;

  const formik = useFormik({
    initialValues: initialValues(Competence), // Utiliza la función getInitialValues
    validationSchema: Yup.object(Competence ? updateSchema() : newSchema()),
    onSubmit: async (formValues) => {
      try {
        if (Competence)
          await updateCompetence(Competence.id_competidores, formValues);
        else await addCompetence(formValues);

        onRefetch();
        onClose();
        message.success("Operación exitosa");
      } catch (error) {
        if (error?.message) {
          message.error(error.message);
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <Form layout="vertical" onFinish={formik.handleSubmit} style={formStyle}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Grupo"
            validateStatus={
              formik.errors.grupo && formik.touched.grupo ? "error" : ""
            }
            help={
              formik.touched.grupo && formik.errors.grupo
                ? formik.errors.grupo
                : ""
            }
          >
            <Input
              name="grupo"
              value={formik.values.grupo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Año"
            validateStatus={
              formik.errors.anio && formik.touched.anio ? "error" : ""
            }
            help={
              formik.touched.anio && formik.errors.anio
                ? formik.errors.anio
                : ""
            }
          >
            <DatePicker
              style={{ width: '100%' }}
              picker="year"
              name="anio"
              value={formik.values.anio ? moment(formik.values.anio) : null}
              onChange={(date, dateString) => formik.setFieldValue('anio', dateString)}
              onBlur={() => formik.setFieldTouched('anio')}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="NIT"
            validateStatus={
              formik.errors.nit && formik.touched.nit ? "error" : ""
            }
            help={
              formik.touched.nit && formik.errors.nit ? formik.errors.nit : ""
            }
          >
            <Input
              name="nit"
              value={formik.values.nit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Competidor"
            validateStatus={
              formik.errors.competidor && formik.touched.competidor
                ? "error"
                : ""
            }
            help={
              formik.touched.competidor && formik.errors.competidor
                ? formik.errors.competidor
                : ""
            }
          >
            <Input
              name="competidor"
              value={formik.values.competidor}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Sitio Web"
            validateStatus={
              formik.errors.sitio_web && formik.touched.sitio_web ? "error" : ""
            }
            help={
              formik.touched.sitio_web && formik.errors.sitio_web
                ? formik.errors.sitio_web
                : ""
            }
          >
            <Input
              name="sitio_web"
              value={formik.values.sitio_web}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Años en el mercado"
            validateStatus={
              formik.errors.web && formik.touched.anio_mercado
                ? "error"
                : ""
            }
            help={
              formik.touched.anio_mercado && formik.errors.anio_mercado
                ? formik.errors.anio_mercado
                : ""
            }
          >
            <Input
              name="anio_mercado"
              value={formik.values.anio_mercado}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Valor Fob"
            validateStatus={
              formik.errors.valor_fob && formik.touched.valor_fob ? "error" : ""
            }
            help={
              formik.touched.valor_fob && formik.errors.valor_fob
                ? formik.errors.valor_fob
                : ""
            }
          >
            <Input
              name="valor_fob"
              value={formik.values.valor_fob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Valor ventas"
            validateStatus={
              formik.errors.valor_ventas && formik.touched.valor_ventas
                ? "error"
                : ""
            }
            help={
              formik.touched.valor_ventas && formik.errors.valor_ventas
                ? formik.errors.valor_ventas
                : ""
            }
          >
            <Input
              name="valor_ventas"
              value={formik.values.valor_ventas}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Sectores"
            validateStatus={
              formik.errors.sectores && formik.touched.sectores ? "error" : ""
            }
            help={
              formik.touched.sectores && formik.errors.sectores
                ? formik.errors.sectores
                : ""
            }
          >
            <Input
              name="sectores"
              value={formik.values.sectores}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Sucursales ciudades"
            validateStatus={
              formik.errors.sucursales_ciudades &&
              formik.touched.sucursales_ciudades
                ? "error"
                : ""
            }
            help={
              formik.touched.sucursales_ciudades &&
              formik.errors.sucursales_ciudades
                ? formik.errors.sucursales_ciudades
                : ""
            }
          >
            <Input
              name="sucursales_ciudades"
              value={formik.values.sucursales_ciudades}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {Competence ? "Actualizar" : "Crear"}
        </Button>
      </Form.Item>
    </Form>
  );
}

// Función para inicializar los valores del formulario
function initialValues(data) {
  return {
    anio: data?.anio || null,
    nit: data?.nit || "",
    competidor: data?.competidor || "",
    grupo: data?.grupo || "",
    sitio_web: data?.sitio_web || "",
    anio_mercado: data?.anio_mercado || "",
    valor_fob: data?.valor_fob || "",
    valor_ventas: data?.valor_ventas || "",
    sectores: data?.sectores || "",
    sucursales_ciudades: data?.sucursales_ciudades || "",
  };
}

// Función para definir el esquema de validación del formulario
function newSchema() {
  return {
    anio: Yup.number().required("El campo de anio es requerido"),
    nit: Yup.number().required("El campo de nit es requerido"),
    competidor: Yup.string().required("El campo de competidor es requerido"),
    grupo: Yup.string().required("El campo de grupo es requerido"),
    sitio_web: Yup.string().required("El campo de sitio web es requerido"),
    anio_mercado: Yup.number().required("El campo de año de mercado es requerido"),
    valor_fob: Yup.number().required("El campo de valor FOB es requerido"),
    valor_ventas: Yup.number().required("El campo de valor ventas es requerido"),
    sectores: Yup.string().required("El campo de sectores es requerido"),
    sucursales_ciudades: Yup.string().required("El campo de sucursales ciudades es requerido"),
  };
}

function updateSchema() {
  return {
    anio: Yup.number().required("El campo de anio es requerido"),
    nit: Yup.number().required("El campo de nit es requerido"),
    competidor: Yup.string().required("El campo de competidor es requerido"),
    grupo: Yup.string().required("El campo de grupo es requerido"),
    sitio_web: Yup.string().required("El campo de sitio web es requerido"),
    anio_mercado: Yup.number().required("El campo de año de mercado es requerido"),
    valor_fob: Yup.number().required("El campo de valor FOB es requerido"),
    valor_ventas: Yup.number().required("El campo de valor ventas es requerido"),
    sectores: Yup.string().required("El campo de sectores es requerido"),
    sucursales_ciudades: Yup.string().required("El campo de sucursales ciudades es requerido"),
  };
}
