import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";

// Función para obtener los datos de un Comercial
export async function getConsultasApi(token, email) {
    const url = `${BASE_API}/syzia/api/consultas/${email}/`;
    return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo Comercial
export async function addConsultasApi(data, token, email, temperature) {
    const url = `${BASE_API}/syzia/api/SYZIA/${temperature}/${email}/`;
    return makeRequest(url, "POST", token, data);
}

// Función para agregar un nuevo Comercial
export async function updateConsultasApi(id, data, token ) {
    const url = `${BASE_API}/syzia/api/SYZIA/${id}/`;
    return makeRequest(url, "PATCH", token, data);
}