import { Tabs } from "antd";
import React, { useState } from "react";
import { SolicitudPermisosTable, SolicitudPermisosTableAprobaciones } from "../../../components/administrativa/solicitud_permisos/components/table";
import { SolicitudPermisosForm } from "../../../components/administrativa/solicitud_permisos/components/form";
import { useAuth } from "../../../hooks/useAuth";

export  function SolicitudPermisosPage() {

  const [activePane, setActivePane] = useState("1")
  const { auth } = useAuth();

  const isGH = auth.me?.is_gh;

  const handleTabChange = (key) => {
    setActivePane(key)
  };

  const items = [
    {
        key: '1',
        label: 'Solicitar permisos',
        children: <SolicitudPermisosForm activepane={handleTabChange} />
    },
    {
        key: '2',
        label: 'Histórico',
        children: <SolicitudPermisosTable />
    },
    {
        key: '3',
        label: 'Aprobaciones',
        children: <SolicitudPermisosTableAprobaciones isGh={isGH} />
    }
  ]

  return (
   <>
    <Tabs 
        activeKey={activePane}
        items={items} 
        destroyInactiveTabPane
        onChange={handleTabChange}
    />
    </>
  )
}