import { useState } from "react";
import { 
    getCondicionesSaludApi,
    addCondicionesSaludApi,
    updateCondicionesSaludApi,
    deleteCondicionesSaludApi,
    getCondicionesSaludApiID,
} from "../../../Api/administrativa/formularios-sig/condiciones-salud";

import { useAuth } from "../../useAuth";

export function useCondicionesSalud() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    CondicionesSalud: null,
  });

  const { loading, error, CondicionesSalud } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getCondicionesSalud = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getCondicionesSaludApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        CondicionesSalud: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getCondicionesSaludID = async (id) => {
    try {

      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getCondicionesSaludApiID(id, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        CondicionesSalud: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addCondicionesSalud = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addCondicionesSaludApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateCondicionesSalud = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateCondicionesSaludApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteCondicionesSalud = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteCondicionesSaludApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    CondicionesSalud,
    getCondicionesSalud,
    addCondicionesSalud,
    updateCondicionesSalud,
    deleteCondicionesSalud,
    getCondicionesSaludID,
    cancelLoader,
  };
}