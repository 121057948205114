import { Divider } from "antd";

import React, { useState } from "react";
import { getDispatchDatosId } from "../../../../Api/ProcesosApi";
import { TagCalendar } from "../TagCalendar";

export const PopoverOrder = ({ dataDispatch, selectedYear }) => {
  const [dataDispatchHover, setDataDispatchHover] = useState({});
  const [loading, setLoading] = useState(true); // Estado para controlar el loader

  const fetchPopoverData = async (year, id) => {
    setLoading(true); // Mostrar el loader antes de la petición
    try {
      const response = await getDispatchDatosId(year, id);
      setDataDispatchHover(response.data.value[0]);
      setLoading(false); // Ocultar el loader después de la petición
    } catch (error) {
      console.log(error);
      setLoading(false); // Ocultar el loader en caso de error
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case -1:
        return "black";
      case -2:
        return "gray";
      case -3:
        return "red-inverse";
      default:
        return "red-inverse";
    }
  };
  return (
    <div
      style={{
        width: "200px",
      }}
    >
      <h1 style={{ fontSize: "20px" }}>LLAMADAS</h1>
      <Divider />
      <div
        style={{
          overflowY: "scroll",
          height: "100%",
        }}
      >
        {dataDispatch.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {dataDispatch
              .filter((_, index) => index !== 0)
              ?.map((data) => {
                return (
                  <TagCalendar
                    onMouseEnter={() =>
                      fetchPopoverData(selectedYear, data?.ServiceCallID)
                    }
                    color={getStatusColor(data?.Status)}
                    nOrden={data?.ServiceCallID ?? ""}
                    dataPopover={dataDispatchHover}
                    type={data?.CustomerName ?? ""}
                    hour={data?.TechnicianName ?? ""}
                    loading={loading} // Pasa el estado loading como prop al componente TagCalendar
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
