import * as Yup from "yup";
import { Col, Form, Input, Row, message, Select, Button, Table, Spin } from "antd";
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useRiesgoPotencial } from "../../../../hooks/administrativa/formularios-sig/useRiesgoPotencial";
import { initialValuesRiesgoPotencial } from "../components/initialValues";
import { riesgoPotencialSchema } from "../components/validations";
import { useEffect } from "react";

export function RiesgoPotencialForm(props) {
  const { handleNext, handlePrev, data, dataTipoFormulario, id, setnewdataRiesgoPotencial } = props;
  const { updateRiesgoPotencial, cancelLoader, loading } = useRiesgoPotencial();
  const { Option } = Select;

  useEffect(() => {
    cancelLoader();
  }, [data])

  const formik = useFormik({
    initialValues: initialValuesRiesgoPotencial(id.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,
    onSubmit: async (formValues) => {
      try {
        await updateRiesgoPotencial(id.Id_riesgopotencial, formValues, dataTipoFormulario);
        message.success("Actualización exitosa (Riesgo Potencial)");
        setnewdataRiesgoPotencial(formValues)
        handleNext();
      } catch (error) {
        message.error("Error en la operación (Riesgo Potencial)");
      }
    },
  });

  return (
    <>
    {loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </div>
    ) : (
    <Form layout="vertical" onFinish={formik.handleSubmit} style={{ overflowY: 'scroll', height: '250px'}}>

      <Row gutter={16}>
        <Col style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}} >
          EPP
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Exposición a sustancias quimicas"
              validateStatus={formik.errors.expsustanciasquimicas && formik.touched.expsustanciasquimicas ? "error" : ""}
              help={formik.touched.expsustanciasquimicas && formik.errors.expsustanciasquimicas ? formik.errors.expsustanciasquimicas : ""}
          >
            <Select
                name="expsustanciasquimicas"
                value={formik.values.expsustanciasquimicas}
                onChange={(value) => formik.setFieldValue('expsustanciasquimicas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Caidas desde alturas superiores a 1,5 mts"
              validateStatus={formik.errors.caidasuperior15 && formik.touched.caidasuperior15 ? "error" : ""}
              help={formik.touched.caidasuperior15 && formik.errors.caidasuperior15 ? formik.errors.caidasuperior15 : ""}
          >
            <Select
                name="caidasuperior15"
                value={formik.values.caidasuperior15}
                onChange={(value) => formik.setFieldValue('caidasuperior15', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Riesgo biológico"
              validateStatus={formik.errors.riesgobiologico && formik.touched.riesgobiologico ? "error" : ""}
              help={formik.touched.riesgobiologico && formik.errors.riesgobiologico ? formik.errors.riesgobiologico : ""}
          >
            <Select
                name="riesgobiologico"
                value={formik.values.riesgobiologico}
                onChange={(value) => formik.setFieldValue('riesgobiologico', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Maquinaria pesada"
              validateStatus={formik.errors.maquinapesada && formik.touched.maquinapesada ? "error" : ""}
              help={formik.touched.maquinapesada && formik.errors.maquinapesada ? formik.errors.maquinapesada : ""}
          >
            <Select
                name="maquinapesada"
                value={formik.values.maquinapesada}
                onChange={(value) => formik.setFieldValue('maquinapesada', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Espacios confinados"
              validateStatus={formik.errors.espaciosconfinados && formik.touched.espaciosconfinados ? "error" : ""}
              help={formik.touched.espaciosconfinados && formik.errors.espaciosconfinados ? formik.errors.espaciosconfinados : ""}
          >
            <Select
                name="espaciosconfinados"
                value={formik.values.espaciosconfinados}
                onChange={(value) => formik.setFieldValue('espaciosconfinados', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Superficies calientes"
              validateStatus={formik.errors.superficiecaliente && formik.touched.superficiecaliente ? "error" : ""}
              help={formik.touched.superficiecaliente && formik.errors.superficiecaliente ? formik.errors.superficiecaliente : ""}
          >
            <Select
                name="superficiecaliente"
                value={formik.values.superficiecaliente}
                onChange={(value) => formik.setFieldValue('superficiecaliente', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Exposicion a tensiones (Riesgo electrico)"
              validateStatus={formik.errors.riesgoelectrico && formik.touched.riesgoelectrico ? "error" : ""}
              help={formik.touched.riesgoelectrico && formik.errors.riesgoelectrico ? formik.errors.riesgoelectrico : ""}
          >
            <Select
                name="riesgoelectrico"
                value={formik.values.riesgoelectrico}
                onChange={(value) => formik.setFieldValue('riesgoelectrico', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Ruido"
              validateStatus={formik.errors.ruido && formik.touched.ruido ? "error" : ""}
              help={formik.touched.ruido && formik.errors.ruido ? formik.errors.ruido : ""}
          >
            <Select
                name="ruido"
                value={formik.values.ruido}
                onChange={(value) => formik.setFieldValue('ruido', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Manipulación de cargas"
              validateStatus={formik.errors.manipulacioncarga && formik.touched.manipulacioncarga ? "error" : ""}
              help={formik.touched.manipulacioncarga && formik.errors.manipulacioncarga ? formik.errors.manipulacioncarga : ""}
          >
            <Select
                name="manipulacioncarga"
                value={formik.values.manipulacioncarga}
                onChange={(value) => formik.setFieldValue('manipulacioncarga', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Atmosferas peligrosas"
              validateStatus={formik.errors.afmosferapeligrosa && formik.touched.afmosferapeligrosa ? "error" : ""}
              help={formik.touched.afmosferapeligrosa && formik.errors.afmosferapeligrosa ? formik.errors.afmosferapeligrosa : ""}
          >
            <Select
                name="afmosferapeligrosa"
                value={formik.values.afmosferapeligrosa}
                onChange={(value) => formik.setFieldValue('afmosferapeligrosa', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Equipos en movimiento"
              validateStatus={formik.errors.equipomovimiento && formik.touched.equipomovimiento ? "error" : ""}
              help={formik.touched.equipomovimiento && formik.errors.equipomovimiento ? formik.errors.equipomovimiento : ""}
          >
            <Select
                name="equipomovimiento"
                value={formik.values.equipomovimiento}
                onChange={(value) => formik.setFieldValue('equipomovimiento', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Caida de objetos"
              validateStatus={formik.errors.caidaobjetos && formik.touched.caidaobjetos ? "error" : ""}
              help={formik.touched.caidaobjetos && formik.errors.caidaobjetos ? formik.errors.caidaobjetos : ""}
          >
            <Select
                name="caidaobjetos"
                value={formik.values.caidaobjetos}
                onChange={(value) => formik.setFieldValue('caidaobjetos', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Herramientas manuales"
              validateStatus={formik.errors.herramientamanual && formik.touched.herramientamanual ? "error" : ""}
              help={formik.touched.herramientamanual && formik.errors.herramientamanual ? formik.errors.herramientamanual : ""}
          >
            <Select
                name="herramientamanual"
                value={formik.values.herramientamanual}
                onChange={(value) => formik.setFieldValue('herramientamanual', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Izaje de cargas"
              validateStatus={formik.errors.izajecarga && formik.touched.izajecarga ? "error" : ""}
              help={formik.touched.izajecarga && formik.errors.izajecarga ? formik.errors.izajecarga : ""}
          >
            <Select
                name="izajecarga"
                value={formik.values.izajecarga}
                onChange={(value) => formik.setFieldValue('izajecarga', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Derrame / Fugas"
              validateStatus={formik.errors.derrames && formik.touched.derrames ? "error" : ""}
              help={formik.touched.derrames && formik.errors.derrames ? formik.errors.derrames : ""}
          >
            <Select
                name="derrames"
                value={formik.values.derrames}
                onChange={(value) => formik.setFieldValue('derrames', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Preciones anormales"
              validateStatus={formik.errors.precionesanormales && formik.touched.precionesanormales ? "error" : ""}
              help={formik.touched.precionesanormales && formik.errors.precionesanormales ? formik.errors.precionesanormales : ""}
          >
            <Select
                name="precionesanormales"
                value={formik.values.precionesanormales}
                onChange={(value) => formik.setFieldValue('precionesanormales', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Radiaciones Ionizantes"
              validateStatus={formik.errors.radiaciones && formik.touched.radiaciones ? "error" : ""}
              help={formik.touched.radiaciones && formik.errors.radiaciones ? formik.errors.radiaciones : ""}
          >
            <Select
                name="radiaciones"
                value={formik.values.radiaciones}
                onChange={(value) => formik.setFieldValue('radiaciones', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Esquirlas / Particulas"
              validateStatus={formik.errors.esquirlas && formik.touched.esquirlas ? "error" : ""}
              help={formik.touched.esquirlas && formik.errors.esquirlas ? formik.errors.esquirlas : ""}
          >
            <Select
                name="esquirlas"
                value={formik.values.esquirlas}
                onChange={(value) => formik.setFieldValue('esquirlas', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
              label="Incendio / Explosion"
              validateStatus={formik.errors.incendio && formik.touched.incendio ? "error" : ""}
              help={formik.touched.incendio && formik.errors.incendio ? formik.errors.incendio : ""}
          >
            <Select
                name="incendio"
                value={formik.values.incendio}
                onChange={(value) => formik.setFieldValue('incendio', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
              label="Otros (Describa)"
              validateStatus={formik.errors.otrosriesgos && formik.touched.otrosriesgos ? "error" : ""}
              help={formik.touched.otrosriesgos && formik.errors.otrosriesgos
              ? formik.errors.otrosriesgos
              : ""}
          >
              <Input
                name="otrosriesgos"
                value={formik.values.otrosriesgos}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
          </Form.Item>
        </Col>
      </Row>


      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button type="primary" onClick={handlePrev}>
            Atras
        </Button>
        <Button type="primary" htmlType="submit">
            Actualizar
        </Button>
      </div>
    </Form>
    )}
    </>
  );
}

export function RiesgoPotencialFullView(props) {
  const { data } = props;
  const { cancelLoader, loading } = useRiesgoPotencial();

  useEffect(() => {
    cancelLoader();
  }, [data]);

  const formik = useFormik({
    initialValues: initialValuesRiesgoPotencial(data.Id_formulario, data),
  });

  const columns = [
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Detalle',
      dataIndex: 'detail',
      key: 'detail',
    },
  ];

  const dataSource = [
    { category: 'Exposición a sustancias químicas', detail: formik.values.expsustanciasquimicas },
    { category: 'Caídas desde alturas superiores a 1,5 mts', detail: formik.values.caidasuperior15 },
    { category: 'Riesgo biológico', detail: formik.values.riesgobiologico },
    { category: 'Maquinaria pesada', detail: formik.values.maquinapesada },
    { category: 'Espacios confinados', detail: formik.values.espaciosconfinados },
    { category: 'Superficies calientes', detail: formik.values.superficiecaliente },
    { category: 'Exposición a tensiones (Riesgo eléctrico)', detail: formik.values.riesgoelectrico },
    { category: 'Ruido', detail: formik.values.ruido },
    { category: 'Manipulación de cargas', detail: formik.values.manipulacioncarga },
    { category: 'Atmósferas peligrosas', detail: formik.values.afmosferapeligrosa },
    { category: 'Equipos en movimiento', detail: formik.values.equipomovimiento },
    { category: 'Caída de objetos', detail: formik.values.caidaobjetos },
    { category: 'Herramientas manuales', detail: formik.values.herramientamanual },
    { category: 'Izaje de cargas', detail: formik.values.izajecarga },
    { category: 'Derrame / Fugas', detail: formik.values.derrames },
    { category: 'Presiones anormales', detail: formik.values.precionesanormales },
    { category: 'Radiaciones Ionizantes', detail: formik.values.radiaciones },
    { category: 'Esquirlas / Partículas', detail: formik.values.esquirlas },
    { category: 'Incendio / Explosión', detail: formik.values.incendio },
    { category: 'Otros (Describa)', detail: formik.values.otrosriesgos },
  ];

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row style={{ fontSize: '25px' }}>Riesgo Potencial</Row>
      <hr />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
      />
    </>
  );
}

export default RiesgoPotencialFullView;

// export function RiesgoPotencialFullView(props) {
//   const { data } = props;
//   const { cancelLoader, loading } = useRiesgoPotencial();

//   useEffect(() => {
//     cancelLoader();
//   }, [data])

//   const formik = useFormik({
//     initialValues: initialValuesRiesgoPotencial(data.Id_formulario, data),
//   });

//   return (
//     <>
//     {loading ? (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     ) : (
//     <Form layout="vertical" style={{ overflowY: 'scroll', height: '250px'}}>
//       <Row style={{ fontSize: '25px' }} >Riesgo Potencial</Row>
//       <hr/>
//       <Row gutter={16}>
//         <Col style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}} >
//           EPP
//         </Col>
//       </Row>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Exposición a sustancias quimicas" >
//             <Input
//                 name="expsustanciasquimicas"
//                 value={formik.values.expsustanciasquimicas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Caidas desde alturas superiores a 1,5 mts" >
//             <Input
//                 name="caidasuperior15"
//                 value={formik.values.caidasuperior15}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Riesgo biológico" >
//             <Input
//                 name="riesgobiologico"
//                 value={formik.values.riesgobiologico}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
      
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Maquinaria pesada" >
//             <Input
//                 name="maquinapesada"
//                 value={formik.values.maquinapesada}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Espacios confinados" >
//             <Input
//                 name="espaciosconfinados"
//                 value={formik.values.espaciosconfinados}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Superficies calientes" >
//             <Input
//                 name="superficiecaliente"
//                 value={formik.values.superficiecaliente}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Exposicion a tensiones (Riesgo electrico)" >
//             <Select
//                 name="riesgoelectrico"
//                 value={formik.values.riesgoelectrico}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Ruido" >
//             <Input
//                 name="ruido"
//                 value={formik.values.ruido}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Manipulación de cargas" >
//             <Input
//                 name="manipulacioncarga"
//                 value={formik.values.manipulacioncarga}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Atmosferas peligrosas" >
//             <Input
//                 name="afmosferapeligrosa"
//                 value={formik.values.afmosferapeligrosa}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Equipos en movimiento" >
//             <Input
//                 name="equipomovimiento"
//                 value={formik.values.equipomovimiento}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Caida de objetos" >
//             <Input
//                 name="caidaobjetos"
//                 value={formik.values.caidaobjetos}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Herramientas manuales" >
//             <Input
//                 name="herramientamanual"
//                 value={formik.values.herramientamanual}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Izaje de cargas" >
//             <Input
//                 name="izajecarga"
//                 value={formik.values.izajecarga}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Derrame / Fugas" >
//             <Input
//                 name="derrames"
//                 value={formik.values.derrames}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Preciones anormales" >
//             <Input
//                 name="precionesanormales"
//                 value={formik.values.precionesanormales}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Radiaciones Ionizantes" >
//             <Input
//                 name="radiaciones"
//                 value={formik.values.radiaciones}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Esquirlas / Particulas" >
//             <Input
//                 name="esquirlas"
//                 value={formik.values.esquirlas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={24}>
//         <Col span={8}>
//           <Form.Item label="Incendio / Explosion" >
//             <Input
//                 name="incendio"
//                 value={formik.values.incendio}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={16}>
//           <Form.Item label="Otros (Describa)">
//               <Input
//                 name="otrosriesgos"
//                 value={formik.values.otrosriesgos}
//                 readOnly
//               />
//           </Form.Item>
//         </Col>
//       </Row>
//     </Form>
//     )}
//     </>
//   );
// }