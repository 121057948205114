import { useState } from "react";
import {
  getArchivosApi,
  addArchivosApi,
  updateArchivosApi,
  deleteArchivosApi,
} from "../Api/Archivos";  
import { useAuth } from "./useAuth";


export function useArchivos() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    Archivos: null,
  });

  const { loading, error, Archivos } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getArchivos = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getArchivosApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        Archivos: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addArchivos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addArchivosApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateArchivos = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateArchivosApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteArchivos = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteArchivosApi(id, auth.token);
    
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    Archivos,
    getArchivos,
    addArchivos,
    updateArchivos,
    deleteArchivos,
  };
}
