import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import  { GridSap } from './GridSap';
import SapPage from './SapPage';

export function Main() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Grid" />
        <Tab label="Calendario" />
      </Tabs>
      <Box p={3}>
        {selectedTab === 0 && <GridSap />}
        {selectedTab === 1 && <SapPage />}
      </Box>
    </div>
  );
}
