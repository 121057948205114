import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";


  
// Función para obtener los datos de un LineaEtica
export async function getLineaEticaApi(token) {
    const url = `${BASE_API}/lineaetica/api/denuncia`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo LineaEtica
  export async function addLineaEticaApi(data, token) {
    const url = `${BASE_API}/lineaetica/api/denuncia/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos de un LineaEtica existente
  export async function updateLineaEticaApi(id, data, token) {
    const url = `${BASE_API}/lineaetica/api/denuncia/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar un LineaEtica
  export async function deleteLineaEticaApi(id, token) {
    const url = `${BASE_API}/lineaetica/api/denuncia/${id}/`;
    return makeRequest(url, "DELETE", token);
  }
