import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Grid, Typography, TextField, CircularProgress } from '@mui/material';
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_API } from "../utils/constants";

export function AddEditExcel(props) {
  const { onRefetch, onClose } = props;
  const [fileName, setFileName] = useState('');
  const [fileFieldName, setFileFieldName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleFile = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
  };

  const handleFileFieldNameChange = (event) => {
    setFileFieldName(event.target.value);
  };

  const handleUpload = () => {
    if (!fileName || !fileFieldName) {
      setErrorMessage('Por favor, complete todos los campos');
      return;
    }

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    formData.append('Name', fileFieldName);
    const url = `${BASE_API}/cargue/detalle/`;

    setLoading(true); // Activar el loader

    axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setUploadProgress(progress);
      },
    })
      .then((response) => {
        const data = response.data;
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          onClose();
          onRefetch();
          Swal.fire({
            title: 'Éxito',
            text: 'La operación se realizó correctamente',
            icon: 'success',
            container: document.getElementById('modal-container'), // Asigna el contenedor del modal aquí
          }).then(() => {
            // Lógica adicional después del éxito
          });
        }
      })
      .catch((error) => {
        onClose();
        onRefetch();
        setErrorMessage('Ocurrió un error al guardar');
      })
      .finally(() => {
        setLoading(false); // Desactivar el loader después de la respuesta
      });
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFile(acceptedFiles);
    },
  });

  return (
    <Grid container spacing={2} id="modal-container">
      <Grid item xs={12}>
        <TextField
          value={fileFieldName}
          onChange={handleFileFieldNameChange}
          label="Nombre del archivo"
          variant="outlined"
          margin="normal"
          fullWidth
        />
      </Grid>
      {errorMessage && (
        <Typography variant="body1" style={{ color: 'red', margin: '10px 0' }}>
          {errorMessage}
        </Typography>
      )}

      <Grid item xs={12}>
        <div
          {...getRootProps()}
          style={{ backgroundColor: '#f7f7f7', padding: '20px', borderRadius: '5px', border: '1px dashed #ccc', textAlign: 'center' }}
        >
          <input {...getInputProps()} />
          <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0' }}>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
          <p style={{ fontSize: '14px', margin: '10px 0 0' }}>Solo se permiten archivos en formato Excel o CSV (xlsx, xls, csv).</p>
        </div>
        {fileName && (
          <Typography variant="body1" style={{ margin: '10px 0' }}>
            Archivo seleccionado: {fileName}
          </Typography>
        )}
      </Grid>

      {uploadProgress > 0 && (
        <Grid item xs={12}>
          <div style={{ width: '100%', height: '10px', backgroundColor: '#f7f7f7', borderRadius: '5px', marginTop: '10px' }}>
            <div
              style={{
                width: `${uploadProgress}%`,
                height: '100%',
                backgroundColor: '#29b6f6',
                borderRadius: '5px',
                transition: 'width 0.3s ease-in-out',
              }}
            />
          </div>
        </Grid>
      )}

      {loading && (
        <>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant="caption" color="textSecondary" style={{ marginTop: '10px' }}>
         Por favor, no recargue la página hasta que el proceso finalice.
       </Typography>
          <CircularProgress />
          
        </Grid>
         
       </>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="secondary" onClick={handleUpload} disabled={!fileName || !fileFieldName || loading}>
          Subir a la plataforma
        </Button>
      </div>
     
    </Grid>
  );
}
