import { useState } from "react";
import {
  getConsultasApi,
  addConsultasApi,
  updateConsultasApi,
} from "../../Api/syzia/syziaApi";

import { useAuth } from "../useAuth";


export function useSyzia() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    Consultas: null,
    RespuestaP: null,
    RespuestaU: null,
    id_consulta: null,
    RespuestaUB: null,
  });

  const { loading, error, Consultas, RespuestaP, RespuestaU, RespuestaUB, id_consulta } = state;
  const { auth } = useAuth();

  /* const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  
  const getConsulta = async ( email ) => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getConsultasApi(auth.token, email);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        Consultas: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addConsulta = async (data, temperature, email) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addConsultasApi(data, auth.token, email, temperature);
      setState((prevState) => ({ 
        ...prevState, 
        loading: false,
        RespuestaP: result.SYZIA,
        id_consulta: result.id
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateConsulta = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateConsultasApi(id, data, auth.token);
      setState((prevState) => ({ 
        ...prevState, 
        loading: false,
        RespuestaU: result.historial,
        RespuestaUB : result.SYZIA,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  return {
    loading,
    error,
    Consultas,
    RespuestaP,
    RespuestaU,
    RespuestaUB,
    id_consulta,
    getConsulta,
    addConsulta,
    updateConsulta,
  };
}