import { Breadcrumb, Layout, Menu, theme } from 'antd';
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import LogoSyz from "../assets/logo.png";
import { Items } from "./Items";
import { Logo, LogoContainer } from "./styles";
import Login from '../pages/login/LoginPage'
import { useAuth } from '../hooks/useAuth';
const { Content, Footer, Sider } = Layout;

export const LayoutComponent = ({ children }) => {
  const { auth } = useAuth();

  if (!auth) return <Login/>;

  return <SideBar>{children}</SideBar>;
};


function SideBar({ children }) {
  const location = useLocation();
  const { items } = Items() || {};
  const [collapsed, setCollapsed] = useState(true);
  const { auth } = useAuth();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const getLocationName = () => {
    const pathArray = location.pathname.split('/');
    const lastPath = pathArray[pathArray.length - 1];

    return lastPath;
  };
  
  return (
    <Layout style={{ minHeight: '100vh' }}>
      
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <LogoContainer>
          <Logo src={LogoSyz} alt="Descripción del logo" />
        </LogoContainer>
        <Menu theme="dark" onScroll={2} defaultSelectedKeys={['1']} mode="vertical" items={items}/>
      </Sider>
      <Layout>
        <Content
          style={{ margin: '0 16px' }} >
            
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
            items={[
              { title: auth.me?.domain_name },
              { title: getLocationName() }
            ]}
          />
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
           {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            fontWeight: 'bold'
          }}
        >
          <strong style={{ color: "rgb(204, 48 ,43)" }}>SYZ</strong> COLOMBIA 2024
        </Footer>
      </Layout>
    </Layout>
  );
}

export default LayoutComponent;