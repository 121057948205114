import { useState } from "react";
import {
  getSurveysOrdersInvoicesApi,
  addSurveysOrdersInvoicesApi,
  updateSurveysOrdersInvoicesApi,
  deleteSurveysOrdersInvoicesApi,
} from "../Api/SurveysOrdersInvoices";  
import { useAuth } from "./useAuth";
export function useSurveysOrdersInvoices() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    SurveysOrdersInvoices: null,
  });

  const { loading, error, SurveysOrdersInvoices } = state;
  const { auth } = useAuth();
  
  const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  };

  const getSurveysOrdersInvoices = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getSurveysOrdersInvoicesApi(auth.token);
      handleResponse(response);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        SurveysOrdersInvoices: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addSurveysOrdersInvoices = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addSurveysOrdersInvoicesApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateSurveysOrdersInvoices = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateSurveysOrdersInvoicesApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteSurveysOrdersInvoices = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteSurveysOrdersInvoicesApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    SurveysOrdersInvoices,
    getSurveysOrdersInvoices,
    addSurveysOrdersInvoices,
    updateSurveysOrdersInvoices,
    deleteSurveysOrdersInvoices,
  };
}