import { useState } from "react";
import {
  getCompetenceApi,
  addCompetenceApi,
  updateCompetenceApi,
  deleteCompetenceApi,
} from "../Api/Competence";  
import { useAuth } from "./useAuth";

export function useCompetence() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    Competence: null,
  });

  const { loading, error, Competence } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getCompetence = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getCompetenceApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        Competence: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addCompetence = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addCompetenceApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateCompetence = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateCompetenceApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteCompetence = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteCompetenceApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    Competence,
    getCompetence,
    addCompetence,
    updateCompetence,
    deleteCompetence,
  };
}