import { useState } from "react";
import { 
    getAccesoPrevencionApi,
    addAccesoPrevencionApi,
    updateAccesoPrevencionApi,
    deleteAccesoPrevencionApi,
    getAccesoPrevencionApiID,
} from "../../../Api/administrativa/formularios-sig/acceso-prevencion";

import { useAuth } from "../../useAuth";

export function useAccesoPrevencion() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    AccesoPrevencion: null,
  });

  const { loading, error, AccesoPrevencion } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getAccesoPrevencion = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getAccesoPrevencionApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        AccesoPrevencion: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getAccesoPrevencionID = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getAccesoPrevencionApiID(id, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        AccesoPrevencion: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addAccesoPrevencion = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addAccesoPrevencionApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateAccesoPrevencion = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateAccesoPrevencionApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteAccesoPrevencion = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteAccesoPrevencionApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  }

  return {
    loading,
    error,
    AccesoPrevencion,
    getAccesoPrevencion,
    addAccesoPrevencion,
    updateAccesoPrevencion,
    deleteAccesoPrevencion,
    getAccesoPrevencionID,
    cancelLoader,
  };
}