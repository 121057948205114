import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { TablaGeneral } from "../../../components/comons/TablaGeneral";
import { useUsersAD } from '../../../hooks/useUserAD';
import { Switch } from "@mui/material";

const headers = [
    { label: "ID", field: "id_user" },
    { label: "Nombres y apellidos", field: "full_name" },
    { label: "Email", field: "email" },
    { label: "Nombre de  dominio", field: "domain_name" },
    { label: "Cargo", field: "cargo" },
    { label: "Área", field: "area" },
    { label: "¿Está activo?", field: "is_active"},
  ];
  
  const popoverColumns = [
    { label: "Permisos Leer Universyz", field: "universyz_only_view" },
    { label: "Permisos Full Universyz", field: "universyz_full_modify" },
    { label: "Permisos Leer Comercial Gerentes", field: "comercial_only_view" },
    { label: "Permisos Leer Comercial General", field: "comercial_only_general" },
    { label: "Permisos Full Comercial", field: "comercial_full_modify" },
    { label: "Permisos Leer Marketing", field: "marketing_only_view" },
    { label: "Permisos Full Marketing", field: "marketing_full_modify" },
    { label: "Permisos Leer Servicios", field: "servicios_only_view" },
    { label: "Permisos Full Servicios", field: "servicios_full_modify" },
    { label: "Permisos Leer Administrativo", field: "administrativo_only_view" },
    { label: "Permisos Full Administrativo", field: "administrativo_full_modify" },
    { label: "Permisos Sig Aprobador", field: "administrativo_sig_aprobador" },
    { label: "Permisos Admon", field: "admon_user_fullview" },
  ];

  export function UsuariosDAPage() {
    const { loading, UsuariosDA, getUsuariosAD, updateUsersPermission } = useUsersAD();
    const [data, setData] = useState([]);
  
    useEffect(() => {
      getUsuariosAD();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSwitchChange = async (id_user, field, checked) => {
      // Actualizar el estado en el servidor
      await updateUsersPermission(id_user, field, checked);
      // Recargar los datos de la tabla
      getUsuariosAD();
  };
    
    // Mapeo de usuarios para parchar los datos que son booleanos sean si no, y validación de switches
    const transformedData = UsuariosDA && UsuariosDA.map(usuario => ({
      ...usuario,
      is_active: <Switch
                      checked={usuario.is_active}
                      onChange={(event) => handleSwitchChange(usuario.id_user, 'is_active', event.target.checked)}
                  />,
      universyz_only_view: <Switch
                              checked={usuario.universyz_only_view}
                              onChange={(event) => handleSwitchChange(usuario.id_user, 'universyz_only_view', event.target.checked)}
                          />,
      universyz_full_modify: <Switch
                                  checked={usuario.universyz_full_modify}
                                  onChange={(event) => handleSwitchChange(usuario.id_user, 'universyz_full_modify', event.target.checked)}
                              />,
      comercial_only_view: <Switch
                              checked={usuario.comercial_only_view}
                              onChange={(event) => handleSwitchChange(usuario.id_user, 'comercial_only_view', event.target.checked)}
                          />,
      comercial_only_general: <Switch
                              checked={usuario.comercial_only_general}
                              onChange={(event) => handleSwitchChange(usuario.id_user, 'comercial_only_general', event.target.checked)}
                          />,
      comercial_full_modify: <Switch
                                  checked={usuario.comercial_full_modify}
                                  onChange={(event) => handleSwitchChange(usuario.id_user, 'comercial_full_modify', event.target.checked)}
                              />,
      marketing_only_view: <Switch
                              checked={usuario.marketing_only_view}
                              onChange={(event) => handleSwitchChange(usuario.id_user, 'marketing_only_view', event.target.checked)}
                          />,
      marketing_full_modify: <Switch
                                  checked={usuario.marketing_full_modify}
                                  onChange={(event) => handleSwitchChange(usuario.id_user, 'marketing_full_modify', event.target.checked)}
                              />,
      servicios_only_view: <Switch
                              checked={usuario.servicios_only_view}
                              onChange={(event) => handleSwitchChange(usuario.id_user, 'servicios_only_view', event.target.checked)}
                          />,
      servicios_full_modify: <Switch
                                  checked={usuario.servicios_full_modify}
                                  onChange={(event) => handleSwitchChange(usuario.id_user, 'servicios_full_modify', event.target.checked)}
                              />,
      administrativo_only_view: <Switch
                                      checked={usuario.administrativo_only_view}
                                      onChange={(event) => handleSwitchChange(usuario.id_user, 'administrativo_only_view', event.target.checked)}
                                  />,
      administrativo_full_modify: <Switch
                                      checked={usuario.administrativo_full_modify}
                                      onChange={(event) => handleSwitchChange(usuario.id_user, 'administrativo_full_modify', event.target.checked)}
                                  />,
      administrativo_sig_aprobador: <Switch
                                      checked={usuario.administrativo_sig_aprobador}
                                      onChange={(event) => handleSwitchChange(usuario.id_user, 'administrativo_sig_aprobador', event.target.checked)}
                                  />,
      admon_user_fullview: <Switch
                              checked={usuario.admon_user_fullview}
                              onChange={(event) => handleSwitchChange(usuario.id_user, 'admon_user_fullview', event.target.checked)}
                            />,
  }));

    return (
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <TablaGeneral
            Data={transformedData}
            columns={headers}
            popoverColumns={popoverColumns}
            filterFields={["id_user"]}
          />
        )}
      </>
    );
  }
  
