import { Button, Col, Form, Input, Row, message, Select, DatePicker } from "antd";
import { Formik, useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useSatisfactionSurveys } from "../../hooks/useSatisfactionSurveys";
import { formStyle } from "../comons/styles";
import moment from "moment";

export function SatisfactionSurveysForm(props) {
  const { onClose, onRefetch, SatisfactionSurveys } = props;
  const { addSatisfactionSurveys, updateSatisfactionSurveys } = useSatisfactionSurveys();
  const { Option } = Select;

  const formik = useFormik({
    initialValues: initialValues(SatisfactionSurveys), // Utiliza la función getInitialValues
    validationSchema: Yup.object(
      SatisfactionSurveys ? updateSchema() : newSchema()
    ),
    onSubmit: async (formValue) => {
      try {
        if (SatisfactionSurveys)
          await updateSatisfactionSurveys(
            SatisfactionSurveys.id_encuesta,
            formValue
          );
        else await addSatisfactionSurveys(formValue);

        onRefetch();
        onClose();
        message.success("Operación exitosa");
      } catch (error) {
        if (error?.message) {
          message.error(error.message);
        } else {
          onClose();
        }
      }
    },
  });


  return (
    <Formik initialValues={initialValues()}>
      <Form layout="vertical" onFinish={formik.handleSubmit} style={formStyle}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Empresas"
              validateStatus={
                formik.errors.empresas && formik.touched.empresas ? "error" : ""
              }
              help={
                formik.touched.empresas && formik.errors.empresas
                  ? formik.errors.empresas
                  : ""
              }
            >
              <Input
                name="empresas"
                value={formik.values.empresas}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Nombres y Apellidos"
              validateStatus={
                formik.errors.nombres_apellidos &&
                formik.touched.nombres_apellidos
                  ? "error"
                  : ""
              }
              help={
                formik.touched.nombres_apellidos &&
                formik.errors.nombres_apellidos
                  ? formik.errors.nombres_apellidos
                  : ""
              }
            >
              <Input
                name="nombres_apellidos"
                value={formik.values.nombres_apellidos}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Correo"
              validateStatus={
                formik.errors.correo && formik.touched.correo ? "error" : ""
              }
              help={
                formik.touched.correo && formik.errors.correo
                  ? formik.errors.correo
                  : ""
              }
            >
              <Input
                name="correo"
                value={formik.values.correo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
        <Col span={8}>
            <Form.Item
              label="Fecha Documento"
              validateStatus={
                formik.errors.fecha_documento && formik.touched.fecha_documento
                  ? "error"
                  : ""
              }
              help={
                formik.touched.fecha_documento && formik.errors.fecha_documento
                  ? formik.errors.fecha_documento
                  : ""
              }
            >
              <DatePicker
                style={{width: '100%'}}
                name="fecha_documento"
                value={formik.values.fecha_documento ? moment(formik.values.fecha_documento) : null}
                onChange={(date, dateString) => formik.setFieldValue('fecha_documento', dateString)}
                onBlur={() => formik.setFieldTouched('fecha_documento')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="¿Cómo percibe la actitud de servicio del personal que lo atendió?"
              validateStatus={
                formik.errors.p1 && formik.touched.p1 ? "error" : ""
              }
              help={
                formik.touched.p1 && formik.errors.p1 ? formik.errors.p1 : ""
              }
            >
              <Select
                name="p1"
                value={formik.values.p1}
                onChange={(value) => formik.setFieldValue('p1', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="¿Los productos adquiridos cumplieron sus expectativas?"
              validateStatus={
                formik.errors.p2 && formik.touched.p2 ? "error" : ""
              }
              help={
                formik.touched.p2 && formik.errors.p2 ? formik.errors.p2 : ""
              }
            >
              <Select
                name="p2"
                value={formik.values.p2}
                onChange={(value) => formik.setFieldValue('p2', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
        <Col span={8}>
            <Form.Item
              label="¿Fue informado del estado de sus solicitudes y/o pedidos?"
              validateStatus={
                formik.errors.p3 && formik.touched.p3 ? "error" : ""
              }
              help={
                formik.touched.p3 && formik.errors.p3 ? formik.errors.p3 : ""
              }
            >
              <Select
                name="p3"
                value={formik.values.p3}
                onChange={(value) => formik.setFieldValue('p3', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="¿Recibió los productos en los tiempos de entrega pactados?"
              validateStatus={
                formik.errors.p4 && formik.touched.p4 ? "error" : ""
              }
              help={
                formik.touched.p4 && formik.errors.p4 ? formik.errors.p4 : ""
              }
            >
              <Select
                name="p4"
                value={formik.values.p4}
                onChange={(value) => formik.setFieldValue('p4', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="¿Las condiciones de entrega y empaque fueron adecuadas?"
              validateStatus={
                formik.errors.p5 && formik.touched.p5 ? "error" : ""
              }
              help={
                formik.touched.p5 && formik.errors.p5 ? formik.errors.p5 : ""
              }
            >
              <Select
                name="p5"
                value={formik.values.p5}
                onChange={(value) => formik.setFieldValue('p5', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
        <Col span={8}>
            <Form.Item
              label="¿Se cumplieron los requisitos exigidos por su empresa para la facturación de mercancía?"
              validateStatus={
                formik.errors.p6 && formik.touched.p6 ? "error" : ""
              }
              help={
                formik.touched.p6 && formik.errors.p6 ? formik.errors.p6 : ""
              }
            >
              <Select
                name="p6"
                value={formik.values.p6}
                onChange={(value) => formik.setFieldValue('p6', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="¿Siente respaldo de SYZ una vez finalizada su compra?"
              validateStatus={
                formik.errors.p7 && formik.touched.p7 ? "error" : ""
              }
              help={
                formik.touched.p7 && formik.errors.p7 ? formik.errors.p7 : ""
              }
            >
              <Select
                name="p7"
                value={formik.values.p7}
                onChange={(value) => formik.setFieldValue('p7', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="¿Como percibe la relación beneficio - costo del producto adquirido?"
              validateStatus={
                formik.errors.p8 && formik.touched.p8 ? "error" : ""
              }
              help={
                formik.touched.p8 && formik.errors.p8 ? formik.errors.p8 : ""
              }
            >
              <Select
                name="p8"
                value={formik.values.p8}
                onChange={(value) => formik.setFieldValue('p8', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={16}>
        <Col span={8}>
            <Form.Item
              label="¿Qué tan difícil o fácil fue resolver su solicitud con SYZ?"
              validateStatus={
                formik.errors.p9 && formik.touched.p9 ? "error" : ""
              }
              help={
                formik.touched.p9 && formik.errors.p9 ? formik.errors.p9 : ""
              }
            >
              <Select
                name="p9"
                value={formik.values.p9}
                onChange={(value) => formik.setFieldValue('p9', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="¿Qué probabilidades hay de que nos recomiendes con tus conocidos?"
              validateStatus={
                formik.errors.p10 && formik.touched.p10 ? "error" : ""
              }
              help={
                formik.touched.p10 && formik.errors.p10 ? formik.errors.p10 : ""
              }
            >
              <Select
                name="p10"
                value={formik.values.p10}
                onChange={(value) => formik.setFieldValue('p10', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="¿Tiene usted algún comentario, felicitación, queja o reclamo respecto al servicio?"
              validateStatus={
                formik.errors.p11 && formik.touched.p11 ? "error" : ""
              }
              help={
                formik.touched.p11 && formik.errors.p11 ? formik.errors.p11 : ""
              }
            >
              <Input
                name="p11"
                value={formik.values.p11}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {SatisfactionSurveys ? "Actualizar" : "Crear"}
          </Button>
        </Form.Item>
      </Form>
    </Formik>
  );
}

// Función para inicializar los valores del formulario
function initialValues(data) {
  return {
    empresas: data?.empresas || "",
    nombres_apellidos: data?.nombres_apellidos || "",
    correo: data?.correo || "",
    fecha_documento: data?.fecha_documento || null,
    p1: data?.p1 || "",
    p2: data?.p2 || "",
    p3: data?.p3 || "",
    p4: data?.p4 || "",
    p5: data?.p5 || "",
    p6: data?.p6 || "",
    p7: data?.p7 || "",
    p8: data?.p8 || "",
    p9: data?.p9 || "",
    p10: data?.p10 || "",
    p11: data?.p11 || "",
  };
}

// Función para definir el esquema de validación del formulario
function newSchema() {
  return {
    empresas: Yup.string().required("El campo de empresas es requerido"),
    nombres_apellidos: Yup.string().required(
      "El campo de nombres y apellidos es requerido"
    ),
    correo: Yup.string()
      .email("El formato de correo electrónico es inválido")
      .required("El campo de correo es requerido"),
    fecha_documento: Yup.string().required(
      "El campo de fecha de documento es requerido"
    ),
    p1: Yup.string().required("El campo es requerido"),
    p2: Yup.string().required("El campo es requerido"),
    p3: Yup.string().required("El campo es requerido"),
    p4: Yup.string().required("El campo es requerido"),
    p5: Yup.string().required("El campo es requerido"),
    p6: Yup.string().required("El campo es requerido"),
    p7: Yup.string().required("El campo es requerido"),
    p8: Yup.string().required("El campo es requerido"),
    p9: Yup.string().required("El campo es requerido"),
    p10: Yup.string().required("El campo es requerido"),
    p11: Yup.string().required("El campo es requerido"),
  };
}

function updateSchema() {
  return {
    empresas: Yup.string().required("El campo de empresas es requerido"),
    nombres_apellidos: Yup.string().required(
      "El campo de nombres y apellidos es requerido"
    ),
    correo: Yup.string()
      .email("El formato de correo electrónico es inválido")
      .required("El campo de correo es requerido"),
    fecha_documento: Yup.string().required(
      "El campo de fecha de documento es requerido"
    ),
    p1: Yup.string().required("El campo es requerido"),
    p2: Yup.string().required("El campo es requerido"),
    p3: Yup.string().required("El campo es requerido"),
    p4: Yup.string().required("El campo es requerido"),
    p5: Yup.string().required("El campo es requerido"),
    p6: Yup.string().required("El campo es requerido"),
    p7: Yup.string().required("El campo es requerido"),
    p8: Yup.string().required("El campo es requerido"),
    p9: Yup.string().required("El campo es requerido"),
    p10: Yup.string().required("El campo es requerido"),
    p11: Yup.string().required("El campo es requerido"),
  };
}
