import { HelmetProvider } from 'react-helmet-async';
import { Navigation } from "./routes";
import { AuthProvider } from "./context";

function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <Navigation />
      </HelmetProvider>
    </AuthProvider>
  );
}

export default App;
