import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from 'formik';
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button, Col, Form, Row, message, Select, Tooltip } from "antd";
import { TextField } from "@mui/material";
import DatePicker from 'react-datepicker';
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import { useComisiones } from '../../hooks/useComisiones';
import { TablaGeneral } from "../../components/comons/TablaComisiones";
  
const headersComisiones = [
    { label: "Fecha comisión", field: "fecha_comision" },
    { label: "Mes comisión", field: "mescomision" },
    { label: "Proyecto", field: "Proyecto" },
    { label: "Nombre proyecto", field: "Nombreproy" },
    { label: "Fecha primer documento", field: "fecha_Pdoc" },
    { label: "Fecha ultimo pago", field: "fecha_Upago" },
    { label: "Total costo", field: "Total_costo" },
    { label: "Total factura", field: "Total_Factura" },
    { label: "Utilidad", field: "utilidad" },
    { label: "Aplica", field: "Aplica" },
    { label: "Porcentaje", field: "porcentaje" },
    { label: "Total pago", field: "Total_Pago" },
];

const headersDetalleComisiones = [
  { label: "Fecha Detalle Comisión", field: "fecha_detcomision" },
  { label: "Número de Pago", field: "num_pago" },
  { label: "Moneda de Pago", field: "moneda_pago" },
  { label: "Total Pago", field: "total_pago" },
  { label: "Fecha de Pago", field: "fecha_pago" },
  { label: "Medio de Pago", field: "medio_pago" },
  { label: "Número de Factura", field: "num_factura" },
  { label: "Moneda de Factura", field: "moneda_factura" },
  { label: "Total Factura", field: "total_factura" },
  { label: "Fecha de Factura", field: "fecha_factura" },
  { label: "Item de Factura", field: "item_factura" },
  { label: "Descripción del Item", field: "descripcion_item" },
  { label: "Cantidad de Factura", field: "cantidad_factura" },
  { label: "Costo de Factura", field: "costo_factura" },
  { label: "Precio Unitario de Factura", field: "precio_unit_factura" },
  { label: "Número de Remisión", field: "num_remision" },
  { label: "Fecha de Remisión", field: "fecha_remision" },
  { label: "Costo de Remisión", field: "costo_Remision" },
  { label: "Número de Orden de Venta", field: "num_ordenventa" },
  { label: "Tipo de Orden de Venta", field: "tipo_ordenventa" },
  { label: "Fecha de Orden de Venta", field: "fecha_ordenventa" },
  { label: "Total Orden de Venta", field: "total_ordenventa" },
  { label: "Item de Orden de Venta", field: "item_ordenventa" },
  { label: "Cantidad de Orden de Venta", field: "cantidad_ordenventa" },
  { label: "Precio de Orden de Venta", field: "precio_ordenventa" },
  { label: "Cotizador", field: "cotizador" },
  { label: "Soporte de Línea", field: "soporte_linea" },
  { label: "Gerente de Industria", field: "gerente_industria" },
  { label: "Gerente de Propuesta", field: "gerente_propuesta" },
  { label: "Número de Cotización", field: "num_cotizacion" },
  { label: "Total Cotización", field: "total_cotizacion" },
  { label: "Fecha de Cotización", field: "fecha_cotizacion" },
  { label: "Item de Cotización", field: "item_cotizacion" },
  { label: "Cantidad de Cotización", field: "cantidad_cotizacion" },
  { label: "Precio de Cotización", field: "precio_cotizacion" },
  { label: "Tipo de Producto", field: "tipo_producto" },
  { label: "Grupo", field: "grupo" },
  { label: "Línea", field: "linea" },
  { label: "Sublínea", field: "sublinea" },
  { label: "TI", field: "ti" },
  { label: "Marca", field: "marca" },
  { label: "Descuento", field: "descuento" },
  { label: "Tasa de Factura", field: "tasa_factura" },
  { label: "Tasa de Pago", field: "tasa_pago" },
  { label: "Mes Comisión", field: "mescomision" },
  { label: "Proyecto", field: "proyecto" },
];

const popoverColumnsDetalleComisiones = [
    { label: "Cargo Cotizador", field: "Cargo_Cotizador" },
    { label: "Cargo Gerente de Industria", field: "Cargo_GIndustria" },
    { label: "Cargo Gerente de Propuesta", field: "Cargo_Gpropuesta" },
    { label: "Cargo Soporte de Línea", field: "Cargo_SopLin" },
    { label: "Frecuencia Prestada Cotizador", field: "FPrest_Cotizador" },
    { label: "Frecuencia Prestada Gerente de Industria", field: "FPrest_GIndustria" },
    { label: "Frecuencia Prestada Gerente de Propuesta", field: "FPrest_GPropuesta" },
    { label: "Frecuencia Prestada Soporte de Línea", field: "FPrest_SopLin" },
    { label: "Participación Cotizador", field: "Part_Cotizador" },
    { label: "Participación Gerente de Industria", field: "Part_GIndustria" },
    { label: "Participación Gerente de Propuesta", field: "Part_GPropuesta" },
    { label: "Participación Soporte de Línea", field: "Part_SopLin" },
    { label: "Pendiente Cotizador", field: "Pend_Cotizador" },
    { label: "Pendiente Gerente de Industria", field: "Pend_GIndustria" },
    { label: "Pendiente Gerente de Propuesta", field: "Pend_GPropuesta" },
    { label: "Pendiente Soporte de Línea", field: "Pend_SopLin" },
    { label: "Salario Cotizador", field: "Salario_Cotizador" },
    { label: "Salario Gerente de Industria", field: "Salario_GIndustria" },
    { label: "Salario Gerente de Propuesta", field: "Salario_GPropuesta" },
    { label: "Salario Soporte de Línea", field: "Salario_SopLin" },
    { label: "GIR Cotizador", field: "gir_Cotizador" },
    { label: "GIR Gerente de Industria", field: "gir_GIndustria" },
    { label: "GIR Soporte de Línea", field: "gir_SopLin" },
    { label: "Transporte Cotizador", field: "transporte_Cotizador" },
    { label: "Transporte Gerente de Industria", field: "transporte_GIndustria" },
    { label: "Transporte Gerente de Propuesta", field: "transporte_GPropuesta" },
    { label: "Transporte Soporte de Línea", field: "transporte_SopLin" },
    { label: "Viáticos Cotizador", field: "viaticos_Cotizador" },
    { label: "Viáticos Gerente de Industria", field: "viaticos_GIndustria" },
    { label: "Viáticos Gerente de Propuesta", field: "viaticos_GPropuesta" },
    { label: "Viáticos Soporte de Línea", field: "viaticos_SopLin" }
];

const popoverColumnsComisiones = [];

export  function ComisionesPage() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const { loading, Comisiones, DetalleComisiones, getComisiones, getDetalleComisiones, addComisiones} = useComisiones();
  const { Option } = Select

  useEffect(() => {
    getComisiones();
    getDetalleComisiones();
  }, [refetch]);

  const formik = useFormik({
    initialValues: initialValues(initialValues(Comisiones)),
    validationSchema: Yup.object(newSchema()),

    onSubmit: async (formValues) => {
      try {

        await addComisiones( formValues );

        onRefetch();
        message.success("Operación exitosa");
      } catch (error) {
        if (error?.message) {
          message.error(error.message);
        }
      }
    },
  });


  const styles = {
    overlayForm: {
      position: 'relative',
      zIndex: 10,
      padding: '10px',
      borderRadius: '5px',
    },
    width: '100%',
    margin: '0 auto',
  };

  const transformedDataComisiones = Comisiones && Comisiones.map(item => ({
    ...item,
    Total_costo: item.Total_costo ? `$${Number(item.Total_costo).toFixed(2)}` : '$0.00',
    Total_Factura: item.Total_Factura ? `$${Number(item.Total_Factura).toFixed(2)}` : '$0.00',
    Total_Pago: item.Total_Pago ? `$${Number(item.Total_Pago).toFixed(2)}` : '$0.00',
    porcentaje: item.porcentaje ? `${parseFloat(item.porcentaje).toFixed(2)}%` : '0%',
    utilidad: item.Total_Factura ? `${(1 - (item.Total_costo / item.Total_Factura)).toFixed(2)}%` : '0.00%'
  }));

  const transformedDataDetalleComisiones = DetalleComisiones && DetalleComisiones.map(item => ({
    ...item,
    total_pago: item.total_pago ? `$${Number(item.total_pago).toFixed(2)}` : '$0.00',
    total_factura: item.total_factura ? `$${Number(item.total_factura).toFixed(2)}` : '$0.00',
    costo_factura: item.costo_factura ? `$${Number(item.costo_factura).toFixed(2)}` : '$0.00',
    precio_unit_factura: item.precio_unit_factura ? `$${Number(item.precio_unit_factura).toFixed(2)}` : '$0.00',
    total_ordenventa: item.total_ordenventa ? `$${Number(item.total_ordenventa).toFixed(2)}` : '$0.00',
    precio_ordenventa: item.precio_ordenventa ? `$${Number(item.precio_ordenventa).toFixed(2)}` : '$0.00',

  }));


  return (
   <>
    <Form layout="vertical" onFinish={formik.handleSubmit} style={styles.overlayForm} >

        <Row gutter={12} >
            <Col span={4}>
            <Form.Item
                label=" "
                validateStatus={ formik.errors.anio && formik.touched.anio
                    ? "error": ""}
                help={ formik.touched.anio && formik.errors.anio 
                    ? formik.errors.anio : "" }
            >
                <DatePicker
                  selected={formik.values.anio ? new Date(formik.values.anio, 0, 1) : null}
                  onChange={(date) => formik.setFieldValue('anio', date ? date.getFullYear() : '')}
                  dateFormat="yyyy"
                  showYearPicker
                  autoComplete="off"
                  customInput={
                    <TextField
                      label="Año"
                      fullWidth
                      value={formik.values.anio ? formik.values.anio : ''}
                      autoComplete="off"
                    />
                  }
                />
            </Form.Item>
            </Col>

            <Col span={4}>
            <Form.Item
                label=" "
                validateStatus={ formik.errors.mes && formik.touched.mes
                    ? "error": ""}
                help={ formik.touched.mes && formik.errors.mes 
                    ? formik.errors.mes : "" }
            >
              <Select
                name="mes"
                value={formik.values.mes || "Seleccionar mes"}
                onChange={(value) => formik.setFieldValue('mes', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                style={{ height: '56px' }}
                defaultValue={1}
              >
                <Option value="ENERO">ENERO</Option>
                <Option value="FEBRERO">FEBRERO</Option>
                <Option value="MARZO">MARZO</Option>
                <Option value="ABRIL">ABRIL</Option>
                <Option value="MAYO">MAYO</Option>
                <Option value="JUNIO">JUNIO</Option>
                <Option value="JULIO">JULIO</Option>
                <Option value="AGOSTO">AGOSTO</Option>
                <Option value="SEPTIEMBRE">SEPTIEMBRE</Option>
                <Option value="OCTUBRE">OCTUBRE</Option>
                <Option value="NOVIEMBRE">NOVIEMBRE</Option>
                <Option value="DICIEMBRE">DICIEMBRE</Option>
              </Select>
            </Form.Item>
            </Col>

            <Col span={3}>
            <Form.Item
                label=" "
                validateStatus={ formik.errors.fecha_inicial && formik.touched.fecha_inicial
                    ? "error": ""}
                help={ formik.touched.fecha_inicial && formik.errors.fecha_inicial 
                    ? formik.errors.fecha_inicial : "" }
            >
              <DatePicker
                selected={formik.values.fecha_inicial ? new Date(formik.values.fecha_inicial + 'T00:00:00') : null}
                onChange={(date) => {
                  const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
                  formik.setFieldValue('fecha_inicial', formattedDate);
                }}
                dateFormat="yyyy-MM-dd"
                autoComplete="off"
                customInput={
                  <TextField
                    label="Fecha inicial"
                    fullWidth
                    value={formik.values.fecha_inicial || ''}
                    autoComplete="off"
                  />
                }
              />
            </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item
                  label=" "
                  validateStatus={ formik.errors.fecha_final && formik.touched.fecha_final
                      ? "error": ""}
                  help={ formik.touched.fecha_final && formik.errors.fecha_final 
                      ? formik.errors.fecha_final : "" }
              >
              <DatePicker
                selected={formik.values.fecha_final ? new Date(formik.values.fecha_final + 'T00:00:00') : null}
                onChange={(date) => {
                  const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
                  formik.setFieldValue('fecha_final', formattedDate);
                }}
                dateFormat="yyyy-MM-dd"
                autoComplete="off"
                customInput={
                  <TextField
                    label="Fecha final"
                    fullWidth
                    value={formik.values.fecha_final || ''}
                    autoComplete="off"
                  />
                }
              />
              </Form.Item>
            </Col>

            <Col span={9}>
              <Form.Item
                label=" "
                style={{ 
                  float: "right",
                  margin: 'auto',
                 }}
              >
                <Tooltip title="Consultar y guardar">
                  <Button type="primary" htmlType="submit" style={{ marginRight: '20px', top: '10px' }} > 
                    <CheckBoxOutlinedIcon />
                  </Button>
                </Tooltip>

                <Tooltip title="Limpiar campos">
                  <Button type="primary" danger style={{ top: '10px' }} onClick={formik.resetForm}>
                    <DeleteForeverOutlinedIcon />
                  </Button>
                </Tooltip>

                </Form.Item>
            </Col>
        </Row>

    </Form>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
        <h2>Comisiones</h2>
        <hr/>
        <br/>
        <TablaGeneral
          style={{ top: '100px' }}
          Data={transformedDataComisiones}
          columns={headersComisiones}
          ExcelColumns={headersComisiones}
          descarga={1}
          popoverColumns={popoverColumnsComisiones}
        />
        <br/>
        <h2>Detalle comisiones</h2>
        <hr/>
        <TablaGeneral
          style={{ top: '100px' }}
          Data={transformedDataDetalleComisiones}
          columns={headersDetalleComisiones}
          ExcelColumns={headersDetalleComisiones}
          descarga={1}
          popoverColumns={popoverColumnsDetalleComisiones}
        />
    </>

      )}
    </>
  )
}

// Función para inicializar los valores del formulario
  function initialValues(data) {
      return {
        anio: data?.anio || "",
        mes: data?.mes || "",
        fecha_inicial: data?.fecha_inicial || null,
        fecha_final: data?.fecha_final || null,
      };
    }
  
  // Función para definir el esquema de validación del formulario
  function newSchema() {
    return {
      anio: Yup.string().required("El campo es requerido"),
      mes: Yup.string().required("El campo es requerido"),
      fecha_inicial: Yup.date().required("El campo es requerido"),
      fecha_final: Yup.date().required("El campo es requerido"),
    };
  }