import { BASE_API } from "../../../utils/constants";
import { makeRequest } from "../../Request";

  
  // Función para obtener los datos de un Competence
  export async function getDifundidoaApi(token) {
    const url = `${BASE_API}/formularios-sig/api/DifundidoaGetTable/`;
    return makeRequest(url, "GET", token);
  }

  export async function getDifundidoaApiID(id, token) {
    const url = `${BASE_API}/formularios-sig/api/Difundidoa/${id}/`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo Competence
  export async function addDifundidoaApi(data, token) {
    const url = `${BASE_API}/formularios-sig/api/Difundidoa/`;
    return makeRequest(url, "POST", token, data);
  }

  // Funcion para enviar email a los difundidos
  export async function SendEmailApproversDifunidos(id, token) {
    const url = `${BASE_API}/formularios-sig/api/EmailAprobadores/${id}/Adifundidos`;
    return makeRequest(url, "GET", token)
  }

  // Funcion para enviar email al aprobadore
  export async function SendEmailApproverAprobador(id, token) {
    const url = `${BASE_API}/formularios-sig/api/EmailAprobadores/${id}/Aprobador`;
    return makeRequest(url, "GET", token)
  }
  
  // Función para actualizar los datos de un Competence existente
  export async function updateDifundidoaApi(id, data, token) {
    const url = `${BASE_API}/formularios-sig/api/Difundidoa/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar un Competence
  export async function deleteDifundidoaApi(id, token) {
    const url = `${BASE_API}/formularios-sig/api/Difundidoa/${id}/`;
    return makeRequest(url, "DELETE", token);
  }