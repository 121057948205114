import React, { useEffect } from "react";
import * as Yup from "yup";
import { Button, Col, Form, Input, Row, message, Select, Table } from "antd";
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { formStyle } from "../../../comons/styles";
import { useAccesoPrevencion } from "../../../../hooks/administrativa/formularios-sig/useAccesoPrevencion";
import { initialValuesAccesoPrevencion } from "../components/initialValues";

export function AccesoPrevencionForm(props) {

  const { updateAccesoPrevencion, cancelLoader, loading } = useAccesoPrevencion();
  const { dataTipoFormulario, data, handleNext, handlePrev, id, setnewdataAccesoPrevencion } = props;
  const { Option } = Select;

  useEffect(() => {
    cancelLoader();
  }, [data])
  
  const formik = useFormik({
    initialValues: initialValuesAccesoPrevencion(id.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,

    onSubmit: async (formValues) => {
      try {
        await updateAccesoPrevencion(id.Id_accesoprevencion, formValues);
        message.success("Actualización exitosa (Acceso y prevención)");
        setnewdataAccesoPrevencion(formValues)
        handleNext();
      } catch (error) {
        message.error("Error en la operación (Acceso y prevención)");
      }
    },
  });

  return (
    <>
    {loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </div>
    ) : (
      <Form layout="vertical" onFinish={formik.handleSubmit} style={formStyle}>
      <Row gutter={16}><Col span={12} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Sistema de acceso y prevención</Col></Row>
      <hr/>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Escalera de tijera"
              validateStatus={formik.errors.escaleratijera && formik.touched.escaleratijera ? "error" : ""}
              help={formik.touched.escaleratijera && formik.errors.escaleratijera ? formik.errors.escaleratijera : ""}
          >
            <Select
                name="escaleratijera"
                value={formik.values.escaleratijera}
                onChange={(value) => formik.setFieldValue('escaleratijera', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Escalera de extensión (1, 2 o 3 cuerpos)"
              validateStatus={formik.errors.escaleraextension && formik.touched.escaleraextension ? "error" : ""}
              help={formik.touched.escaleraextension && formik.errors.escaleraextension ? formik.errors.escaleraextension : ""}
          >
            <Select
                name="escaleraextension"
                value={formik.values.escaleraextension}
                onChange={(value) => formik.setFieldValue('escaleraextension', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Escalera de plataforma"
              validateStatus={formik.errors.escaleraplataforma && formik.touched.escaleraplataforma ? "error" : ""}
              help={formik.touched.escaleraplataforma && formik.errors.escaleraplataforma ? formik.errors.escaleraplataforma : ""}
          >
            <Select
                name="escaleraplataforma"
                value={formik.values.escaleraplataforma}
                onChange={(value) => formik.setFieldValue('escaleraplataforma', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Andamio de carga o autosoportado"
              validateStatus={formik.errors.andamiocarga && formik.touched.andamiocarga ? "error" : ""}
              help={formik.touched.andamiocarga && formik.errors.andamiocarga ? formik.errors.andamiocarga : ""}
          >
            <Select
                name="andamiocarga"
                value={formik.values.andamiocarga}
                onChange={(value) => formik.setFieldValue('andamiocarga', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Andamio para pozo"
              validateStatus={formik.errors.andamiopozo && formik.touched.andamiopozo ? "error" : ""}
              help={formik.touched.andamiopozo && formik.errors.andamiopozo ? formik.errors.andamiopozo : ""}
          >
            <Select
                name="andamiopozo"
                value={formik.values.andamiopozo}
                onChange={(value) => formik.setFieldValue('andamiopozo', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Elevadores Hidráulicos - Mecánico - Eléctricos"
              validateStatus={formik.errors.elevadores && formik.touched.elevadores ? "error" : ""}
              help={formik.touched.elevadores && formik.errors.elevadores ? formik.errors.elevadores : ""}
          >
            <Select
                name="elevadores"
                value={formik.values.elevadores}
                onChange={(value) => formik.setFieldValue('elevadores', value)} // Actualiza el valor en formik
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="Si">Si</Option>
                <Option value="No">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      { dataTipoFormulario !== 'PT Confinado' &&  (
      <>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Barandas"
              validateStatus={formik.errors.barandas && formik.touched.barandas ? "error" : ""}
              help={formik.touched.barandas && formik.errors.barandas ? formik.errors.barandas : ""}
          >
            <Select
                name="barandas"
                value={formik.values.barandas ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('barandas', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Conos - Balizas"
              validateStatus={formik.errors.conos && formik.touched.conos ? "error" : ""}
              help={formik.touched.conos && formik.errors.conos ? formik.errors.conos : ""}
          >
            <Select
                name="conos"
                value={formik.values.conos ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('conos', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Cadenas"
              validateStatus={formik.errors.cadenas && formik.touched.cadenas ? "error" : ""}
              help={formik.touched.cadenas && formik.errors.cadenas ? formik.errors.cadenas : ""}
          >
            <Select
                name="cadenas"
                value={formik.values.cadenas ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('cadenas', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Cerramiento de accesos"
              validateStatus={formik.errors.cerramientos && formik.touched.cerramientos ? "error" : ""}
              help={formik.touched.cerramientos && formik.errors.cerramientos ? formik.errors.cerramientos : ""}
          >
            <Select
                name="cerramientos"
                value={formik.values.cerramientos ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('cerramientos', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Cintas reflectivas"
              validateStatus={formik.errors.cinta && formik.touched.cinta ? "error" : ""}
              help={formik.touched.cinta && formik.errors.cinta ? formik.errors.cinta : ""}
          >
            <Select
                name="cinta"
                value={formik.values.cinta ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('cinta', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Avisos informativos"
              validateStatus={formik.errors.avisos && formik.touched.avisos ? "error" : ""}
              help={formik.touched.avisos && formik.errors.avisos ? formik.errors.avisos : ""}
          >
            <Select
                name="avisos"
                value={formik.values.avisos ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('avisos', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      </>
      )}

      { dataTipoFormulario !== 'PT Alturas' &&  (
      <>
      <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Sistemas de prevención</Col></Row>
      <hr/>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Rotación del personal"
              validateStatus={formik.errors.rotacionpersonal && formik.touched.rotacionpersonal ? "error" : ""}
              help={formik.touched.rotacionpersonal && formik.errors.rotacionpersonal ? formik.errors.rotacionpersonal : ""}
          >
            <Select
                name="rotacionpersonal"
                value={formik.values.rotacionpersonal ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('rotacionpersonal', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Señalización del área"
              validateStatus={formik.errors.senalizacion && formik.touched.senalizacion ? "error" : ""}
              help={formik.touched.senalizacion && formik.errors.senalizacion ? formik.errors.senalizacion : ""}
          >
            <Select
                name="senalizacion"
                value={formik.values.senalizacion ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('senalizacion', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Delimitación del área"
              validateStatus={formik.errors.delimitacion && formik.touched.delimitacion ? "error" : ""}
              help={formik.touched.delimitacion && formik.errors.delimitacion ? formik.errors.delimitacion : ""}
          >
            <Select
                name="delimitacion"
                value={formik.values.delimitacion ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('delimitacion', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={10}>
          <Form.Item
              label="Procedimiento específico en espacios confinados"
              validateStatus={formik.errors.procedimientoespacios && formik.touched.procedimientoespacios ? "error" : ""}
              help={formik.touched.procedimientoespacios && formik.errors.procedimientoespacios ? formik.errors.procedimientoespacios : ""}
          >
            <Select
                name="procedimientoespacios"
                value={formik.values.procedimientoespacios ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('procedimientoespacios', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
              label="Control de acceso"
              validateStatus={formik.errors.controlacceso && formik.touched.controlacceso ? "error" : ""}
              help={formik.touched.controlacceso && formik.errors.controlacceso ? formik.errors.controlacceso : ""}
          >
            <Select
                name="controlacceso"
                value={formik.values.controlacceso ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('controlacceso', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
              label="Bloqueo y etiquetado"
              validateStatus={formik.errors.bloqueo && formik.touched.bloqueo ? "error" : ""}
              help={formik.touched.bloqueo && formik.errors.bloqueo ? formik.errors.bloqueo : ""}
          >
            <Select
                name="bloqueo"
                value={formik.values.bloqueo ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('bloqueo', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

     { dataTipoFormulario !== 'PT Confinado' &&  (
     <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
              label="Análisis de peligros por actividad (APA) SECCIÓN F"
              validateStatus={formik.errors.analisispeligros && formik.touched.analisispeligros ? "error" : ""}
              help={formik.touched.analisispeligros && formik.errors.analisispeligros ? formik.errors.analisispeligros : ""}
          >
            <Select
                name="analisispeligros"
                value={formik.values.analisispeligros ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('analisispeligros', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      </>
     )}
      <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Protección atmosferica</Col></Row>
      <hr/>
      <Row gutter={24}>
        <Col span={10}>
          <Form.Item
              label="Deficiencia de Oxígeno"
              validateStatus={formik.errors.deficienciaoxigeno && formik.touched.deficienciaoxigeno ? "error" : ""}
              help={formik.touched.deficienciaoxigeno && formik.errors.deficienciaoxigeno ? formik.errors.deficienciaoxigeno : ""}
          >
            <Select
                name="deficienciaoxigeno"
                value={formik.values.deficienciaoxigeno ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('deficienciaoxigeno', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
              label="Enriquesida de oxígeno"
              validateStatus={formik.errors.enriquesidaoxigeno && formik.touched.enriquesidaoxigeno ? "error" : ""}
              help={formik.touched.enriquesidaoxigeno && formik.errors.enriquesidaoxigeno ? formik.errors.enriquesidaoxigeno : ""}
          >
            <Select
                name="enriquesidaoxigeno"
                value={formik.values.enriquesidaoxigeno ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('enriquesidaoxigeno', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
              label="Gases combustibles"
              validateStatus={formik.errors.gasescombustibles && formik.touched.gasescombustibles ? "error" : ""}
              help={formik.touched.gasescombustibles && formik.errors.gasescombustibles ? formik.errors.gasescombustibles : ""}
          >
            <Select
                name="gasescombustibles"
                value={formik.values.gasescombustibles ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('gasescombustibles', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Gases tóxicos"
              validateStatus={formik.errors.gasestoxicos && formik.touched.gasestoxicos ? "error" : ""}
              help={formik.touched.gasestoxicos && formik.errors.gasestoxicos ? formik.errors.gasestoxicos : ""}
          >
            <Select
                name="gasestoxicos"
                value={formik.values.gasestoxicos ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('gasestoxicos', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Inflamabilidad <10%LEL/LFL"
              validateStatus={formik.errors.inflamabilidad && formik.touched.inflamabilidad ? "error" : ""}
              help={formik.touched.inflamabilidad && formik.errors.inflamabilidad ? formik.errors.inflamabilidad : ""}
          >
            <Input
                name="inflamabilidad"
                value={formik.values.inflamabilidad}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="H2S <10ppm"
              validateStatus={formik.errors.h2s && formik.touched.h2s ? "error" : ""}
              help={formik.touched.h2s && formik.errors.h2s ? formik.errors.h2s : ""}
          >
            <Input
                name="h2s"
                value={formik.values.h2s}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
              label="Calor (°F/°C)"
              validateStatus={formik.errors.calor && formik.touched.calor ? "error" : ""}
              help={formik.touched.calor && formik.errors.calor ? formik.errors.calor : ""}
          >
            <Input
                name="calor"
                value={formik.values.calor}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Medio de comunicación"
              validateStatus={formik.errors.mediocomuinicacion && formik.touched.mediocomuinicacion ? "error" : ""}
              help={formik.touched.mediocomuinicacion && formik.errors.mediocomuinicacion ? formik.errors.mediocomuinicacion : ""}
          >
            <Input
                name="mediocomuinicacion"
                value={formik.values.mediocomuinicacion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
              label="Conoce el procedimiento de evacuación"
              validateStatus={formik.errors.procedimientoevacua && formik.touched.procedimientoevacua ? "error" : ""}
              help={formik.touched.procedimientoevacua && formik.errors.procedimientoevacua ? formik.errors.procedimientoevacua : ""}
          >
            <Select
                name="procedimientoevacua"
                value={formik.values.procedimientoevacua ? "true" : "false"}
                onChange={(value) => {
                    const booleanValue = value === "true";
                    formik.setFieldValue('procedimientoevacua', booleanValue);
                }}
                onBlur={formik.handleBlur}
                placeholder="Selecciona una opción"
            >
                <Option value="true">Si</Option>
                <Option value="false">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      </>
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="primary" htmlType="button" onClick={handlePrev}>
            Anterior
          </Button>
          <Button type="primary" htmlType="submit">
            Actualizar
          </Button>
      </div>
    </Form>
    )}
    </>
  );
}


export function AccesoPrevencionFullView(props) {
  const { cancelLoader, loading } = useAccesoPrevencion();
  const { dataTipoFormulario, data } = props;

  useEffect(() => {
    cancelLoader();
  }, [data]);

  const formik = useFormik({
    initialValues: initialValuesAccesoPrevencion(data.Id_formulario, data),
    //validationSchema: analisisTrabajoSchema,
  });

  const columns = [
    { dataIndex: 'campo1', key: 'campo1', width: '33%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
    { dataIndex: 'campo2', key: 'campo2', width: '33%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
    { dataIndex: 'campo3', key: 'campo3', width: '33%', render: text => <div style={{ fontSize: '12px' }}>{text}</div> },
  ];

  const dataSource = [
    {
      key: '1',
      campo1: 'Escalera de tijera',
      campo2: 'Escalera de extensión (1, 2 o 3 cuerpos)',
      campo3: 'Escalera de plataforma',
    },
    {
      key: '2',
      campo1: formik.values.escaleratijera,
      campo2: formik.values.escaleraextension,
      campo3: formik.values.escaleraplataforma,
    },
    {
      key: '3',
      campo1: 'Andamio de carga o autosoportado',
      campo2: 'Andamio para pozo',
      campo3: 'Elevadores Hidráulicos - Mecánico - Eléctricos',
    },
    {
      key: '4',
      campo1: formik.values.andamiocarga,
      campo2: formik.values.andamiopozo,
      campo3: formik.values.elevadores,
    },
    {
      key: '5',
      campo1: 'Barandas',
      campo2: 'Conos - Balizas',
      campo3: 'Cadenas',
    },
    {
      key: '6',
      campo1: formik.values.barandas,
      campo2: formik.values.conos,
      campo3: formik.values.cadenas,
    },
    {
      key: '7',
      campo1: 'Cerramiento de accesos',
      campo2: 'Cintas reflectivas',
      campo3: 'Avisos informativos',
    },
    {
      key: '8',
      campo1: formik.values.cerramientos,
      campo2: formik.values.cinta,
      campo3: formik.values.avisos,
    },
    {
      key: '9',
      campo1: 'Rotación del personal',
      campo2: 'Señalización del área',
      campo3: 'Delimitación del área',
    },
    {
      key: '10',
      campo1: formik.values.rotacionpersonal ? 'Sí' : 'No',
      campo2: formik.values.senalizacion ? 'Sí' : 'No',
      campo3: formik.values.delimitacion ? 'Sí' : 'No',
    },
    {
      key: '11',
      campo1: 'Procedimiento específico en espacios confinados',
      campo2: 'Control de acceso',
      campo3: 'Bloqueo y etiquetado',
    },
    {
      key: '12',
      campo1: formik.values.procedimientoespacios ? 'Sí' : 'No',
      campo2: formik.values.controlacceso ? 'Sí' : 'No',
      campo3: formik.values.bloqueo ? 'Sí' : 'No',
    },
    {
      key: '13',
      campo1: 'Análisis de peligros por actividad (APA) SECCIÓN F',
    },
    {
      key: '14',
      campo1: formik.values.analisispeligros ? 'Sí' : 'No',
    },
    {
      key: '15',
      campo1: 'Deficiencia de Oxígeno',
      campo2: 'Enriquecida de oxígeno',
      campo3: 'Gases combustibles',
    },
    {
      key: '16',
      campo1: formik.values.deficienciaoxigeno ? 'Sí' : 'No',
      campo2: formik.values.enriquesidaoxigeno ? 'Sí' : 'No',
      campo3: formik.values.gasescombustibles ? 'Sí' : 'No',
    },
    {
      key: '17',
      campo1: 'Gases tóxicos',
      campo2: 'Inflamabilidad <10%LEL/LFL',
      campo3: 'H2S <10ppm',
    },
    {
      key: '18',
      campo1: formik.values.gasestoxicos ? 'Sí' : 'No',
      campo2: formik.values.inflamabilidad,
      campo3: formik.values.h2s,
    },
    {
      key: '19',
      campo1: 'Calor (°F/°C)',
      campo2: 'Medio de comunicación',
      campo3: 'Conoce el procedimiento de evacuación',
    },
    {
      key: '20',
      campo1: formik.values.calor,
      campo2: formik.values.mediocomuinicacion,
      campo3: formik.values.procedimientoevacua ? 'Sí' : 'No',
    },
  ];

  const getRowClassName = (record) => {
    const grayKeys = ['1', '3', '5', '7', '9', '11', '13', '15', '17', '19']; // Claves para las filas que tendrán fondo gris
    return grayKeys.includes(record.key) ? 'gray-background' : '';
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Form layout="vertical" style={formStyle}>
          <Row gutter={16}>
            <Col span={12} style={{ fontSize: '20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)' }}>
              Sistema de acceso y prevención
            </Col>
          </Row>
          <hr />
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            showHeader={false}
            bordered
            rowClassName={getRowClassName}
            className="table-narrow"
          />
        </Form>
      )}
    </>
  );
}

// export function AccesoPrevencionFullView(props){
//   const { cancelLoader, loading } = useAccesoPrevencion();
//   const { dataTipoFormulario, data } = props;

//   useEffect(() => {
//     cancelLoader();
//   }, [data])
  
//   const formik = useFormik({
//     initialValues: initialValuesAccesoPrevencion(data.Id_formulario, data),
//     //validationSchema: analisisTrabajoSchema,
//   });

//   return (
//     <>
//     {loading ? (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     ) : (
//       <Form layout="vertical" style={formStyle}>
//       <Row gutter={16}><Col span={12} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Sistema de acceso y prevención</Col></Row>
//       <hr/>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Escalera de tijera" >
//             <Input
//                 name="escaleratijera"
//                 value={formik.values.escaleratijera}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Escalera de extensión (1, 2 o 3 cuerpos)" >
//             <Input
//                 name="escaleraextension"
//                 value={formik.values.escaleraextension}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Escalera de plataforma" >
//             <Input
//                 name="escaleraplataforma"
//                 value={formik.values.escaleraplataforma}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Andamio de carga o autosoportado" >
//             <Input
//                 name="andamiocarga"
//                 value={formik.values.andamiocarga}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Andamio para pozo" >
//             <Input
//                 name="andamiopozo"
//                 value={formik.values.andamiopozo}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Elevadores Hidráulicos - Mecánico - Eléctricos" >
//             <Input
//                 name="elevadores"
//                 value={formik.values.elevadores}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
//       { dataTipoFormulario !== 'PT Confinado' &&  (
//       <>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Barandas" >
//             <Input
//                 name="barandas"
//                 value={formik.values.barandas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Conos - Balizas" >
//             <Input
//                 name="conos"
//                 value={formik.values.conos}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Cadenas" >
//             <Input
//                 name="cadenas"
//                 value={formik.values.cadenas}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Cerramiento de accesos" >
//             <Input
//                 name="cerramientos"
//                 value={formik.values.cerramientos}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Cintas reflectivas" >
//             <Input
//                 name="cinta"
//                 value={formik.values.cinta}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Avisos informativos" >
//             <Input
//                 name="avisos"
//                 value={formik.values.avisos}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
//       </>
//       )}

//       { dataTipoFormulario !== 'PT Alturas' &&  (
//       <>
//       <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Sistemas de prevención</Col></Row>
//       <hr/>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Rotación del personal" >
//             <Input
//                 name="rotacionpersonal"
//                 value={formik.values.rotacionpersonal ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Señalización del área" >
//             <Input
//                 name="senalizacion"
//                 value={formik.values.senalizacion ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Delimitación del área" >
//             <Input
//                 name="delimitacion"
//                 value={formik.values.delimitacion ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={24}>
//         <Col span={10}>
//           <Form.Item label="Procedimiento específico en espacios confinados" >
//             <Input
//                 name="procedimientoespacios"
//                 value={formik.values.procedimientoespacios ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={7}>
//           <Form.Item label="Control de acceso" >
//             <Input
//                 name="controlacceso"
//                 value={formik.values.controlacceso ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={7}>
//           <Form.Item label="Bloqueo y etiquetado" >
//             <Input
//                 name="bloqueo"
//                 value={formik.values.bloqueo ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//      { dataTipoFormulario !== 'PT Confinado' &&  (
//      <>
//       <Row gutter={16}>
//         <Col span={12}>
//           <Form.Item label="Análisis de peligros por actividad (APA) SECCIÓN F" >
//             <Input
//                 name="analisispeligros"
//                 value={formik.values.analisispeligros ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
//       </>
//      )}
//       <Row gutter={16}><Col span={8} style={{fontSize:'20px', fontWeight: 'bold', color: 'rgb(204, 48, 43)'}}>Protección atmosferica</Col></Row>
//       <hr/>
//       <Row gutter={24}>
//         <Col span={10}>
//           <Form.Item label="Deficiencia de Oxígeno" >
//             <Input
//                 name="deficienciaoxigeno"
//                 value={formik.values.deficienciaoxigeno ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={7}>
//           <Form.Item label="Enriquesida de oxígeno" >
//             <Input
//                 name="enriquesidaoxigeno"
//                 value={formik.values.enriquesidaoxigeno ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={7}>
//           <Form.Item label="Gases combustibles" >
//             <Input
//                 name="gasescombustibles"
//                 value={formik.values.gasescombustibles ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Gases tóxicos" >
//             <Input
//                 name="gasestoxicos"
//                 value={formik.values.gasestoxicos ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Inflamabilidad <10%LEL/LFL" >
//             <Input
//                 name="inflamabilidad"
//                 value={formik.values.inflamabilidad}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="H2S <10ppm" >
//             <Input
//                 name="h2s"
//                 value={formik.values.h2s}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>

//       <Row gutter={16}>
//         <Col span={8}>
//           <Form.Item label="Calor (°F/°C)" >
//             <Input
//                 name="calor"
//                 value={formik.values.calor}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Medio de comunicación" >
//             <Input
//                 name="mediocomuinicacion"
//                 value={formik.values.mediocomuinicacion}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//         <Col span={8}>
//           <Form.Item label="Conoce el procedimiento de evacuación" >
//             <Input
//                 name="procedimientoevacua"
//                 value={formik.values.procedimientoevacua ? 'Si' : 'No'}
//                 readOnly
//             />
//           </Form.Item>
//         </Col>
//       </Row>
//       </>
//       )}
//     </Form>
//     )}
//     </>
//   );
// }