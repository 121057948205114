import { useState } from "react";
import {
  getLeadsApi,
  addLeadsApi,
  updateLeadsApi,
  deleteLeadsApi,
} from "../Api/Lead";  

import { useAuth } from "./useAuth";

export function useLeads() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    Leads: null,
  });

  const { loading, error, Leads } = state;
  const { auth } = useAuth();

  const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  };

  const getLeads = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getLeadsApi(auth.token);
      handleResponse(response);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        Leads: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addLeads = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addLeadsApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateLeads = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateLeadsApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteLeads = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteLeadsApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    Leads,
    getLeads,
    addLeads,
    updateLeads,
    deleteLeads,
  };
}