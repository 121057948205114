import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { useUsersAD } from '../../hooks/useUserAD';
import { Navigate } from 'react-router-dom';
import './FormResetPass.css';

export function FormResetPass() {
  const { updatePassword } = useUsersAD();
  const [visible, setVisible] = useState(true);
  const [homeredirect, setHomeRedirect] = useState(false);
  const { auth } = useAuth();
  const [form] = Form.useForm();
  const id_user = auth.me?.id_user;


  const handleSubmit = async () => {
    message.loading("Actualizando la contraseña.....")
    try {
      const values = await form.validateFields();
      const { success, mensaje } = await updatePassword(id_user, values.password, values.confirmPassword);

      if (success) {
        message.success(mensaje);
        setVisible(false);
        setHomeRedirect(true);
        form.resetFields();
      }
    } catch (error) {
      message.error('Hubo un problema al actualizar la contraseña. Por favor, inténtalo de nuevo más tarde.');
    }
  };

  if(homeredirect){
    return <Navigate to="/home"/>
  }

  return (
    <>

      {visible &&  <div className="modal-background"></div>}

      {/* Modal para editar contraseña */}
      <Modal
        title={`Porfavor para continuar, cambia tu contraseña`}
        open={visible}
        cancelButtonProps={false}
        closeIcon={false}
        footer={[
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="password"
            label="Nueva Contraseña"
            rules={[
              { required: true, message: 'Por favor ingrese la nueva contraseña' },
              { min: 8, message: 'La contraseña debe tener al menos 8 caracteres' },
              { pattern: /[a-z]/, message: 'La contraseña debe tener al menos una letra minúscula' },
              { pattern: /[A-Z]/, message: 'La contraseña debe tener al menos una letra mayúscula' },
              { pattern: /\d/, message: 'La contraseña debe tener al menos un número' },
              { pattern: /[^a-zA-Z\d]/, message: 'La contraseña debe tener al menos un carácter especial' },
            ]}
          >
            <Input.Password placeholder="Nueva contraseña" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirmar Contraseña"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Por favor confirme la nueva contraseña' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Las contraseñas no coinciden');
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirmar contraseña" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
