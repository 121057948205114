import React, { useEffect, useCallback, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useSyzia } from "../../hooks/syzia/usesyzia";
import { TablaGeneral } from "../comons/Tables/TablaSyzia";
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "antd";
import { UpdateConsultaForm } from "./form";

const headersComisiones = [
    { label: "ID", field: "id_consulta" },
    { label: "Fecha Consulta", field: "fecha_consulta" },
    { label: "Usuario", field: "usuario" },
    { label: "Pregunta inicial", field: "pregunta" }
  ];
  

const popoverColumns = [
    { label: "", field: "contenido" }
]


export function SyziaTable(props) {
    const { setRecoveryData, setActiveTab } = props;
    const { loading, Consultas, getConsulta, Respuesta} = useSyzia();
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
    const [refetch, setRefetch] = useState(false);
    const [showStatusModal, setStatusModal] = useState(false);
    const [contentStatusModal,  setContentStatusModal] = useState(null);
    const openCloseStatusModal = useCallback(() => setStatusModal((prev) => !prev), []);
    const  { auth } = useAuth();
    const userEmail = auth.me?.email;

    const formatContent = (messages) => {
        return messages.map((msg, index) => {
            
        // Determina si el mensaje actual es el último en el array
        const isLastMessage = index === messages.length - 1;
        // Determina si se debe mostrar una línea horizontal después del mensaje actual
        const showHr = !isLastMessage && messages[index + 1]?.role === 'user';
    
            return (
                <div key={index}>
                    {msg.role === 'user' && (
                        <>
                            <strong>Pregunta:</strong>
                            <p>{msg.content}</p>
                        </>
                    )}
                    {msg.role === 'assistant' && (
                        <>
                            <strong>Respuesta:</strong>
                            <p>{msg.content}</p>
                        </>
                    )}
                    {showHr && <hr />}
                </div>
            );
        });
    };
    
    const transformData = Consultas?.map((item) => {
        const messages = Array.isArray(item.consulta) ? item.consulta : [];
    
        return {
            ...item,
            pregunta: item.consulta[1].content,
            contenido: formatContent(messages),
        };
    });
    
      
    useEffect(() => {
      getConsulta(userEmail);
    }, [refetch]);

    const handleConsultForm = useCallback(
        (data) => {
            setActiveTab('1');  // Cambia la pestaña activa a la primera
            setRecoveryData(data);  // Asegúrate de que esta función sea llamada para actualizar el estado
        },
        [setActiveTab]
    );


    // const handleConsultForm = useCallback(
    //     (data) => {
    //         //setRecoveryData(data)
    //         setActiveTab('1')
    //     //   setContentStatusModal(
    //     //     <UpdateConsultaForm
    //     //       onClose={openCloseStatusModal}
    //     //       onRefetch={onRefetch}
    //     //       data={data}
    //     //     />
    //     //   );
    //     //   openCloseStatusModal();
    //     },
    //     []
    //   );

    return (
        <>
            {loading ? (
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
                >
                <CircularProgress />
                </div>
            ) : (
            <>
            <br/>
                <TablaGeneral
                    style={{ top: '100px' }}
                    Data={transformData}
                    columns={headersComisiones}
                    popoverColumns={popoverColumns}
                    handleSendNewConsult={handleConsultForm}
                />
            </>

            )}


        <Modal
            style={{
                top: 20,
            }}
            open={showStatusModal}
            onCancel={() => {openCloseStatusModal();}}
            footer={null}
            width="500px"
            title="Continuar con la consulta"
            destroyOnClose
            centered
            cancelButtonProps={false}
            
        >
            {contentStatusModal}
        </Modal>
        </>
    )
  }
  