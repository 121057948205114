import { useState } from "react";
import { 
    getQuienElaboraApi,
    addQuienElaboraApi,
    updateQuienElaboraApi,
    deleteQuienElaboraApi,
    getQuienElaboraApiID,
} from "../../../Api/administrativa/formularios-sig/quien-elabora";

import { useAuth } from "../../useAuth";

export function useQuienElabora() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    QuienElabora: null,
  });

  const { loading, error, QuienElabora } = state;
  const { auth } = useAuth();

/*   const handleResponse = (response) => {
    if (response.status !== 200) {
      console.log("incorrecto");
    } else {
      console.log("Correcto");
    }
  }; */

  const getQuienElabora = async () => {
    
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await  getQuienElaboraApi(auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        QuienElabora: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getQuienElaboraID = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getQuienElaboraApiID(id, auth.token);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        QuienElabora: result,
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addQuienElabora = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addQuienElaboraApi(data, auth.token);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateQuienElabora = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateQuienElaboraApi(id, data, auth.token);
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteQuienElabora = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteQuienElaboraApi(id, auth.token);
     
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const cancelLoader = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    QuienElabora,
    getQuienElabora,
    addQuienElabora,
    updateQuienElabora,
    deleteQuienElabora,
    getQuienElaboraID,
    cancelLoader,
  };
}