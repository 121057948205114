import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";

// Función para obtener los datos de un Comercial
export async function getComercialApi(token) {
    const url = `${BASE_API}/comercial/api/comercial/`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo Comercial
  export async function addComercialApi(data, token) {
    const url = `${BASE_API}/comercial/api/comercial/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos de un Comercial existente
  export async function updateComercialApi(id, data, token) {
    const url = `${BASE_API}/comercial/api/comercial/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar un Comercial
  export async function deleteComercialApi(id, token) {
    const url = `${BASE_API}/comercial/api/comercial/${id}/`;
    return makeRequest(url, "DELETE", token);
  }
  