import { alignProperty } from "@mui/material/styles/cssUtils";
import styled from "styled-components";

export const ContainerPopover = styled.div`
  width: 400px;
`;
export const ContainerContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;
export const ContainerFlex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TextBold = styled.p`
  font-size: 12px;
  font-weight: 700;
  margin: 0;
`;
export const SpanNormal = styled.span`
  font-size: 12px;
  font-weight: 400;
`;
export const TextCrudo = styled.p`
  color: #100db1;
  font-size: 14px;
  margin: 0;
`;

export const Circle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
`;

// Estado -1 (Cerrado)
export const CircleCerrado = styled(Circle)`
  background-color: #000;
`;

// Estado -2 (Pendiente)
export const CirclePendiente = styled(Circle)`
  background-color: #aaa;
`;

// Estado -3 (Abierto)
export const CircleAbierto = styled(Circle)`
  background-color: #f00;
`;

export const cellStyle = {
  fontSize: '12px',
  fontFamily: 'Noto, sans-serif',
  textAlign: 'center',
};

export const cellStyleComisiones = {
  fontSize: '10.5px',
  fontFamily: 'Noto, sans-serif',
  textAlign: 'center',
};

export const cellStyleSig = {
  fontSize: '12px',
  fontFamily: 'Noto, sans-serif',
  width: '230px',
};

export const formStyle = {
  width: '100%', // Ajusta el valor según tus preferencias
  margin: '0 auto',
};

export const buttonStyle = {
  backgroundColor: '#CC302B',
  color: 'white',
};