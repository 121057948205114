import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../hooks/useAuth";
import { TablaGeneral } from "../../components/comons/TablaGeneral";
import { Modal } from "antd";
import { useSatisfactionSurveys } from "../../hooks/useSatisfactionSurveys";
import { SatisfactionSurveysForm } from "../../components/Marketing/SatisfactionSurveysForm";

const headers = [
  { label: "ID", field: "id_encuesta" },
  { label: "Empresas", field: "empresas" },
  { label: "Nombres y Apellidos", field: "nombres_apellidos" },
  { label: "Correo", field: "correo" },
  { label: "Fecha del Documento", field: "fecha_documento" },
];

const popoverColumns = [
  { label: "ID", field: "id_encuesta" },
  { label: "Empresas", field: "empresas" },
  { label: "Nombres y Apellidos", field: "nombres_apellidos" },
  { label: "Correo", field: "correo" },
  { label: "Fecha del Documento", field: "fecha_documento" },
  { label: "¿Cómo percibe la actitud de servicio del personal que lo atendió?", field: "p1" },
  { label: "¿Los productos adquiridos cumplieron sus expectativas?", field: "p2" },
  { label: "¿Fue informado del estado de sus solicitudes y/o pedidos?", field: "p3" },
  { label: "¿Recibió los productos en los tiempos de entrega pactados?", field: "p4" },
  { label: "¿Las condiciones de entrega y empaque fueron adecuadas?", field: "p5" },
  { label: "¿Se cumplieron los requisitos exigidos por su empresa para la facturación de mercancía?", field: "p6" },
  { label: "¿Siente respaldo de SYZ una vez finalizada su compra?", field: "p7" },
  { label: "¿Como percibe la relación beneficio - costo del producto adquirido?", field: "p8" },
  { label: "¿Qué tan difícil o fácil fue resolver su solicitud con SYZ?", field: "p9" },
  { label: "¿Qué probabilidades hay de que nos recomiendes con tus conocidos?", field: "p10" },
  { label: "¿Tiene usted algún comentario, felicitación, queja o reclamo respecto al servicio?", field: "p11" },
];

export function SatisfactionSurveysPage() {
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { auth } = useAuth();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [contentModal, setContentModal] = useState(null);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const {
    loading,
    SatisfactionSurveys,
    getSatisfactionSurveys,
    deleteSatisfactionSurveys,
  } = useSatisfactionSurveys();

  useEffect(() => {
    getSatisfactionSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  const handleAdd = useCallback(() => {
    setContentModal(
      <SatisfactionSurveysForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setContentModal(
        <SatisfactionSurveysForm
          onClose={openCloseModal}
          onRefetch={onRefetch}
          SatisfactionSurveys={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    (data) => {
      deleteSatisfactionSurveys(data.id_encuesta);
      onRefetch();
    },
    [deleteSatisfactionSurveys, onRefetch]
  );
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
        {(auth.me?.marketing_full_modify) && (
        <TablaGeneral
          Data={SatisfactionSurveys}
          columns={headers}
          popoverColumns={popoverColumns}
          handleAgregarData={handleAdd}
          handleEditarData={handleUpdate}
          handleEliminarData={handleDelete}
        />
        )}

        {(auth.me?.marketing_only_view && !auth.me?.marketing_full_modify) && (
        <TablaGeneral
          Data={SatisfactionSurveys}
          columns={headers}
          popoverColumns={popoverColumns}
        />
        )}
        </>
        
      )}
      <Modal
        open={showModal}
        onCancel={openCloseModal}
        footer={null}
        width="70%"
        title="Encuestas de satisfaccion"
        destroyOnClose={true} // Esto destruirá el contenido del modal cuando se cierre
      >
        {contentModal}
      </Modal>
    </>
  );
}
