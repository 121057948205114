import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";


  
// Función para obtener los datos de un SurveysOrdersInvoices
export async function getSurveysOrdersInvoicesApi(token) {
    const url = `${BASE_API}/encuestas_pedidos_facturas/api`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo SurveysOrdersInvoices
  export async function addSurveysOrdersInvoicesApi(data, token) {
    const url = `${BASE_API}/encuestas_pedidos_facturas/api/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos de un SurveysOrdersInvoices existente
  export async function updateSurveysOrdersInvoicesApi(id, data, token) {
    const url = `${BASE_API}/encuestas_pedidos_facturas/api/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar un SurveysOrdersInvoices
  export async function deleteSurveysOrdersInvoicesApi(id, token) {
    const url = `${BASE_API}/encuestas_pedidos_facturas/api/${id}/`;
    return makeRequest(url, "DELETE", token);
  }
  