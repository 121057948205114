import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";


  
// Función para obtener los datos de un SatisfactionSurveys
export async function getSatisfactionSurveysApi(token) {
    const url = `${BASE_API}/encuestas_satisfaccion/api`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo SatisfactionSurveys
  export async function addSatisfactionSurveysApi(data, token) {
    const url = `${BASE_API}/encuestas_satisfaccion/api/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos de un SatisfactionSurveys existente
  export async function updateSatisfactionSurveysApi(id, data, token) {
    const url = `${BASE_API}/encuestas_satisfaccion/api/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar un SatisfactionSurveys
  export async function deleteSatisfactionSurveysApi(id, token) {
    const url = `${BASE_API}/encuestas_satisfaccion/api/${id}/`;
    return makeRequest(url, "DELETE", token);
  }
  