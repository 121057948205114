import AddIcon from "@mui/icons-material/Add";
import {
  Card,
  CardContent,
  Container,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import {
  CircleAbierto,
  CircleCerrado,
  CirclePendiente,
 cellStyle
} from "./styles";

export function TablaBaseSap(props) {
  const { Data, año,  handleYearChange } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePopoverOpen = (event, data) => {
    setPopoverAnchorEl(event.currentTarget);
    setSelectedData(data);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setSelectedData(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const filteredData =
    Data &&
    Data.filter(
      (data) =>
        (data.CustomerName &&
          data.CustomerName.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (data.TechnicianName &&
          data.TechnicianName.toLowerCase().includes(searchTerm.toLowerCase()))
    );

  const rowsToShow =
    filteredData &&
    filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const open = Boolean(popoverAnchorEl);
  const popoverId = open ? "Data-popover" : undefined;

  // Columnas principales que se mostrarán en la tabla
  const mainColumns = [
    { label: "ID", field: "ServiceCallID" },
    { label: "Cliente", field: "CustomerName" },
    { label: "Tecnico", field: "TechnicianName" },
    { label: "Objetivo", field: "Subject" },
    { label: "Estado", field: "Status" },
  ];

  // Otras columnas que se mostrarán en el popover
  const popoverColumns = [
    { label: "Código item", field: "ItemDescription" },
    { label: "Tipo de llamada", field: "CallType" },
    { label: "Prioridad", field: "Priority" },
    { label: "dirección", field: "BPShipToAddress" },
    { label: "fecha de creación", field: "CreationDate" },
    { label: "fecha de resolución", field: "ResolutionOnDate" },
    { label: "tiempo de resolución", field: "ResponseOnTime" },
    { label: "fecha de cierre", field: "ClosingDate" },
    { label: "Solución", field: "Resolution" },
    { label: "Estado", field: "Status" },
  ];

  // Función para asignar el círculo según el valor de Status
  const getStatusCircle = (status) => {
    switch (status) {
      case -1:
        return <CircleCerrado />;
      case -2:
        return <CirclePendiente />;
      case -3:
        return <CircleAbierto />;
      default:
        return null;
    }
  };
  const getStatusName = (status) => {
    switch (status) {
      case -1:
        return "Cerrado";
      case -2:
        return "Pendiente";
      case -3:
        return "Abierto";
      default:
        return null;
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

   // Generar la lista de años desde el 2008 hasta el 2035
   const years = [];
   for (let year = 2015; year <= 2035; year++) {
     years.push(year);
   }

  return (
    <Container>
      <Card sx={{ marginBottom: '16px' }}>
        <CardContent sx={{ display: 'flex', gap: '16px' }}>
          {/* Caja de búsqueda para filtrar por Cliente o Técnico */}
          <TextField
            label="Buscar por Cliente o Técnico"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
          />
          {/* Select para filtrar por año */}
          <Select
            labelId="year-select-label"
            value={año}
            onChange={handleYearChange}
          >
            {/* Mapear los años para generar los elementos MenuItem */}
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </CardContent>
      </Card>
      <Card>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {mainColumns.map((header) => (
                  <TableCell key={header.field}>
                    <Typography style={cellStyle}>{header.label}</Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography style={cellStyle}>Acción</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsToShow &&
                rowsToShow.map((data) => (
                  <React.Fragment key={data.ServiceCallID}>
                    <TableRow>
                      {mainColumns.map((header) => (
                        <TableCell style={cellStyle} key={header.field}>
                          {header.field === 'Status'
                            ? getStatusCircle(data[header.field])
                            : data[header.field]}
                        </TableCell>
                      ))}
                      <TableCell>
                        <IconButton onClick={(event) => handlePopoverOpen(event, data)}>
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 200, 500]}
          component="div"
          count={filteredData ? filteredData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {popoverColumns.map((header) => (
                  <TableCell key={header.field}>
                    <Typography style={cellStyle}>{header.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedData && (
                <TableRow>
                  {popoverColumns.map((header) => (
                    <TableCell style={cellStyle} key={header.field}>
                       {header.field === 'Status'
                            ? getStatusName(selectedData[header.field])
                            : selectedData[header.field]}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Acción realizada con éxito"
      />
    </Container>
  );
}
