import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";


  
// Función para obtener los datos de un Competence
export async function getCompetenceApi(token) {
    const url = `${BASE_API}/competencia/api`;
    return makeRequest(url, "GET", token);
  }
  
  // Función para agregar un nuevo Competence
  export async function addCompetenceApi(data, token) {
    const url = `${BASE_API}/competencia/api/`;
    return makeRequest(url, "POST", token, data);
  }
  
  // Función para actualizar los datos de un Competence existente
  export async function updateCompetenceApi(id, data, token) {
    const url = `${BASE_API}/competencia/api/${id}/`;
    return makeRequest(url, "PUT", token, data);
  }
  
  // Función para eliminar un Competence
  export async function deleteCompetenceApi(id, token) {
    const url = `${BASE_API}/competencia/api/${id}/`;
    return makeRequest(url, "DELETE", token);
  }
  